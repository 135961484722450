import { Button, Modal, Table } from 'antd';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import OrderDataDownloader from '../../../../components/fileDownloader/orderDataDownloader';
import { useLocaleData } from '../../../../locale';
import { TaskLocale } from '../../../../locale/types';
import { getSubtaskOrderData } from '../../../../utils/api';
import { toISODateTime } from '../../../../utils/utils';
import { currentSubtaskState } from '../../atoms';
import { OrderSpecs } from '../../types';
import { exportOrderDataColumns } from '../../orderDataPage/columns';

const ResultOrderDataModal = () => {
  const [modalVisible, setModelVisible] = React.useState(false);
  const openModal = () => setModelVisible(true);
  const closeModal = () => setModelVisible(false);

  const { taskId, subtaskId } = useParams<{ taskId: string; subtaskId: string }>();
  const currentSubtask = useRecoilValue(currentSubtaskState);
  const locale = useLocaleData<TaskLocale>('task');

  const { data: orderData, isLoading: isFetchingSubtaskOrderData } = useQuery<OrderSpecs[]>(
    ['subtaskOrderData', subtaskId],
    getSubtaskOrderData(taskId, subtaskId),
    { enabled: !!taskId && !!subtaskId }
  );

  return (
    <span>
      <Button onClick={openModal} disabled={!subtaskId || !orderData} type="link">
        Order Data
      </Button>

      <Modal
        title={`Order Data - ${toISODateTime(new Date(currentSubtask?.created))}`}
        visible={modalVisible}
        onCancel={closeModal}
        maskClosable={false}
        width={1280}
        footer={null}
      >
        <OrderDataDownloader
          data={orderData}
          fileName={`${toISODateTime(new Date(currentSubtask?.created))}.xlsx`}
        />
        <Table
          dataSource={orderData}
          loading={isFetchingSubtaskOrderData}
          columns={exportOrderDataColumns(locale)}
          rowKey={(x) => x.id}
        />
      </Modal>
    </span>
  );
};

export default ResultOrderDataModal;
