import React, { useEffect, useMemo } from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import thTh from 'antd/lib/locale/th_TH';

import AppLayout from './pages/Layout';
import Error from './pages/base/errorPage';

import { getLocalLanguage } from './utils/storage';
import ForgetPasswordPage from './pages/account/forgetPasswordPage/forgetPasswordPage';
import ResetPasswordPage from './pages/account/resetPasswordPage/resetPasswordPage';
import loginPage from './pages/account/loginPage/loginPage';
import ExternalResultPage from './pages/externalResultPage/externalResultPage';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { currentLocaleState, accountInfo } from './pages/account/atoms';
import history from './utils/history';
import './App.css';

const App = () => {
  const userAccountInfo = useRecoilValue(accountInfo);
  const setCurrentLocale = useSetRecoilState(currentLocaleState);
  useEffect(() => {
    getLocalLanguage() && setCurrentLocale(getLocalLanguage());
  }, []);

  useEffect(() => {
    userAccountInfo && setCurrentLocale(userAccountInfo.lang);
  }, [userAccountInfo]);

  const lang = useMemo(() => {
    if (!userAccountInfo) return enUS;
    switch (userAccountInfo.lang) {
      case 'zh_CN':
        return zhCN;
      case 'en_US':
        return enUS;
      case 'th':
        return thTh;
      default:
        return enUS;
    }
  }, [userAccountInfo]);

  return (
    <ConfigProvider locale={lang}>
      <div className="app">
        <Router history={history}>
          <Switch>
            <Route path="/error" component={Error} exact />
            <Route path="/plan/:planId" component={ExternalResultPage} exact />
            <Route path="/resetPassword" component={ResetPasswordPage} exact />
            <Route path="/forgetPassword" component={ForgetPasswordPage} exact />
            <Route path="/login" component={loginPage} exact />
            <Route path="/" component={AppLayout} />
          </Switch>
        </Router>
      </div>
    </ConfigProvider>
  );
};

export default App;
