import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Space, message } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { Rule } from 'antd/lib/form';
import { LocaleSelect } from '../../../components/LocaleSelect';
import { useLocaleData } from '../../../locale';
import { AccountLocale, LocaleLanguage } from '../../../locale/types';
import { useMutation } from 'react-query';
import { getVerificationCode, login } from '../../../utils/api';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { currentLocaleState, accountInfo } from '../atoms';
import { AxiosResponse, AxiosError } from 'axios';
import { setToken, setDistrict, setLocalLanguage, getToken } from '../../../utils/storage';
import './loginPage.css';

const COUNT_DOWN_SECONDS = 60;

const loginPage = (): JSX.Element => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleState);
  const setAccountInfo = useSetRecoilState(accountInfo);

  const locale = useLocaleData<AccountLocale>('account');
  const [seconds, setSeconds] = useState(null);

  const getCodeMutation = useMutation(getVerificationCode, {
    onSuccess: () => {
      setSeconds(COUNT_DOWN_SECONDS);
    },
    onError: (error: AxiosError) => {
      if (error.response.status === 429) {
        if (error.response.data.type === '/api/problems/auth.008') {
          message.error(
            locale.message.tooManyRequestPrefix +
              error.response.data.detail.wait +
              locale.message.tooManyRequestSuffix
          );
        }
      }
      if (error.response.status === 401) {
        if (error.response.data?.type === '/api/problems/auth.006') {
          message.error(locale.message.invalidEmailOrPassword);
        } else {
          message.error(locale.message.accessDenied);
        }
      }
    },
  });

  useEffect(() => {
    if (getToken()) {
      history.push('/');
    } else {
      setAccountInfo(undefined);
    }
  }, []);

  const loginMutation = useMutation(login, {
    onSuccess: (response: AxiosResponse) => {
      setToken(response.data.tokens.access);
      setDistrict(response.data.selectedDistrict);
      setAccountInfo(response.data.user);
      setLocalLanguage(response.data.user.lang as LocaleLanguage);
      history.push('/');
    },
    onError: (error: AxiosError) => {
      if (error.response.status === 401) {
        if (error.response.data?.type === '/api/problems/auth.011') {
          message.error(locale.message.invalidVerificationCode);
        } else {
          message.error(locale.message.invalidEmailOrPassword);
        }
      }
    },
  });

  useEffect(() => {
    if (!seconds) return;
    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [seconds]);

  const formOptions = {
    email: {
      rules: [{ required: true, type: 'email', message: locale.message.emailEmpty }] as Rule[],
      normalize: (value) => value.trim(),
    },
    loginCode: {
      rules: [
        { required: true, message: locale.message.loginCodeEmpty },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!getFieldValue('email')) {
              return Promise.reject(new Error(locale.message.emailEmpty));
            }
            return Promise.resolve();
          },
        }),
      ] as Rule[],
      normalize: (value) => value.trim(),
    },
    password: {
      rules: [{ required: true, message: locale.message.passwordEmpty }],
      normalize: (value) => value.trim(),
    },
  };

  const handleLogin = (values) => {
    const { email, password, loginCode } = values;
    loginMutation.mutate({ email, password, verificationCode: loginCode });
  };

  const getCode = () => {
    form.validateFields(['email', 'password']).then(() => {
      getCodeMutation.mutate({
        email: form.getFieldValue('email'),
        password: form.getFieldValue('password'),
      });
    });
  };

  return (
    <div className="login-page" style={{ height: window.innerHeight }}>
      <div style={{ float: 'right', padding: '10px' }}>
        <LocaleSelect value={currentLocale} onSelectLocale={setCurrentLocale} />
      </div>
      <div className="login-form">
        <Typography.Title level={1} className="login-form-title">
          DoraOpt AI-CLP
        </Typography.Title>
        <Typography.Title level={3} className="login-form-subtitle">
          SaaS Platform
        </Typography.Title>
        <Form form={form} onFinish={handleLogin}>
          <Form.Item name="email" {...formOptions.email} style={{ textAlign: 'center' }}>
            <Input placeholder={locale.accountInfo.email} style={{ width: '300px' }} />
          </Form.Item>
          <Form.Item name="password" {...formOptions.password} style={{ textAlign: 'center' }}>
            <Input.Password
              placeholder={locale.accountSecurity.passwordLabel}
              style={{ width: '300px' }}
            />
          </Form.Item>
          <Form.Item name="loginCode" {...formOptions.loginCode} style={{ textAlign: 'center' }}>
            <Space style={{ width: '300px' }}>
              <Input placeholder={locale.accountInfo.loginCode} style={{ width: '300px' }} />
              {seconds > 0 ? (
                <Button disabled>{seconds} s</Button>
              ) : (
                <Button
                  onClick={getCode}
                  loading={getCodeMutation.isLoading}
                  data-test-id="getCode"
                >
                  {locale.getCode}
                </Button>
              )}
            </Space>
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Link to="/forgetPassword" data-test-id="forgetPassword">
              {locale.forgotPassword} ?
            </Link>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loginMutation.isLoading}
              style={{ width: '200px' }}
              data-test-id="login"
            >
              {locale.login}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default loginPage;
