import React, { useEffect, useState } from 'react';
import { Input, Button, Divider, Form, DatePicker, FormItemProps } from 'antd';
import { MaterialType } from '../../../masterDataManagement/material/types';
import Select, { SelectValue } from 'antd/lib/select';
import { ExportOrderSpecs } from '../../types';
import { Locale, GlobalLocale, TaskExportCargoLocale } from '../../../../locale/types';
import { useLocaleData } from '../../../../locale';
import { useQuery } from 'react-query';
import { listConstraints, listMaterials } from '../../../../utils/api';
import { stripId } from '../../../../utils/utils';
import { useRecoilValue } from 'recoil';
import { currentTaskState } from '../../atoms';
import moment from 'moment';

interface OrderFormProps {
  postCancel: () => void;
  postSubmit: () => void;
  onSubmit: (orderData: ExportOrderSpecs[]) => void;
  orderData: ExportOrderSpecs[];
  onEditGoods?: ExportOrderSpecs;
}
const ExportOrderForm = (props: OrderFormProps): JSX.Element => {
  const [form] = Form.useForm();
  const globalLocale = useLocaleData<GlobalLocale>('global');
  const locale = useLocaleData<TaskExportCargoLocale>((locale: Locale) => locale.taskExportCargo);

  const { data: materialList = [] } = useQuery('materials', listMaterials);
  const [currentMaterial, setCurrentMaterial] = useState<MaterialType | undefined>(undefined);
  const currentTask = useRecoilValue(currentTaskState);

  const { data: constraints } = useQuery('constraints', listConstraints);

  useEffect(() => {
    !!props.onEditGoods && setCurrentMaterial(props.onEditGoods.material);
  }, [props.onEditGoods]);

  const handleSubmit = (values) => {
    const updatedCargo = {
      ...values,
      retaDate: values.retaDate.format('YYYY-MM-DD'),
      material: currentMaterial,
    };
    if (props.onEditGoods) {
      const dataIndex = props.orderData.findIndex((goods) => goods.id === props.onEditGoods.id);
      const payload = [...props.orderData];
      payload.splice(dataIndex, 1, updatedCargo);
      props.onSubmit(payload.map(stripId));
    } else {
      props.onSubmit([...props.orderData.map(stripId), updatedCargo]);
    }
    props.postSubmit();
  };

  const coordinateMaterial = (material: MaterialType | undefined): void => {
    if (!material) return;
    form.setFieldsValue({
      materialCode: material.materialNumber,
      materialName: material.description,
      packageType: material.package?.description,
    });

    setCurrentMaterial(material);
  };

  const handleMaterialChange = (value: SelectValue): void => {
    const selectedMaterial = materialList.find((material) => material.id === value);
    coordinateMaterial(selectedMaterial);
  };

  const validateFullDrum = (_, value) => {
    if (!currentMaterial) return Promise.reject('Material' + locale.errorMessage.notFound);
    const currentItemNumber = value / currentMaterial.weight;
    return Number.isInteger(currentItemNumber)
      ? Promise.resolve()
      : Promise.reject(
          locale.errorMessage.notDivisible +
            `${
              Math.floor(currentItemNumber) === 0
                ? ''
                : Math.floor(currentItemNumber) * currentMaterial.weight + ' | '
            } ${Math.ceil(currentItemNumber) * currentMaterial.weight}`
        );
  };

  const validateFullPallet = (_, value) => {
    const currentItemNumber = value / currentMaterial.weight;
    const packageConstraints = constraints
      .filter((x) => x.pallet.id === currentTask?.detail.pallet.id)
      .find((x) => x.package.id === currentMaterial.package.id);

    if (!packageConstraints) return Promise.reject('Package' + locale.errorMessage.notFound);
    const fullPalletItemNumber =
      packageConstraints.itemsPerLayer * packageConstraints.layersPerPallet;

    return Number.isInteger(currentItemNumber / fullPalletItemNumber)
      ? Promise.resolve()
      : Promise.reject(
          `Not full pallet, try: ${
            Math.floor(currentItemNumber / fullPalletItemNumber) === 0
              ? ''
              : Math.floor(currentItemNumber / fullPalletItemNumber) *
                  fullPalletItemNumber *
                  currentMaterial.weight +
                ' | '
          }${
            Math.ceil(currentItemNumber / fullPalletItemNumber) *
            fullPalletItemNumber *
            currentMaterial.weight
          }`
        );
  };

  const goodsFormOptions: { [name: string]: FormItemProps } = {
    customerCode: {
      rules: [{ required: true, message: locale.customerCode.required }],
      initialValue: props.onEditGoods ? props.onEditGoods.customerCode : '',
    },
    customerName: {
      rules: [{ max: 50, message: locale.errorMessage.tooLong50 }],
      initialValue: props.onEditGoods ? props.onEditGoods.customerName : '',
    },
    destination: {
      rules: [
        { required: true, message: locale.destination.required },
        { max: 50, message: locale.errorMessage.tooLong50 },
      ],
      initialValue: props.onEditGoods ? props.onEditGoods.destination : '',
    },
    soNumber: {
      rules: [
        { required: true, message: locale.soNumber.required },
        { max: 50, message: locale.errorMessage.tooLong50 },
      ],
      initialValue: props.onEditGoods ? props.onEditGoods.soNumber : '',
    },
    retaDate: {
      rules: [{ required: true, message: locale.retaDate.required }],
      initialValue: props.onEditGoods ? moment(props.onEditGoods.retaDate, 'YYYY-MM-DD') : '',
    },
    materialCode: {
      rules: [
        { required: true, message: locale.materialCode.required },
        {
          enum: materialList.map((x) => x.materialNumber),
          message: 'Material' + locale.errorMessage.notFound,
        },
      ],
      initialValue: props.onEditGoods ? props.onEditGoods.materialCode : '',
    },
    materialName: {
      rules: [
        {
          enum: materialList.map((x) => x.description),
          message: 'Material' + locale.errorMessage.notFound,
        },
      ],
      initialValue: props.onEditGoods ? props.onEditGoods.materialName : '',
    },
    packageType: {
      rules: [{ required: true, message: locale.packageType.required }],
      initialValue: props.onEditGoods ? props.onEditGoods.material.package?.description : '',
    },
    orderQuantity: {
      rules: [
        { required: true, message: locale.orderQuantity.required },
        { validator: validateFullDrum },
        { validator: validateFullPallet, warningOnly: true },
      ],

      initialValue: props.onEditGoods ? props.onEditGoods.orderQuantity : null,
    },
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Form.Item
        name="customerCode"
        {...goodsFormOptions.customerCode}
        label={locale.customerCode.label + ':'}
      >
        <Input autoFocus data-test-id="customerCode" />
      </Form.Item>
      <Form.Item
        name="customerName"
        {...goodsFormOptions.customerName}
        label={locale.customerName.label + ':'}
      >
        <Input data-test-id="customerName" />
      </Form.Item>
      <Form.Item
        name="materialCode"
        {...goodsFormOptions.materialCode}
        label={locale.materialCode.label}
      >
        {
          <Select
            showSearch
            filterOption={(inputValue, option): boolean =>
              option ? option.children.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0 : false
            }
            onChange={handleMaterialChange}
            disabled={!!props.onEditGoods}
            data-test-id="selectMaterialCode"
          >
            {materialList.map((x) => (
              <Select.Option key={x.id} value={x.id} data-test-id={x.id}>
                {x.materialNumber}
              </Select.Option>
            ))}
          </Select>
        }
      </Form.Item>
      <Form.Item
        name="materialName"
        {...goodsFormOptions.materialName}
        label={locale.materialName.label}
      >
        <Select
          showSearch
          filterOption={(inputValue, option): boolean =>
            option ? option.children.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0 : false
          }
          onChange={handleMaterialChange}
          disabled={!!props.onEditGoods}
          data-test-id="selectMaterialName"
        >
          {materialList.map((x) => (
            <Select.Option key={x.id} value={x.id} data-test-id={x.id}>
              {x.description}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="packageType"
        {...goodsFormOptions.packageType}
        label={locale.packageType.label + ':'}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="orderQuantity"
        {...goodsFormOptions.orderQuantity}
        label={locale.orderQuantity.label + ':'}
      >
        <Input type="number" min={1} data-test-id="orderQuantity" />
      </Form.Item>
      <Form.Item
        name="destination"
        {...goodsFormOptions.destination}
        label={locale.destination.label + ':'}
      >
        <Input data-test-id="destination" />
      </Form.Item>
      <Form.Item name="soNumber" {...goodsFormOptions.soNumber} label={locale.soNumber.label + ':'}>
        <Input data-test-id="soNumber" />
      </Form.Item>
      <Form.Item name="retaDate" {...goodsFormOptions.retaDate} label={locale.retaDate.label + ':'}>
        <DatePicker data-test-id="retaDate" />
      </Form.Item>

      <Divider />
      <div style={{ textAlign: 'right' }}>
        <Button onClick={props.postCancel} data-test-id="cancel">
          {globalLocale.cancel}
        </Button>
        <Button htmlType="submit" type="primary" style={{ marginLeft: '8px' }}>
          {globalLocale.save}
        </Button>
      </div>
    </Form>
  );
};

export default ExportOrderForm;
