import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Row, Col, Card, Typography } from 'antd';
import { HomeLocale } from '../../locale/types';
import { useLocaleData } from '../../locale';
import './homePage.css';

const Home = (): JSX.Element => {
  const locale: HomeLocale = useLocaleData<HomeLocale>('home');

  const taskSubmissionSteps = [
    {
      step: locale.taskStepNew.title,
      text: locale.taskStepNew.content,
    },
    {
      step: locale.taskStepOrderData.title,
      text: locale.taskStepOrderData.content,
    },
    {
      step: locale.taskStepConfigure.title,
      text: locale.taskStepConfigure.content,
    },
  ];

  return (
    <div>
      <Row className="home-section">
        <Typography.Title level={2}>{locale.title}</Typography.Title>
      </Row>

      <Row className="home-section">
        <Col span={24}>
          <Card title={locale.introduction.title}>
            <p>{locale.introduction.content}</p>
          </Card>
        </Col>
      </Row>

      {/* <Row className="home-section">
        <Col span={24}>
          <Card title={locale.tutorial.title}>
            <p>
              {locale.tutorial.content}
              <FileDownloader
                fileName="user_manual_chinese.pdf"
                url="/basf-storage-container/user_manual_chinese.pdf"
                text={locale.chinese}
              />

              <FileDownloader
                fileName="user_manual_english.pdf"
                url="/basf-storage-container/user_manual_english.pdf"
                text={locale.english}
              />
            </p>
          </Card>
        </Col>
      </Row> */}

      <Row>
        {taskSubmissionSteps.map((step, i) => (
          <Col span={8} key={i}>
            <Row gutter={20} align="middle" className="home-section">
              <Col span={i !== taskSubmissionSteps.length - 1 ? 20 : 24}>
                <Card title={step.step}>{step.text}</Card>
              </Col>
              {i !== taskSubmissionSteps.length - 1 && (
                <Col span={4}>
                  <RightOutlined style={{ color: 'darkgreen', fontSize: '30px' }} />
                </Col>
              )}
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default Home;
