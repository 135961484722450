import React, { useMemo } from 'react';
import { ContactType, ContactBookType } from './types';
import { ContactAddLocale, Locale } from '../../locale/types';
import { useLocaleData } from '../../locale';
import { Form, Input, Divider, Button, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { useQueryClient, useMutation } from 'react-query';
import { updateContact, createContact } from '../../utils/api';

interface ContactFormProps {
  onEditContact?: ContactType;
  postSubmit: () => void;
  postCancel: () => void;
}

function ContactForm(props: ContactFormProps): JSX.Element {
  const [form] = Form.useForm();
  const locale = useLocaleData<ContactAddLocale>((locale: Locale) => locale.contactAdd);
  const queryClient = useQueryClient();
  const contactBookList = queryClient.getQueryData<ContactBookType[]>('contactBookList');
  const updateContactMutation = useMutation(updateContact, {
    onSuccess: () => {
      message.success(locale.message.emailUpdated);
      queryClient.invalidateQueries('contactBookList');
    },
  });
  const createContactMutation = useMutation(createContact, {
    onSuccess: () => {
      message.success(locale.message.emailAdded);
      queryClient.invalidateQueries('contactBookList');
    },
  });

  const bookId = useMemo(() => contactBookList[0]?.id, [contactBookList]);

  const formOptions = {
    firstName: {
      rules: [
        { required: true, type: 'string', message: locale.firstName.required },
        { type: 'string', max: 50, message: locale.firstNameTooLong },
      ] as Rule[],
      normalize: (value) => value.trim(),
      initialValue: props.onEditContact ? props.onEditContact.firstName : undefined,
    },
    email: {
      rules: [
        { required: true, type: 'string', message: locale.email.required },
        { type: 'email', message: locale.invalidEmail },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (
              contactBookList[0].contacts.find(
                (contact) =>
                  (contact.email === value &&
                    props.onEditContact &&
                    props.onEditContact.id !== contact.id) ||
                  (contact.email === value && !props.onEditContact)
              )
            ) {
              return Promise.reject(new Error(locale.message.repeatedEmail));
            }

            return Promise.resolve();
          },
        }),
      ] as Rule[],
      normalize: (value) => value.trim(),
      initialValue: props.onEditContact ? props.onEditContact.email : undefined,
    },
  };
  const handleSubmit = (values) => {
    if (!bookId) throw Error('contact book id not found!');
    const payload = {
      id: props.onEditContact?.id,
      email: values.email,
      firstName: values.firstName,
      bookId,
    };
    if (props.onEditContact) {
      updateContactMutation.mutate(payload);
    } else {
      createContactMutation.mutate(payload);
    }
    props.postSubmit();
  };
  return (
    <Form onFinish={handleSubmit} form={form} layout="vertical">
      <Form.Item
        name="firstName"
        label={locale.firstName.label}
        {...formOptions.firstName}
        style={{ textAlign: 'center' }}
      >
        <Input data-test-id="firstName" />
      </Form.Item>
      <Form.Item
        name="email"
        label={locale.email.label}
        {...formOptions.email}
        style={{ textAlign: 'center' }}
      >
        <Input data-test-id="email" />
      </Form.Item>
      <Divider />
      <div className="form-action-button-group" style={{ textAlign: 'right' }}>
        <Button onClick={props.postCancel} data-test-id="cancel">
          {locale.cancel}
        </Button>
        <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }}>
          {locale.save}
        </Button>
      </div>
    </Form>
  );
}

export default ContactForm;
