import React from 'react';
import { Typography, Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Rule } from 'antd/lib/form';

import './forgetPasswordPage.css';
import { useLocaleData } from '../../../locale';
import { AccountLocale } from '../../../locale/types';
import { LocaleSelect } from '../../../components/LocaleSelect';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '../atoms';
import { useForgetPasswordHook } from '../../../hooks/forgetPassword';

const ForgetPasswordPage = (): JSX.Element => {
  const [form] = Form.useForm();
  const locale = useLocaleData<AccountLocale>('account');
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleState);
  const { setForgetPassword, isLoading } = useForgetPasswordHook();

  const formOptions = {
    email: {
      rules: [{ required: true, type: 'email', message: locale.message.emailEmpty }] as Rule[],
    },
  };

  const onFinish = (values) => {
    setForgetPassword(values.email);
  };

  return (
    <div className="forget-password-page" style={{ height: window.innerHeight }}>
      <div style={{ float: 'right', padding: '10px' }}>
        <LocaleSelect value={currentLocale} onSelectLocale={setCurrentLocale} />
      </div>
      <Form form={form} onFinish={onFinish} layout="vertical" className="forget-password-form">
        <Typography.Title level={2} className="forget-password-form-title">
          {locale.forgotPassword}
        </Typography.Title>
        <Form.Item name="email" {...formOptions.email}>
          <Input placeholder={locale.accountInfo.email} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} data-test-id="sendEmail">
            {locale.sendResetEmail}
          </Button>
        </Form.Item>
        <Link to="/login" data-test-id="login">
          {locale.backToLogin}
        </Link>
      </Form>
    </div>
  );
};

export default ForgetPasswordPage;
