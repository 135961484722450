import * as React from 'react';
import { Button } from 'antd';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { ExportOrderSpecs, OrderSpecs } from '../../pages/task/types';

type Props = {
  fileName: string;
  data: OrderSpecs[];
};

const composeExcel = (data: OrderSpecs[]) => {
  const wb = new Excel.Workbook();
  const ws = wb.addWorksheet('export-orders');
  const columnNames = [
    'SO_Number',
    'Material_Code',
    'Material_Name',
    'Order_Quantity',
    'Customer_Code',
    'Customer_Name',
    'Destination',
    'RETA_Date',
  ];
  ws.addRow(columnNames);

  for (let i = 1; i <= columnNames.length; i++) ws.getColumn(i).width = 18;

  data.forEach((x: ExportOrderSpecs) =>
    ws.addRow([
      x.soNumber,
      x.materialCode,
      x.materialName,
      x.orderQuantity,
      x.customerCode,
      x.customerName,
      x.destination,
      x.retaDate,
    ])
  );

  return wb;
};

const OrderDataDownloader = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleDownload = async () => {
    setIsLoading(true);
    // Compose the excel file.
    const wb = composeExcel(props.data);
    // Saving and Downloading
    const result = await wb.xlsx.writeBuffer();
    saveAs(new Blob([result]), props.fileName);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setIsLoading(false);
  };
  return (
    <Button type="link" onClick={handleDownload} loading={isLoading} disabled={!props.data}>
      Download
    </Button>
  );
};

export default OrderDataDownloader;
