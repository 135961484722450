import { Button, message } from 'antd';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { currentSubtaskState, currentTaskState } from '../../pages/task/atoms';
import { FileAssetsType } from '../../pages/task/types';
import { authRequest } from '../../utils/request';
import { ResultReportReferenceType } from '../interactiveScene/types';
import downloadFile from './downloadUtils';

type Props = {
  report: ResultReportReferenceType;
  fileName: string;
};
const ReportDownloader = (props: Props) => {
  const [progress, setProgress] = React.useState(0);
  const currentTask = useRecoilValue(currentTaskState);
  const currentSubtask = useRecoilValue(currentSubtaskState);

  const handleDownload = async (e) => {
    const fileUrl = `/api/btl/tasks/${currentTask.id}/subtasks/${currentSubtask.id}/files/`;
    const files: FileAssetsType[] = await (await authRequest().get(fileUrl)).data;
    const reportId = files.find((x) => x.tag === 'report')?.id;
    if (!reportId) return message.error('Report File Not Found');
    downloadFile(
      fileUrl + `${reportId}/?page_num=${props.report?.page_num}&&offset=${props.report?.offset}`,
      props.fileName,
      setProgress
    );
  };

  return (
    <Button
      loading={progress > 0}
      onClick={handleDownload}
      type="link"
      size="small"
      disabled={!currentSubtask.reportUrl}
      data-test-id="pdf"
    >
      {progress > 0 ? `${progress}%` : 'PDF'}
    </Button>
  );
};

export default ReportDownloader;
