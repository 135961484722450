import React, { useState, useEffect } from 'react';

import { Row, Card, Table, Typography, Col, Button } from 'antd';
import { useLocaleData } from '../../../locale';
import { PalletListLocale, Locale } from '../../../locale/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PalletType } from './types';
import { EditOutlined } from '@ant-design/icons';
import DeleteConfirm from '../../../components/deleteConfirm';
import FormModal from '../../../components/formModal';
import PalletForm from './palletForm';
import { deletePallet, listPalletBases } from '../../../utils/api';

type TableFormat = {
  key: string;
  index: number;
  name: string;
  dimension: string;
  weight: string;
};

const useDataSource = (palletList: PalletType[]): TableFormat[] => {
  const [dataSource, setDataSource] = useState<TableFormat[]>([]);
  useEffect(() => {
    if (palletList) {
      setDataSource(
        palletList.map((spec: PalletType, index: number) => ({
          key: spec.id,
          index: index + 1,
          name: spec.name,
          dimension: `${spec.length} x ${spec.width} x ${spec.height} mm`,
          weight: `${spec.weight} kg`,
        }))
      );
    } else {
      setDataSource([]);
    }
  }, [palletList]);

  return dataSource;
};

const Pallet = (): JSX.Element => {
  // Hooks
  const locale = useLocaleData<PalletListLocale>((locale: Locale) => locale.palletList);
  const { data: palletList, isLoading } = useQuery<PalletType[]>('palletList', listPalletBases);
  const [palletFormModalVisible, setPalletFormVisible] = useState(false);
  const [onEditPallet, setOnEditPallet] = useState<PalletType | undefined>(undefined);

  const tableDataSource = useDataSource(palletList);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(deletePallet, {
    onSuccess: () => {
      queryClient.invalidateQueries('palletList');
    },
  });

  // event handling
  const openModal = (): void => setPalletFormVisible(true);
  const closeModal = (): void => setPalletFormVisible(false);

  const editPallet = (palletId: string) => {
    setOnEditPallet(palletList.find((pallet) => pallet.id === palletId));
    openModal();
  };
  const renderActions = (pallet: TableFormat): JSX.Element => (
    <div>
      <EditOutlined
        onClick={() => editPallet(pallet.key)}
        style={{ marginRight: '20px' }}
        data-test-id="edit"
      />
      {palletList.length > 1 && (
        <DeleteConfirm onConfirm={() => deleteMutation.mutate(pallet.key)} />
      )}
    </div>
  );

  const tableColDef = [
    { title: locale.table.index, dataIndex: 'index', key: 'index' },
    { title: locale.table.name, dataIndex: 'name', key: 'name' },
    { title: locale.table.dimension, dataIndex: 'dimension', key: 'dimension' },
    { title: locale.table.weight, dataIndex: 'weight', key: 'weight' },
    {
      title: locale.table.action.name,
      key: 'action',
      render: renderActions,
    },
  ];

  // const addNewPallet = (): void => {
  //   setOnEditPallet(undefined);
  //   openModal();
  // };

  const palletFormModalSettings = {
    openModal,
    closeModal,
    title: onEditPallet ? locale.edit : locale.add,
    visible: palletFormModalVisible,
    component: PalletForm,
    componentProps: { onEditPallet },
  };
  // View
  return (
    <div>
      <Row>
        <Typography.Title level={2}>{locale.title}</Typography.Title>
      </Row>

      <Row>
        <Col span={24}>
          <Card
            title={
              <h3>
                {/*TODO Temporary disable this. */}
                {/* <span>
                  <Button onClick={addNewPallet} type="link">
                    {locale.add}
                  </Button>
                </span> */}
              </h3>
            }
          >
            <Table columns={tableColDef} dataSource={tableDataSource} loading={isLoading} />
          </Card>
        </Col>
      </Row>
      <Row></Row>
      <FormModal {...palletFormModalSettings} />
    </div>
  );
};
export default Pallet;
