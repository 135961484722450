import React from 'react';
import { Result, Button } from 'antd';
import { useLocaleData } from '../../locale';
import { GlobalLocale } from '../../locale/types';
import { useHistory } from 'react-router-dom';

const Error = (): JSX.Element => {
  const globalLocale = useLocaleData<GlobalLocale>('global');
  const history = useHistory();
  return (
    <Result status="warning" title={globalLocale.someThingWentWrong}>
      <div style={{ textAlign: 'center' }}>
        <Button type="primary" onClick={() => history.push('/')}>
          {globalLocale.goBackToHome}
        </Button>
      </div>
    </Result>
  );
};

export default Error;
