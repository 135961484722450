import * as React from 'react';

import { Button, Space, Table, Tag } from 'antd';
import { useQuery } from 'react-query';
import { getPlanData } from '../../../../utils/api';
import {
  PlanData,
  ResultRuleType,
  ResultType,
} from '../../../../components/interactiveScene/types';
import { ColumnProps } from 'antd/lib/table';
import SuggestionModal from './suggestionModal';
import SceneModal from './sceneModal';
import SendEmailModal from './sendEmailModal';
import { useRecoilValue } from 'recoil';
import { currentSubtaskState } from '../../atoms';
import ReportDownloader from '../../../../components/fileDownloader/reportDownloader';
import { toISODateTime } from '../../../../utils/utils';
import { TaskResultLocale } from '../../../../locale/types';
import { useLocaleData } from '../../../../locale';

const ResultDetailTable = () => {
  const currentSubtask = useRecoilValue(currentSubtaskState);
  const { data: resultList, isLoading: isFetchingResult } = useQuery(
    ['planData', currentSubtask?.plansUrl],
    getPlanData(currentSubtask?.plansUrl),
    { enabled: !!currentSubtask?.plansUrl }
  );

  const locale: TaskResultLocale = useLocaleData<TaskResultLocale>('taskResult');
  const [planFileToDisplay, setPlanFileToDisplay] = React.useState<PlanData[]>([]);
  const [suggestionToShow, setSuggestionToShow] = React.useState<ResultType>(undefined);
  const [resultRuleToSend, setResultPlanToSend] = React.useState<ResultRuleType>(undefined);

  // result detail table columns
  const renderResultDetailActions = (_, record: ResultType) => {
    return (
      <Space>
        <Button
          size="small"
          onClick={() => setPlanFileToDisplay(record.plan)}
          type="link"
          data-test-id="preview"
        >
          {locale.preview}
        </Button>
        <Button
          size="small"
          type="link"
          onClick={() => setResultPlanToSend(record.rule)}
          data-test-id="email"
        >
          {locale.email}
        </Button>

        <ReportDownloader
          report={record.report}
          fileName={`[ASP]Plan of ${toISODateTime(new Date(currentSubtask.created))}.pdf`}
        />

        <Button
          size="small"
          type="link"
          disabled={!record.suggestions}
          onClick={() => setSuggestionToShow(record)}
          data-test-id="suggestions"
        >
          {locale.suggestions}
        </Button>
      </Space>
    );
  };
  const getResultSummary = (value, record: ResultType) => {
    const countMap = new Map<string, number>();
    record.container_stats.forEach((x) =>
      countMap.has(x.name)
        ? countMap.set(x.name, countMap.get(x.name) + 1)
        : countMap.set(x.name, 1)
    );
    return (
      <span>
        {Array.from(countMap.entries()).map((x, index) => (
          <Tag key={index}>
            {x[0]} * {x[1]}
          </Tag>
        ))}
      </span>
    );
  };

  // the column should be adaptive to various way of consolidation.
  const getColumnsByData = (data): ColumnProps<ResultType>[] => {
    return [
      // {
      //   title: 'Consolidate By',
      //   dataIndex: 'rule',
      //   render: (rule) => Object.keys(rule.value).toString(),
      // },
      {
        title: locale.weekNo,
        render: (_, record) => record.rule.value.week_number,
      },
      {
        title: locale.customerCode,
        render: (_, record) => record.rule.value.customer_code,
      },
      {
        title: locale.destination,
        render: (_, record) => record.rule.value.destination,
      },
      {
        title: locale.summary,
        render: getResultSummary,
      },
      {
        title: locale.actions,
        align: 'center',
        render: renderResultDetailActions,
      },
    ];
  };

  return (
    <div>
      <Table
        loading={isFetchingResult}
        dataSource={resultList?.map((x, index) => ({ ...x, key: index }))}
        columns={getColumnsByData(resultList)}
      />
      <SceneModal planFile={planFileToDisplay} onClose={() => setPlanFileToDisplay([])} />
      <SuggestionModal result={suggestionToShow} onClose={() => setSuggestionToShow(undefined)} />
      <SendEmailModal rule={resultRuleToSend} onClose={() => setResultPlanToSend(undefined)} />
    </div>
  );
};

export default ResultDetailTable;
