import React from 'react';
import { Descriptions, Typography, Skeleton, Button, Card, Divider } from 'antd';
import ChangePasswordModal from '../components/changePasswordModal';
import { LocaleSelect } from '../../../components/LocaleSelect';
import { LocaleLanguage, AccountLocale } from '../../../locale/types';
import { useLocaleData } from '../../../locale';
import { clearStorage } from '../../../utils/storage';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { accountInfo, currentLocaleState } from '../atoms';
import { useMutation } from 'react-query';
import { updateAccount } from '../../../utils/api';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';

const AccountCenterPage = (): JSX.Element => {
  const [userAccountInfo, setUserAccountInfo] = useRecoilState(accountInfo);
  const setLocale = useSetRecoilState(currentLocaleState);
  const history = useHistory();
  const logout = () => {
    clearStorage();
    setUserAccountInfo(undefined);
    history.push('/login');
  };
  const updateAccountMutation = useMutation(updateAccount, {
    onSuccess: (res: AxiosResponse) => {
      setUserAccountInfo(res.data);
    },
  });
  const locale = useLocaleData<AccountLocale>('account');
  const updateLanguageSetting = (lang: LocaleLanguage) => {
    const updateObject = { lang, id: userAccountInfo.id };
    setLocale(lang);
    updateAccountMutation.mutate(updateObject);
  };

  if (userAccountInfo) {
    return (
      <div className="account-center page">
        <Typography.Title level={2}> {locale.title}</Typography.Title>
        <Card>
          <Descriptions title={locale.accountInfo.subtitle}>
            <Descriptions.Item
              label={locale.accountInfo.userName}
            >{`${userAccountInfo.firstName} ${userAccountInfo.lastName}`}</Descriptions.Item>
            <Descriptions.Item label={locale.accountInfo.email}>
              {userAccountInfo.email}
            </Descriptions.Item>
            <Descriptions.Item label={locale.accountInfo.role}>
              {userAccountInfo.roles[0]['displayTitle']}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title={locale.languageSetting} style={{ marginTop: '20px' }}>
            <Descriptions.Item>
              <LocaleSelect value={userAccountInfo.lang} onSelectLocale={updateLanguageSetting} />
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title={locale.accountSecurity.subtitle} style={{ marginTop: '20px' }}>
            <Descriptions.Item label={locale.accountSecurity.passwordLabel}>
              <ChangePasswordModal userId={userAccountInfo.id} />
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Button type="primary" onClick={logout} data-test-id="logout">
            {locale.logout}
          </Button>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="account-center page">
        <Skeleton active />
      </div>
    );
  }
};

export default AccountCenterPage;
