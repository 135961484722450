// --------------------------------------------------------------------------------
// Base locale types used to define other locale types or build locale translations
export enum LocaleLanguage {
  EN_US = 'en_US',
  ZH_CN = 'zh_CN',
  th = 'th',
}

export interface ContactLocale {
  title: string;
  add: string;
  edit: string;
  searchPlaceHolder: string;
  table: {
    index: string;
    email: string;
    action: ActionColumnLocale;
    bookName: string;
    firstName: string;
  };
  message: {
    emailDeleted: string;
  };
}

export interface HelpTextLocale {
  customerId: string;
  destinationPort: string;
  orderNum: string;
  subtaskName: string;
  containerList: string;
  palletType: string;
  plannerType: string;
  minCostPlanner: string;
  totalGrossWeight: string;
  totalGrossWeightExtraHint: string;
}

export interface LabelWithHelpLocale {
  label: string;
  help?: string;
}

export interface InputLocale extends LabelWithHelpLocale {
  placeholder?: any;
  default?: any;
  required?: string;
  error?: string;
}

export interface SelectLocale extends InputLocale {
  selections: InputLocale[];
}

export interface CardLocale {
  title: string;
  content: string;
}

export interface ActionColumnLocale {
  name: string;
  rowItems?: {
    [key: string]: string;
  };
}

// -----------------------
// Components locale types
export interface GlobalLocale {
  save: string;
  saved: string;
  cancel: string;
  saas: string;
  back: string;
  next: string;
  edit: string;
  yes: string;
  no: string;
  error: string;
  add: string;
  submit: string;
  added: string;
  created: string;
  login: string;
  logout: string;
  updated: string;
  deleted: string;
  submitted: string;
  draft: string;
  stable: string;
  serverError: {
    message: string;
    description: string;
  };
  invalidTokenError: string;
  emailSent: string;
  unknownError: string;
  someThingWentWrong: string;
  goBackToHome: string;
  nothingHere: string;
}
export interface UtilizationLocale {
  spaceUtilization: string;
  loadUtilization: string;
}
export interface LoginLocale {
  email: string;
  password: string;
  notification: {
    AUTH_ERROR: {
      message: string;
    };
  };
}

export interface HomeLocale {
  title: string;
  chinese: string;
  english: string;
  introduction: CardLocale;
  tutorial: CardLocale;
  taskStepNew: CardLocale;
  taskStepOrderData: CardLocale;
  taskStepConfigure: CardLocale;
}

export interface TaskOrderInfoLocale<T> {
  title: string;
  customerId: T;
  destinationPort: T;
  orderNum: T;
  palletType: T;
  taskType: T;
}

export interface DomesticCargoListLocale {
  title: string;
  add: string;
  table: {
    index: string;
    customerCode: string;
    customerName: string;
    postcode: string;
    doNumber: string;
    deliveryDate: string;
    materialCode: string;
    materialName: string;
    orderQuantity: string;
    actions: string;
  };
}

export interface ExportCargoListLocale {
  title: string;
  add: string;
  table: {
    index: string;
    soNumber: string;
    customerCode: string;
    customerName: string;
    materialCode: string;
    materialName: string;
    destination: string;
    retaDate: string;
    orderQuantity: string;
    actions: string;
  };
}

export interface TaskSelectCargoLocale {
  title: string;
  materialNum: InputLocale;
  materialDescription: InputLocale;
  package: InputLocale;
  quantity: InputLocale;
  totalWeight: InputLocale;
}

export interface TaskImportCargoLocale {
  title: string;
  draggerText: string;
  downloadTemplate: string;
  here: string;
}

export interface TaskConfigureLocale {
  title: string;
  addSubTask: string;
  deleteWarning: string;
  table: {
    index: string;
    name: string;
    plannerType: LabelWithHelpLocale;
    containerList: LabelWithHelpLocale;
    maxTime: string;
    action: string;
    maxTimeUnit: string;
    moreThan: string;
    lessThan: string;
  };
}

export interface TaskAddSubtaskLocale {
  title: string;
  name: InputLocale;
  maxTime: InputLocale;
  plannerType: SelectLocale;
  containerList: InputLocale;
  addContainer: string;
  add: string;
  cancel: string;
  numberLabel: string;
}

export interface TaskSubmitReview extends TaskOrderInfoLocale<string> {
  taskName: string;
  numCargos: string;
  numSubtasks: string;
  label: string;
}

export interface TaskLocale {
  inputDataTitle: string;
  configureTaskTitle: string;
  add: string;
  edit: string;
  fill: string;
  label: string;
  submitted: string;
  lineNumber: string;
  errorMessages: TaskErrorMessages;
  taskInfo: TaskOrderInfoLocale<InputLocale>;
  domesticCargoList: DomesticCargoListLocale;
  exportCargoList: ExportCargoListLocale;
  selectCargo: TaskSelectCargoLocale;
  importCargo: TaskImportCargoLocale;
  configure: TaskConfigureLocale;
  addSubtask: TaskAddSubtaskLocale;
  review: TaskSubmitReview;
  notification: TaskNotificationLocale;
  domestic: string;
  export: string;
  taskType: string;
  newTask: string;
  createNewTask: string;
  configuration: string;
  generatePlan: string;
  selectTruckContainer: string;
  emptyContainers: string;
  emptyGoods: string;
  batchDelete: string;
  selectAll: string;
  selectCurrentPage: string;
  unSelectAll: string;
  unSelectCurrentPage: string;
  inverseSelect: string;
  cancel: string;
  delete: string;
  quickSelection: string;
}

export interface TaskNotificationLocale {
  emptyFile: {
    message: string;
    description: string;
  };
  fileTypeError: {
    message: string;
    description: string;
  };
}
export interface TaskErrorMessages {
  tooMuchGoodsWarning: string;
  tooMuchGoodsError: string;
  noGoodsError: string;
  noSubtaskError: string;
  zeroAttributeRowError: string;
  abnormalDensityWarning: string;
  orderNumberEmptyMessage: string;
}

export interface MainNavigationLocale {
  home: string;
  newTask: string;
  tasks: string;
  cargos: string;
  pallets: string;
  containers: string;
  userManual: string;
  account: string;
  users: string;
  contactBook: string;
  constraints: string;
  masterData: string;
  task: string;
  none: string;
}

export interface TaskCreateNavigationLocale {
  home: string;
  inputData: string;
  configTask: string;
  newTask: string;
  result: string;
}

export interface PageNavigationLocale {
  main: MainNavigationLocale;
  task: TaskCreateNavigationLocale;
}

export interface TaskListLocale {
  title: string;
  table: {
    index: string;
    name: string;
    label: string;
    owner: string;
    created: string;
    completed: string;
    action: ActionColumnLocale;
  };
}

export interface TaskDetailLocale {
  title: string;
  downloadInput: string;
  table: {
    favorite: string;
    name: string;
    plannerType: string;
    containerList: string;
    status: string;
    numContainers: string;
    action: ActionColumnLocale;
  };
}

export interface ContactListLocale {
  add: string;
}

export interface CargoListLocale {
  title: string;
  add: string;
  edit: string;
  searchPlaceHolder: string;
  table: {
    index: string;
    materialNum: string;
    packageType: string;
    weight: string;
    description: string;
    action: ActionColumnLocale;
  };
  notification: {
    [name: string]: {
      message: string;
      description?: string;
    };
  };
}

export interface ContainerListLocale {
  title: string;
  add: string;
  edit: string;
  shipping: string;
  truck: string;
  table: {
    index: string;
    name: string;
    type: string;
    dimension: string;
    weight: string;
    capacity: string;
    maxPallet: string;
    cost: LabelWithHelpLocale;
    action: ActionColumnLocale;
  };
  message: {
    containerDeleted: string;
  };
}

export interface CargoAddLocale {
  title: string;
  save: string;
  cancel: string;
  materialNum: InputLocale;
  materialDescription: InputLocale;
  package: InputLocale;
  packageWeight: InputLocale;
  weight: InputLocale;
  netWeight: InputLocale;
  repeatedMaterialNumber: string;
}

export interface ConstraintLocale {
  title: string;
  palletType: string;
  packageType: string;
  packagePerLayer: string;
  layerPerPallet: string;
  layerPerTrailer: string;
}

export interface ContactAddLocale {
  title: string;
  save: string;
  cancel: string;
  firstName: InputLocale;
  email: InputLocale;
  firstNameTooLong: string;
  message: {
    repeatedEmail: string;
    emailAdded: string;
    emailDeleted: string;
    emailUpdated: string;
  };
  invalidEmail: string;
}

export interface PalletAddLocale {
  title: string;
  save: string;
  cancel: string;
  name: InputLocale;
  length: InputLocale;
  width: InputLocale;
  height: InputLocale;
  weight: InputLocale;
  nameTooLong: string;
  repeatedName: string;
}
export interface ContainerAddLocale {
  title: string;
  save: string;
  cancel: string;
  name: InputLocale;
  type: InputLocale;
  maxLoad: InputLocale;
  maxPallet: InputLocale;
  length: InputLocale;
  width: InputLocale;
  height: InputLocale;
  weight: InputLocale;
  refCost: InputLocale;
  nameTooLong: string;
  shipping: string;
  truck: string;
  repeatedName: string;
  message: {
    containerAdded: string;
    containerUpdated: string;
  };
}

export interface CreateTaskLocale {
  title: string;
  create: string;
  cancel: string;
  name: InputLocale;
  palletType: InputLocale;
  orderType: InputLocale;
  domestic: string;
  export: string;
  nameTooLong: string;
}

export interface CargoImportLocale {
  title: string;
  dropZoneText: string;
  closeText: string;
  downloadTemplate: string;
  here: string;
}

export interface SendEmailModalLocale {
  help: string;
  title: string;
  hint: string;
  cancel: string;
  send: string;
  subject: string;
  recipient: string;
  loadingPlan: string;
  errorMessage: {
    subjectEmpty: string;
    recipientEmpty: string;
  };
}

export interface DeleteConfirmLocale {
  title: string;
  okText: string;
  cancelText: string;
  deleteText: string;
}

export interface AccountLocale {
  title: string;
  accountInfo: {
    subtitle: string;
    userName: string;
    email: string;
    loginCode: string;
    organization: string;
    department: string;
    role: string;
  };
  languageSetting: string;
  accountSecurity: {
    subtitle: string;
    passwordLabel: string;
    changePassword: ChangePasswordModalLocale;
  };
  logout: string;
  login: string;
  getCode: string;
  forgotPassword: string;
  resetPassword: string;
  sendResetEmail: string;
  backToLogin: string;
  message: AccountMessageLocale;
}

export interface AccountMessageLocale {
  emailEmpty: string;
  loginCodeEmpty: string;
  passwordEmpty: string;
  loginSuccessful: string;
  invalidEmailOrPassword: string;
  serverErrorFailed: string;
  unknownErrorFailed: string;
  logoutSuccessful: string;
  accessDenied: string;
  invalidVerificationCode: string;
  tooManyRequestPrefix: string;
  tooManyRequestSuffix: string;
  passwordNotStrongEnough: string;
}

export interface ChangePasswordModalLocale {
  title: string;
  change: string;
  cancel: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  message: {
    changed: string;
    currentPasswordEmpty: string;
    lengthNotEnough: string;
    confirmPasswordEmpty: string;
    notMatchingNewPassword: string;
    sameAsCurrentPassword: string;
    passwordNotStrongEnough: string;
  };
}

export interface UsersLocale {
  title: string;
  userTable: {
    name: string;
    email: string;
    role: string;
    organization: string;
    department: string;
    isActive: string;
    action: string;
  };
  admin: string;
  user: string;
  isActive: string;
  locked: string;
  createUserModal: {
    title: string;
    emailLabel: string;
    firstNameLabel: string;
    lastNameLabel: string;
    roleLabel: string;
    cancel: string;
    create: string;
    addUser: string;
    message: {
      emailEmpty: string;
      firstNameEmpty: string;
      lastNameEmpty: string;
      roleEmpty: string;
      cancel: string;
    };
  };
  resetPassword: string;
  message: {
    changingOwnRole: string;
    changingOwnActive: string;
    serverErrorCreatFailed: string;
    emailAlreadyRegistered: string;
    newUserCreated: string;
    passwordChange: string;
    invalidCurrentPassword: string;
    serverErrorUpdateFailed: string;
    resetEmailSentTo: string;
    resetExceedLimit: string;
    minutes: string;
    secondes: string;
    serverErrorResetFailed: string;
    resetSuccessful: string;
    resetLinkExpired: string;
    userPasswordIncorrect: string;
    userAdded: string;
  };
}

export interface ErrorMessageLocale {
  and: string;
  more: string;
  rows: string;
  column: string;
  notFound: string;
  notAllowed: string;
  alreadyExists: string;
  unknownAPIError: string;
  notAuthorized: string;
  samePassword: string;
  invalidFileExtension: string;
  fileNotReadable: string;
  fileEmpty: string;
  missingColumn: string;
  notUnique: string;
  notCategorical: string;
  notValidNumber: string;
  notValidType: string;
  tooSmall: string;
  tooLarge: string;
  notPositive: string;
  outOfRange: string;
  notNegative: string;
  notEndWith: string;
  invalidConfigValue: string;
  emptyValue: string;
  systemError: string;
  valueNotAllowed: string;
  crossDepartment: string;
  notNewPassword: string;
  notDivisible: string;
  backToTaskList: string;
}
export interface TaskExportCargoLocale {
  title: string;
  soNumber: InputLocale;
  customerCode: InputLocale;
  customerName: InputLocale;
  materialCode: InputLocale;
  materialName: InputLocale;
  destination: InputLocale;
  retaDate: InputLocale;
  orderQuantity: InputLocale;
  packageType: InputLocale;
  errorMessage: {
    tooLong50: string;
    notDivisible: string;
    notFound: string;
  };
}

export interface TaskDomesticCargoLocale {
  title: string;
  customerCode: InputLocale;
  customerName: InputLocale;
  postcode: InputLocale;
  doNumber: InputLocale;
  deliveryDate: InputLocale;
  materialCode: InputLocale;
  materialName: InputLocale;
  orderQuantity: InputLocale;
  packageType: InputLocale;
  errorMessage: {
    tooLong50: string;
    notDivisible: string;
  };
}
export interface PalletListLocale {
  title: string;
  add: string;
  edit: string;
  table: {
    index: string;
    name: string;
    dimension: string;
    weight: string;
    action: ActionColumnLocale;
  };
}

export interface TaskLogLocale {
  add: string;
  commentLabel: string;
  commentRequired: string;
  comment140: string;
  system: string;
  feedback: string;
  taskLogTitle: string;
  activity: {
    rerun: string;
    run: string;
    email: string;
    upload: string;
    create: string;
    comment: string;
    'update-order-data': string;
  };
}

export interface TaskResultLocale {
  resultHistory: string;
  time: string;
  error: string;
  status: string;
  resultDetail: string;
  weekNo: string;
  customerCode: string;
  destination: string;
  actions: string;
  result: string;
  notEnoughContainers: string;
  waiting: string;
  completed: string;
  timeout: string;
  preview: string;
  email: string;
  sendEmail: string;
  suggestions: string;
  code: string;
  name: string;
  quantity: string;
  forTheOrders: string;
  addMoreMaterials: string;
  sendResult: string;
  task: string;
  send: string;
  recipient: string;
  recipientInputPlaceholder: string;
  extraMessage: string;
  invalidEmail: string;
  somethingWrong: string;
  emailSent: string;
  canNotFindMaterial: string;
  maximizeWeightUtilization: string;
  minimizeTypesOfMaterial: string;
  columnError: string;
  rowError: string;
  overWeightPallet: string;
  overHeightPallet: string;
  summary: string;
}

export interface Locale {
  locale: string;
  login: LoginLocale;
  nav: PageNavigationLocale;
  global: GlobalLocale;
  home: HomeLocale;
  task: TaskLocale;
  taskList: TaskListLocale;
  taskDetail: TaskDetailLocale;
  cargoList: CargoListLocale;
  cargoAdd: CargoAddLocale;
  contactAdd: ContactAddLocale;
  cargoImport: CargoImportLocale;
  containerList: ContainerListLocale;
  constraint: ConstraintLocale;
  account: AccountLocale;
  users: UsersLocale;
  sendEmailModal: SendEmailModalLocale;
  deleteConfirm: DeleteConfirmLocale;
  utilization: UtilizationLocale;
  errorMessage: ErrorMessageLocale;
  contactBook: ContactLocale;
  createTask: CreateTaskLocale;
  taskDomesticCargo: TaskDomesticCargoLocale;
  taskExportCargo: TaskExportCargoLocale;
  palletList: PalletListLocale;
  palletAdd: PalletAddLocale;
  containerAdd: ContainerAddLocale;
  taskLog: TaskLogLocale;
  taskResult: TaskResultLocale;
}
