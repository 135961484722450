import Axios from 'axios';
import { ContainerSpec } from '../pages/masterDataManagement/container/types';
import { PalletType } from '../pages/masterDataManagement/pallet/types';
import { OrderSpecs, NewSubtaskType, NewTaskType } from '../pages/task/types';
import { authRequest, authExcelRequest, baseRequest } from './request';
import { ResultRuleType } from '../components/interactiveScene/types';
import { PackageSpec, MaterialType } from '../pages/masterDataManagement/material/types';
import { LocaleLanguage } from '../locale/types';
import { AccountType } from '../pages/account/types';
import { UserCreateRequest, UserUpdateRequest, UserUpdateType } from '../pages/users/types';
import { ConstraintSpec } from '../pages/masterDataManagement/constraint/types';

export const listTasks = (pageNumber: number) =>
  authRequest()
    .get(`/api/btl/tasks/?page=${pageNumber}`)
    .then((res) => res.data);

export const postTask = (newTask: NewTaskType) =>
  authRequest()
    .post(`/api/btl/tasks/`, newTask)
    .then((res) => res.data);

export const getTask = (taskId: string) => () =>
  authRequest()
    .get(`/api/btl/tasks/${taskId}/`)
    .then((res) => res.data);

export const getTaskOrderData = (taskId: string) => () =>
  authRequest()
    .get(`/api/btl/tasks/${taskId}/goods/`)
    .then((res) => res.data);

export const putOrderData = (taskId: string, orderType: string, orderData: OrderSpecs[]) =>
  authRequest()
    .put(`/api/btl/tasks/${taskId}/goods/?type=${orderType}`, orderData)
    .then((res) => res.data);

export const getSubtaskOrderData = (taskId: string, subtaskId: string) => () =>
  authRequest()
    .get(`/api/btl/tasks/${taskId}/subtasks/${subtaskId}/goods/`)
    .then((res) => res.data);

export const listConstraints = () =>
  authRequest()
    .get(`/api/btl/constraints/`)
    .then((res) => res.data as ConstraintSpec[]);

// task comments
export const getTaskLogs = (taskId: string) => () =>
  authRequest()
    .get(`/api/btl/tasks/${taskId}/`)
    .then((res) => res.data.logs);

export const postTaskComment = (payload: {
  taskId: string;
  activityType: string;
  activityDetail: string;
}) =>
  authRequest()
    .post(`/api/btl/tasks/${payload.taskId}/comments/`, {
      activityType: payload.activityType,
      activityDetail: payload.activityDetail,
    })
    .then((res) => res.data);

export const deleteTaskComment = (payload: { taskId: string; logId: string }) =>
  authRequest()
    .delete(`/api/btl/tasks/${payload.taskId}/comments/${payload.logId}`)
    .then((res) => res.data);

//pallets
export const listPalletBases = () =>
  authRequest()
    .get(`/api/btl/pallets/`)
    .then((res) => res.data.results);

export const updatePallet = (payload: { palletId: string; pallet: PalletType }) =>
  authRequest()
    .patch(`/api/btl/pallets/${payload.palletId}/`, payload.pallet)
    .then((res) => res.data);

export const postPallet = (payload: { pallet: PalletType }) =>
  authRequest()
    .post(`/api/btl/pallets/`, payload.pallet)
    .then((res) => res.data);

export const deletePallet = (payload: string) =>
  authRequest()
    .delete(`/api/btl/pallets/${payload}/`)
    .then((res) => res.data);

//containers
export const listContainers = () => () =>
  authRequest()
    .get(`/api/btl/containers/`)
    .then((res) => res.data);

export const updateContainer = (payload: { containerId: string; container: ContainerSpec }) =>
  authRequest()
    .patch(`/api/btl/containers/${payload.containerId}/`, payload.container)
    .then((res) => res.data);

export const postContainer = (payload: { container: ContainerSpec }) =>
  authRequest()
    .post(`/api/btl/containers/`, payload.container)
    .then((res) => res.data);

export const deleteContainer = (payload: string) =>
  authRequest()
    .delete(`/api/btl/containers/${payload}/`)
    .then((res) => res.data);

// task operations
export const postNewSubtask = (payload: { taskId: string; newSubtask: NewSubtaskType }) =>
  authRequest()
    .post(`/api/btl/tasks/${payload.taskId}/subtasks/`, payload.newSubtask)
    .then((res) => res.data);

export const patchSubtask = (payload: {
  taskId: string;
  subtaskId: string;
  action: 'submit' | 'rerun';
}) =>
  authRequest().patch(`/api/btl/tasks/${payload.taskId}/subtasks/${payload.subtaskId}/`, {
    action: payload.action,
  });

export const getPlanData = (url: string) => () => {
  const urlArray = url.split('/');
  return baseRequest()
    .get(`/btl-results-container/${urlArray.pop()}`)
    .then((res) => res.data);
};

export const importOrders = (payload: { options; orderType: string }) => {
  const { onProgress, onSuccess, onError, file } = payload.options;
  onProgress({ percent: 100 }, file);
  const fileName = 'fileName' + file.name.split('.').pop();
  return authExcelRequest(fileName)
    .put(`/api/btl/tasks/upload_goods/?type=${payload.orderType}`, file)
    .then((res) => {
      onSuccess(res, file);
      return res.data;
    })
    .catch((error) => {
      onError(error);
      return Promise.reject(error);
    });
};

//materials
export const listMaterials = () =>
  authRequest()
    .get('/api/btl/materials/')
    .then((res) => res.data);

export const getContactBooks = () =>
  authRequest()
    .get(`/api/contact-books/`)
    .then((res) => res.data.results.pop());

export const sendPlanToEmails = (
  recipients: { name: string; email: string }[],
  taskId: string,
  subtaskId: string,
  rule: ResultRuleType,
  subject: string,
  comment: string
) =>
  authRequest().post(
    `/api/btl/tasks/${taskId}/subtasks/${subtaskId}/notification/?rule=${JSON.stringify(rule)}`,
    { subject, recipients, comment }
  );
export const listPackages = () =>
  authRequest()
    .get('/api/btl/packages/')
    .then((res) => res.data);

export const getMaterial = (payload: { id: string }) =>
  authRequest()
    .get(`/api/btl/materials/${payload.id}`)
    .then((res) => res.data);

export const addMaterial = (payload: MaterialType) =>
  authRequest()
    .post(`/api/btl/materials/`, payload)
    .then((res) => res.data);

export const deleteMaterial = (payload: { id: string }) =>
  authRequest()
    .delete(`/api/btl/materials/${payload.id}`)
    .then((res) => res.data);

export const createMaterial = (payload: {
  materialNumber: string;
  package: PackageSpec;
  weight: number;
  description: string;
}) =>
  authRequest()
    .post(`/api/btl/materials/`, payload)
    .then((res) => res.data);

export const updateMaterial = (payload: {
  id: string;
  materialNumber: string;
  package: PackageSpec;
  weight: number;
  description: string;
}) =>
  authRequest()
    .patch(`/api/btl/materials/${payload.id}/`, payload)
    .then((res) => res.data);

export const uploadMaterial = (payload) => {
  const { onProgress, onSuccess, onError, file } = payload;
  onProgress({ percent: 100 }, file);
  const fileName = 'fileName' + file.name.split('.').pop();
  return authExcelRequest(fileName)
    .post(`/api/btl/materials/`, file)
    .then((res) => {
      onSuccess(res, file);
      return res.data;
    })
    .catch((error) => {
      onError(error);
      return Promise.reject(error);
    });
};

//auth
export const getVerificationCode = (payload: { email: string; password: string }) =>
  baseRequest()
    .post('/api/auth/login?action=apply', payload)
    .then((res) => res.data);

export const login = (payload: { email: string; password: string; verificationCode: string }) => {
  const { email, password, verificationCode } = payload;
  return baseRequest().post(`/api/auth/login?action=submit&code=${verificationCode}`, {
    email,
    password,
  });
};

export const updateAccount = (payload: UserUpdateType) =>
  authRequest().patch(`/api/users/${payload.id}/`, payload);

export const getAccountInfo = () => authRequest().get('/api/users/');

export const forgetPassword = (payload: {
  email: string;
  lang: LocaleLanguage;
  accountInfo?: AccountType;
  redirectUrl: string;
}) => {
  const { email, lang, accountInfo, redirectUrl } = payload;
  const requestMethod = accountInfo ? authRequest() : baseRequest();
  return requestMethod.put('/api/auth/password?action=forget', { lang, email, redirectUrl });
};

export const resetPassword = (payload: { newPassword; token }) => {
  const request = Axios.create({
    baseURL: '/',
    headers: { Authorization: 'Bearer ' + payload.token },
  });
  return request.put('api/auth/password?action=reset', { newPassword: payload.newPassword });
};

//users
export const getUserRoles = () =>
  authRequest()
    .get('/api/roles/')
    .then((res) => res.data);

export const createUser = (payload: UserCreateRequest) =>
  authRequest().post('/api/users/', payload);

export const updateUser = (payload: UserUpdateRequest) =>
  authRequest().patch(`/api/users/${payload.id}/`, payload);

export const updatePassword = (payload: { user: string; password: string; newPassword: string }) =>
  authRequest().put(`/api/auth/password?action=change`, payload);

//contact book
export const listContactBooks = () =>
  authRequest()
    .get('/api/contact-books')
    .then((res) => res.data.results);

export const deleteContact = (payload: { bookId: string; contactId: string }) =>
  authRequest().delete(`/api/contact-books/${payload.bookId}/contacts/${payload.contactId}/`);

export const createContact = (payload: { email: string; firstName: string; bookId: string }) =>
  authRequest().post(`/api/contact-books/${payload.bookId}/contacts/`, payload);

export const updateContact = (payload: {
  id: string;
  email: string;
  firstName: string;
  bookId: string;
}) => authRequest().patch(`/api/contact-books/${payload.bookId}/contacts/${payload.id}/`, payload);
