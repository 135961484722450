import { authRequest } from '../../utils/request';
import { saveAs } from 'file-saver';

const downloadFile = async (url: string, fileName: string, setProgress: Function) => {
  if (!url) return;
  setProgress(1);
  authRequest()
    .get(url, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent) =>
        setProgress(Math.floor((progressEvent.loaded / progressEvent.total) * 100)),
    })
    .then((res) => saveAs(new Blob([res.data]), fileName))
    .catch((err: Error) => console.error({ message: err }))
    .finally(() => setProgress(0));
};

export default downloadFile;
