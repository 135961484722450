import { Locale, HelpTextLocale } from './types';

const zhCnHelp: HelpTextLocale = {
  customerId: '用于标识此次订单的客户方的代码编号',
  destinationPort: '此次订单的目的地港口名称',
  orderNum: '用于标识此次订单代码编号',
  subtaskName: '方案的名称，用于标识当前方案',
  containerList:
    '可选用的车柜列表（允许选择多种车柜）。进行装载方案的生成时，算法仅选用传入的车柜列表中的车柜类型进行装载',
  palletType: '装载此次订单货物的托盘类型',
  minCostPlanner:
    '费用最少方案: 此方案以车柜组合预估费用最少为原则，进行可用车柜组合的选择。例如，当此次订单中的货物在车柜组合为［2x20GP］和 [1x40HQ] 时均可被装载完全，方案将选择预估费用较少的组合 [1x40HQ] 进行推荐',
  plannerType: '选用的装载方案类型。选择不同的方案类型，将生成不同的装载方案',
  totalGrossWeight: '当前添加货物类型的货物的总毛重，包括货物重量及货物的包装类型的重量',
  totalGrossWeightExtraHint: '注： 若计算所得总重与实际总重不一致，请手动修改以纠正',
};

export const zhCnLocale: Locale = {
  locale: 'zh_CN',
  // ********************* GLOBAL *********************
  global: {
    save: '保存',
    saved: '已保存',
    cancel: '取消',
    saas: 'BTL SaaS Platform',
    next: '下一步',
    back: '上一步',
    edit: '编辑',
    yes: '确认',
    no: '取消',
    error: '错误',
    add: '添加',
    submit: '提交',
    added: '已添加',
    created: '已新建',
    login: '登录',
    logout: '登出',
    deleted: '已删除',
    updated: '已更新',
    submitted: '已提交',
    stable: '正式',
    draft: '草稿',
    serverError: {
      message: '服务器错误',
      description: '请稍后重试',
    },
    unknownError: '未知错误',
    someThingWentWrong: '程序似乎出错了',
    goBackToHome: '返回首页',
    emailSent: '邮件已发送',
    invalidTokenError: '登录状态过期',
    nothingHere: '这儿什么都没有',
  },
  utilization: {
    spaceUtilization: '空间装载率',
    loadUtilization: '质量装载率',
  },
  // ********************* LOGIN PAGE *********************
  login: {
    email: '邮箱',
    password: '密码',
    notification: {
      AUTH_ERROR: {
        message: '无效的邮箱地址或密码',
      },
    },
  },
  // ********************* SIDE NAV *********************
  nav: {
    main: {
      home: '主页',
      newTask: '新建任务',
      tasks: '所有任务',
      cargos: '货物类型',
      pallets: '托盘类型',
      containers: '车柜类型',
      userManual: '用户手册',
      account: '账户',
      users: '用户管理',
      contactBook: '邮箱地址',
      constraints: '装载规则',
      masterData: '基本数据',
      task: '任务',
      none: '无',
    },
    task: {
      home: '主页',
      inputData: '输入数据',
      configTask: '任务配置',
      newTask: '新任务',
      result: '结果',
    },
  },
  // ******************** Send Email Modal ****************
  sendEmailModal: {
    help: '一些电子邮箱地址似乎是无效的',
    title: '发送电子邮件',
    hint: '*邮件地址一行一个',
    cancel: '取消',
    send: '发送',
    subject: '主题',
    recipient: '收件人',
    loadingPlan: '装箱方案',
    errorMessage: {
      subjectEmpty: '请填写邮件主题',
      recipientEmpty: '请至少填写一个收件人',
    },
  },

  // ********************* Delete Confirm ****************
  deleteConfirm: {
    title: '确认删除吗',
    okText: '确认',
    cancelText: '取消',
    deleteText: '删除',
  },
  // ********************* HOME PAGE *********************
  home: {
    title: '主页',
    chinese: '中文版',
    english: '英文版',
    introduction: {
      title: '欢迎来到AI-CLP SaaS平台',
      content:
        'AI-CLP提供可以代替人工操作的最佳的集装箱预订策略和装载计划解决方案。它可以在各种复杂场景中，满足不同的负载要求如货物承重、指定规格搭配和重量分配平衡等，并达到较高的装载利用率',
    },
    tutorial: {
      title: '用户手册',
      content: '点击下载用户手册：',
    },
    taskStepNew: {
      title: '新建任务',
      content: '单击左侧菜单中的“新建任务”以开始配置。',
    },
    taskStepOrderData: {
      title: '输入订单数据',
      content: '从预定义类型的列表中选择要装载的货物。',
    },
    taskStepConfigure: {
      title: '进行任务配置',
      content: '配置方案以找到该场景下的优化解决方案。',
    },
  },
  // ********************* ACCOUNT PAGE *********************
  account: {
    title: '账户中心',
    accountInfo: {
      subtitle: '账户信息',
      userName: '姓名',
      email: '电子邮箱',
      organization: '组织',
      department: '部门',
      role: '角色',
      loginCode: '验证码',
    },
    languageSetting: 'Language Setting / 语言设置',
    accountSecurity: {
      subtitle: '账户安全',
      passwordLabel: '密码',
      changePassword: {
        title: '修改密码',
        change: '修改',
        cancel: '取消',
        currentPassword: '当前密码',
        newPassword: '新密码',
        confirmPassword: '重复新密码',
        message: {
          changed: '修改成功',
          currentPasswordEmpty: '请输入新密码',
          lengthNotEnough: '密码长度至少为8位',
          confirmPasswordEmpty: '请确认新密码',
          notMatchingNewPassword: '两次新密码输入不一致',
          sameAsCurrentPassword: '新密码不能与当前密码相同',
          passwordNotStrongEnough: '密码须包含一位大写字母，一位小写字母，一位数字和一位特殊符号',
        },
      },
    },
    logout: '退出登录',
    login: '登录',
    getCode: '获取验证码',
    forgotPassword: '忘记密码',
    resetPassword: '重置密码',
    sendResetEmail: '发送重置邮件',
    backToLogin: '返回登录',
    message: {
      emailEmpty: '请输入邮件地址',
      loginCodeEmpty: '请输入邮件验证码',
      passwordEmpty: '请输入密码',
      loginSuccessful: '登录成功',
      invalidEmailOrPassword: '邮件地址或密码错误',
      serverErrorFailed: '服务器错误， 登录失败',
      unknownErrorFailed: '未知错误，登录失败',
      logoutSuccessful: '成功退出登录',
      accessDenied: '拒绝访问',
      invalidVerificationCode: '验证码错误',
      tooManyRequestPrefix: '请求过于频繁，请等待',
      tooManyRequestSuffix: '秒后再试',
      passwordNotStrongEnough: '密码须包含一位大写字母，一位小写字母，一位数字和一位特殊符号',
    },
  },
  // ********************* USERS PAGE *********************
  users: {
    title: '用户管理',
    userTable: {
      name: '姓名',
      email: '电子邮件',
      role: '角色',
      organization: '组织',
      department: '部门',
      isActive: '账户状态',
      action: '操作',
    },
    admin: '管理员',
    user: '用户',
    isActive: '正常',
    locked: '锁定',
    createUserModal: {
      title: '创建新用户',
      emailLabel: '电子邮件',
      firstNameLabel: '名字',
      lastNameLabel: '姓氏',
      roleLabel: '角色',
      create: '创建',
      cancel: '取消',
      addUser: '创建新用户',
      message: {
        emailEmpty: '请输入一个有效的电子邮件地址',
        firstNameEmpty: '请输入名字',
        lastNameEmpty: '请输入姓氏',
        roleEmpty: '请选择角色',
        cancel: '取消',
      },
    },
    resetPassword: '重置密码',
    message: {
      changingOwnActive: '不能将自己锁定',
      changingOwnRole: '不能修改自己的角色',
      serverErrorCreatFailed: '服务器错误， 创建用户失败',
      emailAlreadyRegistered: '该电子邮箱地址已经被注册',
      newUserCreated: '新用户创建成功',
      passwordChange: '修改密码成功， 请使用新密码重新登录',
      invalidCurrentPassword: '当前密码错误',
      serverErrorUpdateFailed: '服务器错误，修改密码失败',
      resetEmailSentTo: '重置密码邮件已发送至：',
      resetExceedLimit: '重置密码请求已超过上限， 请稍后重试。请等待：',
      minutes: '分钟',
      secondes: '秒',
      serverErrorResetFailed: '服务器错误，重置密码失败',
      resetSuccessful: '重置密码成功',
      resetLinkExpired: '该重置密码链接已经失效',
      userPasswordIncorrect: '密码错误',
      userAdded: '添加成功',
    },
  },
  // ********************* Material LIST PAGE *********************
  cargoList: {
    title: '货物类型',
    add: '添加货物类型',
    edit: '编辑货物类型',
    searchPlaceHolder: '搜索物料编号，包装类型',
    table: {
      index: '序号',
      materialNum: '材料编号',
      packageType: '包装类型',
      weight: '净重',
      description: '描述',
      action: { name: '操作' },
    },
    notification: {
      'cargo material with this Unique material number already exists.': {
        message: '材料编号已存在',
      },
      PACKAGE_ERROR: {
        message: '包装类型无效',
        description: '请刷新后重试',
      },
    },
  },
  // ********************* CARGO CREATE PAGE *********************
  cargoAdd: {
    title: '添加货物类型',
    save: '保存',
    cancel: '取消',
    materialNum: {
      label: '材料编号',
      required: '请输入该货物类型的材料编号',
    },
    package: {
      label: '包装类型',
      required: '请选择一个包装类型',
    },
    packageWeight: {
      label: '包装重量',
      required: '请输入包装重量',
    },
    weight: {
      label: '毛重',
      required: '请输入该货物类型的毛重',
    },
    materialDescription: {
      label: '描述',
      required: '请输入该货物类型的描述',
    },
    netWeight: {
      label: '净重',
      required: '请输入该货物类型的净重',
    },
    repeatedMaterialNumber: '材料编号已存在',
  },
  cargoImport: {
    title: '从文件导入',
    dropZoneText: '单击或将文件拖拽到此处进行上传',
    closeText: '关闭',
    downloadTemplate: '下载文件模板：',
    here: '下载',
  },
  // ********************* CONTAINER LIST PAGE *********************
  containerList: {
    title: '车柜类型',
    add: '添加车柜',
    edit: '编辑车柜',
    shipping: '货柜',
    truck: '卡车',
    table: {
      index: '序号',
      name: '名称',
      type: '类型',
      maxPallet: '最大托盘数',
      dimension: '尺寸',
      weight: '自重',
      capacity: '载重能力',
      cost: {
        label: '参考费用',
        help: '各个车柜费用的估计比例,用于向装载算法提供推荐上的算法参考值',
      },
      action: { name: '操作' },
    },
    message: {
      containerDeleted: '删除成功',
    },
  },
  // ********************* CONSTRAINT PAGE *********************
  constraint: {
    title: '装载规则',
    palletType: '托盘类型',
    packageType: '包装类型',
    packagePerLayer: '每层货物数量',
    layerPerPallet: '托盘内货物层数',
    layerPerTrailer: '货柜内托盘层数',
  },
  // ********************* TASK LIST PAGE *********************
  taskList: {
    title: '所有任务',
    table: {
      index: '序号',
      name: '任务名称',
      label: '标签',
      owner: '所有者',
      created: '创建日期',
      completed: '成功',
      action: {
        name: '操作',
        rowItems: {
          detail: '查看',
        },
      },
    },
  },
  taskDetail: {
    title: '任务详情',
    downloadInput: '下载货单',
    table: {
      favorite: '选择方案',
      name: '方案名',
      plannerType: '方案类型',
      containerList: '备选车柜',
      status: '状态',
      numContainers: '使用车柜',
      action: {
        name: '操作',
        rowItems: {
          inspect: '预览',
          download: '下载PDF',
        },
      },
    },
  },
  // ********************* TASK PAGE *********************
  task: {
    taskType: '任务类型',
    inputDataTitle: '输入数据',
    configureTaskTitle: '任务配置',
    add: '添加货物',
    edit: '编辑货物',
    fill: '填写',
    submitted: '装箱规划任务已提交',
    lineNumber: '行号',
    label: '标签',
    domestic: '国内',
    export: '出口',
    newTask: '新任务',
    createNewTask: '创建新任务',
    configuration: '配置',
    generatePlan: '生成规划',
    selectTruckContainer: '选择卡车/货柜',
    emptyContainers: '请选择卡车/货柜',
    emptyGoods: '请添加货物',
    batchDelete: '批量删除',
    selectAll: '全选',
    unSelectAll: '取消全选',
    selectCurrentPage: '选择当前页',
    unSelectCurrentPage: '取消选择当前页',
    inverseSelect: '反选',
    cancel: '取消',
    delete: '删除',
    quickSelection: '快速选择',
    errorMessages: {
      tooMuchGoodsWarning: '货物数量或质量较大，所需计算时间可能较长',
      tooMuchGoodsError: '货物数量或质量过大，请减少货物后重试',
      noGoodsError: '未添加任何货物',
      noSubtaskError: '未配置任何方案',
      zeroAttributeRowError: '在下列序号处检测到货物数量或总毛重异常',
      abnormalDensityWarning: '在下列序号处检测到货物总毛重（基于当前货物数量)可能异常',
      orderNumberEmptyMessage: '请填写订单编码，或任意内容以便今后查找',
    },
    taskInfo: {
      title: '任务信息',
      customerId: {
        label: '客户编号',
        help: zhCnHelp.customerId,
      },
      destinationPort: {
        label: '目的地港口',
        help: zhCnHelp.destinationPort,
      },
      orderNum: {
        label: '订单编码',
        help: zhCnHelp.orderNum,
      },
      palletType: {
        label: '托盘类型',
        help: zhCnHelp.palletType,
      },
      taskType: {
        label: '任务类型',
      },
    },

    domesticCargoList: {
      title: '货物列表',
      add: '添加货物',
      table: {
        index: '序号',
        customerCode: '客户编号',
        customerName: '客户名称',
        postcode: '邮编',
        doNumber: 'DO',
        deliveryDate: '发货日期',
        materialCode: '物料编号',
        materialName: '物料名称',
        orderQuantity: '订单数量',
        actions: '操作',
      },
    },

    exportCargoList: {
      title: '货物列表',
      add: '添加货物',
      table: {
        index: '序号',
        soNumber: 'SO',
        customerCode: '客户编号',
        customerName: '客户名称',
        materialCode: '物料编号',
        materialName: '物料名称',
        orderQuantity: '订单数量',
        retaDate: '日期',
        destination: '目的地',
        actions: '操作',
      },
    },

    selectCargo: {
      title: '添加货物',
      materialNum: {
        label: '材料编号',
        required: '请选择一个材料编号以指定货物类型选择',
      },
      materialDescription: {
        label: '描述',
        required: '请选择一个描述以指定货物类型选择',
      },
      package: {
        label: '包装类型',
        required: '请选择一个包装类型',
      },
      quantity: {
        label: '数量',
        required: '请输入已指定的货物类型的货物数量',
      },
      totalWeight: {
        label: '总毛重',
        required: '请输入已指定的货物类型的货物总毛重',
        help: zhCnHelp.totalGrossWeight + '\n' + zhCnHelp.totalGrossWeightExtraHint,
      },
    },
    importCargo: {
      title: '从文件导入',
      draggerText: '单击或将文件拖拽到此处进行上传',
      downloadTemplate: '点击下载文件模板：',
      here: '下载',
    },

    configure: {
      title: '方案列表',
      addSubTask: '添加优化方案',
      deleteWarning: '是否要删除该方案?',
      table: {
        index: '序号',
        name: '名称',
        plannerType: {
          label: '方案类型',
          help: zhCnHelp.plannerType,
        },
        containerList: {
          label: '车柜列表',
          help: zhCnHelp.containerList,
        },
        maxTime: '预计时间',
        maxTimeUnit: '分钟',
        moreThan: '多于',
        lessThan: '少于',
        action: '操作',
      },
    },

    addSubtask: {
      title: '添加方案',
      addContainer: '添加车柜',
      add: '添加',
      cancel: '取消',
      numberLabel: '个数: ',
      name: {
        label: '名称',
        default: '默认名称',
        help: zhCnHelp.subtaskName,
      },
      maxTime: {
        label: '预计时间(分)',
      },
      plannerType: {
        label: '方案类型',
        required: '请选择一个方案类型',
        help: zhCnHelp.plannerType,
        selections: [
          {
            label: 'MIN_COST --- 费用最少方案',
            help: zhCnHelp.minCostPlanner,
          },
        ],
      },
      containerList: {
        label: '车柜列表',
        required: '请至少选择一个车柜类型',
        help: zhCnHelp.containerList,
      },
    },

    review: {
      title: '审阅并提交',
      taskName: '任务名',
      taskType: '任务类型',
      numCargos: '货物行数',
      numSubtasks: '方案数量',
      orderNum: '订单编码',
      customerId: '客户编号',
      destinationPort: '目的地港口',
      palletType: '托盘类型',
      label: '标签',
    },
    notification: {
      emptyFile: {
        message: '文件为空',
        description: '请检查文件并重试',
      },
      fileTypeError: {
        message: '文件错误',
        description: '不支持的文件格式，请下载使用文件模板',
      },
    },
  },
  errorMessage: {
    and: '和',
    more: '个其他值',
    rows: '行号:',
    column: '列',
    notFound: '未找到对应数据',
    notAllowed: '操作被禁止',
    alreadyExists: '已经存在',
    unknownAPIError: '未知接口错误',
    notAuthorized: '权限不足',
    samePassword: '新旧密码不能一样',
    invalidFileExtension: '不支持该文件拓展名',
    fileNotReadable: '无法读取该文件',
    fileEmpty: '文件为空',
    missingColumn: '缺少列',
    notUnique: '数据有重复',
    notCategorical: '错误的数据类型',
    notValidNumber: '不是数值',
    notValidType: '数据类型错误',
    tooSmall: '数值过小',
    tooLarge: '数值过大',
    notPositive: '数值不是正数',
    outOfRange: '数值超出范围',
    notNegative: '数值不是负数',
    notEndWith: '正确的结尾',
    invalidConfigValue: '无效的配置',
    emptyValue: '空值',
    systemError: '系统错误',
    valueNotAllowed: '无效的值',
    crossDepartment: '禁止跨部门操作',
    notNewPassword: '新密码与旧密码相同',
    notDivisible: '货物数量无法被物料净重整除',
    backToTaskList: '返回任务列表',
  },
  contactBook: {
    title: '邮箱地址',
    add: '添加邮箱',
    edit: '编辑邮箱',
    searchPlaceHolder: '搜索',
    table: {
      index: '序号',
      firstName: '名称',
      email: '邮箱',
      bookName: '群组',
      action: { name: '操作' },
    },
    message: {
      emailDeleted: '删除成功',
    },
  },
  contactAdd: {
    title: '添加邮箱',
    save: '保存',
    cancel: '取消',
    firstName: {
      label: '名称',
      required: '请输入名称',
    },
    firstNameTooLong: '名称过长',
    invalidEmail: '请输入正确的邮箱地址',
    email: {
      label: '邮箱',
      required: '请输入邮箱',
    },
    message: {
      repeatedEmail: '该邮箱已存在！',
      emailAdded: '添加成功',
      emailDeleted: '删除成功',
      emailUpdated: '更新成功',
    },
  },
  createTask: {
    title: '新任务',
    create: '创建',
    cancel: '取消',
    nameTooLong: '名称过长',
    name: {
      label: '任务名',
      required: '请输入任务名称',
    },
    orderType: {
      label: '订单类型',
      required: '请选择订单类型',
    },
    palletType: {
      label: '托盘',
      required: '请选择托盘',
    },
    domestic: '国内',
    export: '出口',
  },
  taskDomesticCargo: {
    title: '添加国内货物',
    customerCode: { label: '客户编号', required: '请填写客户编号' },
    customerName: { label: '客户名称' },
    postcode: { label: '邮编', required: '请填写邮编' },
    doNumber: { label: 'DO', required: '请填写DO' },
    deliveryDate: { label: '发货日期', required: '请填写发货日期' },
    materialCode: { label: '物料编号', required: '请填写物料编号' },
    materialName: { label: '物料名称', required: '请填写物料名称' },
    orderQuantity: { label: '订单数量', required: '请填写订单数量' },
    packageType: { label: '包装类型', required: '请选择包装类型' },
    errorMessage: {
      tooLong50: '文字过长',
      notDivisible: '订单数量必需能被物料重量整除',
    },
  },
  taskExportCargo: {
    title: '添加出口货物',
    customerCode: { label: '客户编号', required: '请填写客户编号' },
    customerName: { label: '客户名称' },
    soNumber: { label: 'SO', required: '请填写SO' },
    retaDate: { label: 'RETA 日期', required: '请填写RETA日期' },
    destination: { label: '目的地', required: '请填写目的地' },
    materialCode: { label: '物料编号', required: '请填写物料编号' },
    materialName: { label: '物料名称', required: '请填写物料名称' },
    orderQuantity: { label: '订单数量', required: '请填写订单数量' },
    packageType: { label: '包装类型', required: '请选择包装类型' },
    errorMessage: {
      tooLong50: '文字过长',
      notDivisible: '订单数量必需能被物料重量整除',
      notFound: '未找到，请重试',
    },
  },
  palletList: {
    title: '托盘类型',
    add: '添加托盘',
    edit: '编辑托盘',
    table: {
      index: '序号',
      name: '名称',
      dimension: '尺寸',
      weight: '重量',
      action: { name: '操作' },
    },
  },
  palletAdd: {
    title: '添加托盘',
    save: '保存',
    cancel: '取消',
    name: {
      label: '名称',
      required: '请填写托盘名称',
    },
    length: {
      label: '长度',
      required: '请填写托盘长度',
    },
    width: {
      label: '宽度',
      required: '请填写托盘宽度',
    },
    height: {
      label: '高度',
      required: '请填写托盘高度',
    },
    weight: {
      label: '重量',
      required: '请填写托盘重量',
    },
    nameTooLong: '托盘名称过长',
    repeatedName: '托盘名称已存在！',
  },
  containerAdd: {
    title: '添加车柜',
    save: '保存',
    cancel: '取消',
    name: {
      label: '名称',
      required: '请选择名称',
    },
    type: {
      label: '类型',
      required: '请选择类型',
    },
    length: {
      label: '长度',
      required: '请填写长度',
    },
    width: {
      label: '宽度',
      required: '请填写宽度',
    },
    height: {
      label: '高度',
      required: '请填写高度',
    },
    weight: {
      label: '自重',
      required: '请填写重量',
    },
    maxLoad: {
      label: '载重能力',
      required: '请填写载重能力',
    },
    maxPallet: {
      label: '最大托盘数',
      required: '请填写最大托盘数',
    },
    refCost: {
      label: '参考费用',
      required: '请填写参考费用',
    },
    nameTooLong: '名称过长',
    shipping: '货柜',
    truck: '卡车',
    repeatedName: '车柜名称已存在！',
    message: {
      containerAdded: '添加成功',
      containerUpdated: '更新成功',
    },
  },
  taskLog: {
    add: '添加',
    commentLabel: '添加一条评论',
    commentRequired: '评论不能为空',
    comment140: '评论不能超过140字',
    feedback: '反馈',
    system: '系统',
    taskLogTitle: '任务日志和反馈',
    activity: {
      create: '创建任务',
      upload: '上传文件',
      run: '执行任务',
      rerun: '再次执行任务',
      email: '发送邮件',
      comment: '评论',
      'update-order-data': '货物更新',
    },
  },
  taskResult: {
    resultHistory: '计算历史',
    resultDetail: '计算详情',
    time: '时间',
    error: '错误',
    status: '状态',
    weekNo: '周编号',
    customerCode: '客户编号',
    destination: '目的地',
    actions: '操作',
    result: '计算结果',
    notEnoughContainers: '车柜不足，无法装载所有订单',
    waiting: '等待中',
    completed: '已完成',
    timeout: '运算超时',
    preview: '预览',
    email: '邮件',
    sendEmail: '发送邮件',
    suggestions: '建议',
    code: '编号',
    name: '名称',
    quantity: '数量',
    forTheOrders: '对于此类订单:',
    addMoreMaterials: '继续添加材料，采用如下组合填充剩余空间:',
    sendResult: '发送以下内容',
    task: '任务',
    send: '发送',
    recipient: '收件人',
    recipientInputPlaceholder: '请输入收件人',
    extraMessage: '额外信息',
    invalidEmail: '收件人信息有误',
    somethingWrong: '发送出错，请查看收件人地址是否正确',
    emailSent: '封邮件已发送',
    canNotFindMaterial: '未找到物料: ',
    maximizeWeightUtilization: '最佳载重率用率',
    minimizeTypesOfMaterial: '使用最少种物料',
    columnError: '货柜宽度过短或托盘宽度过长，无法装载。货柜宽应是托盘宽1～2倍（含）之间。',
    rowError: '货柜长度过短或托盘长度过长，无法装载。',
    overWeightPallet: '有托盘货物过重超过货柜承重，无法装载。',
    overHeightPallet: '有托盘货物过高超过货柜高度，无法装载',
    summary: '总计',
  },
};
