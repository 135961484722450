import {
  basfBlueMaterial,
  basfWhiteMaterial,
  basfRedMaterial,
  basfPalletMaterial,
} from './meshLibrary';

export const basfPalletLength = 1140;
export const basfPalletHeight = 130;

export const basfGeometryDB = {
  TRUCK: '/mesh/truck.stl',
  CONTAINER: '/mesh/container.stl',
  PALLET: '/mesh/pallet.stl',
  STEEL_DRUM_BLUE_CLOSE_TOP_200L: '/mesh/200L.stl',
  STEEL_DRUM_BLUE_OPEN_TOP_200L: '/mesh/200L.stl',
  STEEL_DRUM_RED_CLOSE_TOP_200L: '/mesh/200L.stl',
  STEEL_DRUM_BLUE_CLOSE_TOP_60L: '/mesh/60L.stl',
  STEEL_DRUM_BLUE_OPEN_TOP_60L: '/mesh/60L.stl',
  STEEL_DRUM_RED_CLOSE_TOP_60L: '/mesh/60L.stl',
  STEEL_DRUM_BLUE_OPEN_TOP_60L_HAND_COA: '/mesh/60L.stl',
  STEEL_DRUM_RED_CLOSE_TOP_30L: '/mesh/30L.stl',
  STEEL_DRUM_BLUE_CLOSE_TOP_30L: '/mesh/30L.stl',
  STEEL_DRUM_BLUE_CLOSE_TOP_20L: '/mesh/20L.stl',
  STEEL_DRUM_RED_CLOSE_TOP_20L: '/mesh/20L.stl',
  STEEL_CANISTER_BLUE_CLOSE_TOP_20L: '/mesh/canister20L.stl',
  STEEL_CANISTER_RED_CLOSE_TOP_20L: '/mesh/canister20L.stl',
  PLASTIC_CAN_30L: '/mesh/plastic30L.stl',
  PLASTIC_CAN_25L: '/mesh/plastic25L.stl',
  PLASTIC_CAN_25L_BL: '/mesh/plastic25L.stl',
  PLASTIC_CAN_10L: '/mesh/plastic10L.stl',
  PLASTIC_CAN_1000L: '/mesh/ibc.stl',
  PLASTIC_CAN_1000L_RECYCLE_STEEL_PALLET: '/mesh/ibc.stl',
  PLASTIC_CAN_1050L_BFV80: '/mesh/ibc.stl',
  PLASTIC_CAN_1050L: '/mesh/ibc.stl',
  PLASTIC_CAN_1050L_DN50: '/mesh/ibc.stl',
  PLASTIC_CAN_F_IBC_FABEIC_COATED: '/mesh/ibc.stl',
};

export const basfItemMaterials = {
  PALLET: basfPalletMaterial,
  STEEL_DRUM_BLUE_CLOSE_TOP_200L: basfBlueMaterial,
  STEEL_DRUM_BLUE_OPEN_TOP_200L: basfBlueMaterial,
  STEEL_DRUM_RED_CLOSE_TOP_200L: basfRedMaterial,
  STEEL_DRUM_BLUE_CLOSE_TOP_60L: basfBlueMaterial,
  STEEL_DRUM_BLUE_OPEN_TOP_60L: basfBlueMaterial,
  STEEL_DRUM_RED_CLOSE_TOP_60L: basfRedMaterial,
  STEEL_DRUM_BLUE_OPEN_TOP_60L_HAND_COA: basfBlueMaterial,
  STEEL_DRUM_RED_CLOSE_TOP_30L: basfRedMaterial,
  STEEL_DRUM_BLUE_CLOSE_TOP_30L: basfBlueMaterial,
  STEEL_DRUM_BLUE_CLOSE_TOP_20L: basfBlueMaterial,
  STEEL_DRUM_RED_CLOSE_TOP_20L: basfRedMaterial,
  STEEL_CANISTER_BLUE_CLOSE_TOP_20L: basfBlueMaterial,
  STEEL_CANISTER_RED_CLOSE_TOP_20L: basfRedMaterial,
  PLASTIC_CAN_30L: basfWhiteMaterial,
  PLASTIC_CAN_25L: basfWhiteMaterial,
  PLASTIC_CAN_25L_BL: basfWhiteMaterial,
  PLASTIC_CAN_10L: basfWhiteMaterial,
  PLASTIC_CAN_1000L: basfWhiteMaterial,
  PLASTIC_CAN_1000L_RECYCLE_STEEL_PALLET: basfWhiteMaterial,
  PLASTIC_CAN_1050L_BFV80: basfWhiteMaterial,
  PLASTIC_CAN_1050L: basfWhiteMaterial,
  PLASTIC_CAN_1050L_DN50: basfWhiteMaterial,
  PLASTIC_CAN_F_IBC_FABEIC_COATED: basfWhiteMaterial,
};
