import * as React from 'react';
import { Button, message } from 'antd';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { getTaskOrderData, patchSubtask, postNewSubtask } from '../../../utils/api';
import { currentConfigurationState } from '../atoms';
import { NewSubtaskType, OrderSpecs } from '../types';
import { TaskLocale } from '../../../locale/types';
import { useLocaleData } from '../../../locale';

const GeneratePlanButton = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const currentConfiguration = useRecoilValue(currentConfigurationState);
  const locale: TaskLocale = useLocaleData<TaskLocale>('task');

  const { data: orderData, isFetching: isFetchingTaskOrderData } = useQuery<OrderSpecs[]>(
    ['taskOrderData', taskId],
    getTaskOrderData(taskId),
    { enabled: !!taskId }
  );

  const postSubtaskMutation = useMutation(postNewSubtask, {
    onSuccess: (newSubtask) =>
      patchSubtaskMutation.mutate({ taskId, subtaskId: newSubtask.id, action: 'submit' }),
    onSettled: (res) => queryClient.invalidateQueries(['task', taskId]),
  });

  const patchSubtaskMutation = useMutation(patchSubtask, {
    onSuccess: (res) => history.push(`/task/${taskId}/result/${res.data.id}`),
    onSettled: (res) => queryClient.invalidateQueries(['task', taskId]),
  });

  const createAndRun = () => {
    if (currentConfiguration.containerList.length === 0) {
      return message.error(locale.emptyContainers);
    }

    if (orderData.length < 1) {
      return message.error(locale.emptyGoods);
    }

    const newSubtask: NewSubtaskType = {
      name: new Date().toTimeString(),
      plannerType: 'MIN_COST',
      maxTime: 300,
      consolidationType: 'export',
      containerList: currentConfiguration.containerList,
    };

    postSubtaskMutation.mutate({ taskId, newSubtask });
  };
  return (
    <span>
      <Button
        type="primary"
        loading={postSubtaskMutation.isLoading || patchSubtaskMutation.isLoading}
        onClick={createAndRun}
        disabled={isFetchingTaskOrderData}
        data-test-id="generatePlan"
      >
        {locale.generatePlan}
      </Button>
    </span>
  );
};

export default GeneratePlanButton;
