import {
  MeshLambertMaterial,
  MeshBasicMaterial,
  LineBasicMaterial,
  DoubleSide,
  MeshPhysicalMaterial,
  Color,
} from 'three';

// Cargo Materials
export const cargoDefaultMaterial = new MeshLambertMaterial({ color: 0xfeb74c });
export const cargoDefaultLineMaterial = new LineBasicMaterial({ color: 0x315a70, linewidth: 1.5 });

export const cargoErrorMaterial = new MeshLambertMaterial({ color: 0xff2222 });
export const cargoOnSelectMaterial = new MeshLambertMaterial({ color: 0x3333dd });

// Trailer Materials

export const trailerLineMaterial = new LineBasicMaterial({ color: 0x315a70, linewidth: 1 });

export const transparentWallMaterial = new MeshBasicMaterial({
  color: 0xffffff,
  opacity: 0.5,
  transparent: true,
  side: DoubleSide,
});

// Weight Center Materials and Trailer Centers
export const trailerCenterColor = 0x5555cc;
export const weightCenterColor = 0xcc5555;

export function hashStringToHex(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 7) - hash);
  }
  return hash & 0x55ffff;
}

// Constrains
export const constraintDefaultMaterial = new MeshPhysicalMaterial({
  color: 0x000000,
  opacity: 0.8,
  transparent: true,
});

// basf materials
export const basfPalletMaterial = new MeshLambertMaterial({ color: new Color('grey') });
export const basfRedMaterial = new MeshLambertMaterial({ color: new Color('tomato') });
export const basfBlueMaterial = new MeshLambertMaterial({ color: new Color('royalblue') });
export const basfWhiteMaterial = new MeshLambertMaterial({ color: new Color('white') });
