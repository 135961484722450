import { atom } from 'recoil';
import { SubtaskType, TaskType } from './types';

export const currentTaskState = atom<TaskType>({
  key: 'currentTaskState',
  default: undefined,
});

export const currentConfigurationState = atom({
  key: 'currentConfigurationState',
  default: {
    containerList: [],
    consolidationType: 'export',
  },
});

export const currentSubtaskState = atom<SubtaskType>({
  key: 'currentSubtaskState',
  default: undefined,
});
