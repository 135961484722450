import { Card, Typography } from 'antd';
import * as React from 'react';
import CreateTaskForm from '../components/createTaskForm';
import { TaskLocale } from '../../../locale/types';
import { useLocaleData } from '../../../locale';

const NewTaskPage = () => {
  const locale: TaskLocale = useLocaleData<TaskLocale>('task');
  return (
    <div>
      <Typography.Title level={2}>{locale.newTask}</Typography.Title>
      <div style={{ width: '400px', margin: '10px auto' }}>
        <Card title={locale.createNewTask}>
          <CreateTaskForm />
        </Card>
      </div>
    </div>
  );
};

export default NewTaskPage;
