import React, { useState, useEffect } from 'react';

import { EditOutlined } from '@ant-design/icons';

import { Card, Table, Typography, Button, Input } from 'antd';
import { MaterialType } from './types';
import DeleteConfirm from '../../../components/deleteConfirm';
import FormModal from '../../../components/formModal';
import cargoForm from './components/materialForm';
import { useLocaleData } from '../../../locale';
import { CargoListLocale, Locale } from '../../../locale/types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { listMaterials, deleteMaterial } from '../../../utils/api';
import ImportCargoesModal from './components/importCargoesModal';

type CargoTableFormat = {
  key: string;
  index: number;
  materialNumber: string;
  package?: string;
  weight: string;
  description: string;
};

const useTableDataSource = (materialList: MaterialType[]): CargoTableFormat[] => {
  const [dataSource, setDataSource] = useState<CargoTableFormat[]>([]);

  useEffect(() => {
    if (materialList) {
      const parsed = materialList.map((spec: MaterialType, index: number) => ({
        key: spec.id,
        index: index + 1,
        materialNumber: spec.materialNumber,
        package: spec.package?.description,
        weight: `${spec.weight} KG`,
        description: spec.description,
      }));
      setDataSource(parsed);
    } else {
      setDataSource([]);
    }
  }, [materialList]);

  return dataSource;
};

const MaterialManagementPage = (): JSX.Element => {
  // Hooks
  const locale = useLocaleData<CargoListLocale>((locale: Locale) => locale.cargoList);
  const queryClient = useQueryClient();
  const { data: materialList, isLoading } = useQuery('materials', listMaterials);
  const deleteMaterialMutation = useMutation(deleteMaterial, {
    onSuccess: () => {
      queryClient.invalidateQueries('materials');
    },
  });
  const tableDataSource = useTableDataSource(materialList);
  const [onEditMaterial, setOnEditMaterial] = useState<MaterialType | undefined>(undefined);
  const [cargoFormModalVisible, setCargoFormVisible] = useState(false);

  const [searchFilter, setSearchFilter] = useState('');

  // event handling
  const openModal = (): void => setCargoFormVisible(true);
  const closeModal = (): void => setCargoFormVisible(false);

  const addNewMaterial = (): void => {
    setOnEditMaterial(undefined);
    openModal();
  };

  const editMaterial =
    (material: CargoTableFormat) =>
    (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      e && e.preventDefault();
      setOnEditMaterial(materialList.find((x) => x.id === material.key));
      openModal();
    };
  const handleDelete =
    (material: CargoTableFormat) =>
    (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined): void => {
      e && e.preventDefault();
      deleteMaterialMutation.mutate({ id: material.key });
    };

  const renderActions = (material: CargoTableFormat): JSX.Element => (
    <div>
      <EditOutlined
        onClick={editMaterial(material)}
        style={{ marginRight: '20px' }}
        data-test-id="edit"
      />
      <DeleteConfirm onConfirm={handleDelete(material)} />
    </div>
  );
  // Data table column definition
  const tableColDef = [
    { title: locale.table.index, dataIndex: 'index', key: 'index' },
    { title: locale.table.materialNum, dataIndex: 'materialNumber', key: 'materialNumber' },
    { title: locale.table.packageType, dataIndex: 'package', key: 'package' },
    { title: locale.table.weight, dataIndex: 'weight', key: 'weight' },
    { title: locale.table.description, dataIndex: 'description', key: 'description' },
    {
      title: locale.table.action.name,
      key: 'action',
      render: renderActions,
    },
  ];
  const cargoFormModalSettings = {
    openModal,
    closeModal,
    title: onEditMaterial ? locale.edit : locale.add,
    visible: cargoFormModalVisible,
    component: cargoForm,
    componentProps: { onEditMaterial },
  };

  return (
    <div>
      <Typography.Title level={2}>{locale.title}</Typography.Title>

      <Card
        title={
          <h3>
            <span>
              <Button onClick={addNewMaterial} type="link" data-test-id="addCargo">
                {locale.add}
              </Button>

              <ImportCargoesModal />
            </span>

            <span style={{ float: 'right', width: '350px' }}>
              <Input.Search
                placeholder={locale.searchPlaceHolder}
                onChange={(e) => setSearchFilter(e.target.value)}
                allowClear
              />
            </span>
          </h3>
        }
      >
        <Table
          columns={tableColDef}
          dataSource={tableDataSource.filter(
            (x) => x.materialNumber?.includes(searchFilter) || x.package?.includes(searchFilter)
          )}
          loading={isLoading}
        />
      </Card>
      <FormModal {...cargoFormModalSettings} />
    </div>
  );
};

export default MaterialManagementPage;
