import { atom } from 'recoil';
import { LocaleLanguage } from '../../locale/types';
import { AccountType } from './types';

export const accountInfo = atom<AccountType>({
  key: 'userAccountInfo',
  default: undefined,
});

export const currentLocaleState = atom<LocaleLanguage>({
  key: 'currentLocaleState',
  default: LocaleLanguage.EN_US,
});
