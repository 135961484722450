import { Card, Typography, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useLocaleData } from '../../../locale';
import { ConstraintLocale, Locale } from '../../../locale/types';
import { listConstraints } from '../../../utils/api';
import { ConstraintSpec } from './types';

const ConstraintPage = () => {
  const { data, isLoading } = useQuery<ConstraintSpec[]>('constraints', listConstraints);
  const locale = useLocaleData<ConstraintLocale>((locale: Locale) => locale.constraint);
  const columns: ColumnProps<ConstraintSpec>[] = [
    {
      title: locale.palletType,
      render: (_, record) => record.pallet.name,
    },
    {
      title: locale.packageType,
      render: (_, record) => record.package.description,
    },
    { title: locale.packagePerLayer, render: (_, record) => record.itemsPerLayer },
    { title: locale.layerPerPallet, render: (_, record) => record.layersPerPallet },
    { title: locale.layerPerTrailer, render: (_, record) => record.layersPerTrailer },
  ];

  return (
    <div>
      <Typography.Title level={2}>{locale.title}</Typography.Title>
      <Card>
        <Table columns={columns} dataSource={data} loading={isLoading} rowKey={(x) => x.id} />
      </Card>
    </div>
  );
};

export default ConstraintPage;
