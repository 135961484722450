import { enUsLocale } from './en_US';
import { zhCnLocale } from './zh_CN';
import { thLocale } from './th';
import { Locale, LocaleLanguage } from './types';
import { useState, useEffect } from 'react';
// import { store } from '../store';
import { useRecoilValue } from 'recoil';
import { currentLocaleState } from '../pages/account/atoms';
import { getLocalLanguage } from '../utils/storage';

type LocaleComponent = keyof Locale;

export function getLocaleData<T>(
  selector: LocaleComponent | Function | null = null,
  lang?: LocaleLanguage
): T {
  if (!lang) {
    lang = getLocalLanguage() ? getLocalLanguage() : LocaleLanguage.EN_US;
  }
  const rootLocale: Locale = {
    [LocaleLanguage.EN_US]: enUsLocale,
    [LocaleLanguage.th]: thLocale,
    [LocaleLanguage.ZH_CN]: zhCnLocale,
  }[lang];

  // Seek for a particular locale component
  if (selector instanceof Function) {
    return selector(rootLocale);
  }

  if (selector === null) {
    return rootLocale as any;
  }
  return rootLocale[selector] as any;
}

function useLocaleData<T>(selector: LocaleComponent | Function | null = null): T {
  const currentLocale = useRecoilValue(currentLocaleState);
  const [localeData, setLocaleData] = useState<T>(getLocaleData(selector, currentLocale));

  useEffect(() => {
    setLocaleData(getLocaleData(selector, currentLocale));
  }, [currentLocale]);

  return localeData;
}

export { enUsLocale, zhCnLocale, thLocale, useLocaleData };
