import React, { useEffect, useState } from 'react';
import { Input, Button, Divider, Form, DatePicker } from 'antd';
import { MaterialType } from '../../../masterDataManagement/material/types';
import Select, { SelectValue } from 'antd/lib/select';
import { DomesticOrderSpecs } from '../../types';
import { Locale, GlobalLocale, TaskDomesticCargoLocale } from '../../../../locale/types';
import { useLocaleData } from '../../../../locale';
import moment from 'moment';
import { useQuery } from 'react-query';
import { listMaterials } from '../../../../utils/api';
import { stripId } from '../../../../utils/utils';

interface OrderFormProps {
  postCancel: () => void;
  postSubmit: () => void;
  onSubmit: (orderData: DomesticOrderSpecs[]) => void;
  orderData: DomesticOrderSpecs[];
  onEditGoods?: DomesticOrderSpecs;
}
const DomesticGoodsForm = (props: OrderFormProps): JSX.Element => {
  const [form] = Form.useForm();
  const globalLocale = useLocaleData<GlobalLocale>('global');
  const locale = useLocaleData<TaskDomesticCargoLocale>(
    (locale: Locale) => locale.taskDomesticCargo
  );

  const { data: materialList } = useQuery('materials', listMaterials);
  const [currentMaterial, setCurrentMaterial] = useState<MaterialType | undefined>(undefined);

  useEffect(() => {
    props.onEditGoods && setCurrentMaterial(props.onEditGoods.material);
  }, [props.onEditGoods]);

  const handleSubmit = (values) => {
    const updatedOrderData = {
      ...values,
      deliveryDate: values.deliveryDate.format('YYYY-MM-DD'),
      material: currentMaterial,
    };

    if (props.onEditGoods) {
      const dataIndex = props.orderData.findIndex((goods) => goods.id === props.onEditGoods.id);
      const payload = [...props.orderData];
      payload.splice(dataIndex, 1, updatedOrderData);
      props.onSubmit(payload.map(stripId));
    } else {
      props.onSubmit([...props.orderData.map(stripId), updatedOrderData]);
    }
    props.postSubmit();
  };

  const coordinateMaterial = (material: MaterialType | undefined): void => {
    if (!material) return;
    form.setFieldsValue({
      materialCode: material?.materialNumber,
      materialName: material?.description,
      packageType: material?.package?.description,
    });

    setCurrentMaterial(material);
  };

  const handleMaterialCodeChange = (value: SelectValue): void => {
    const selectedMaterial = materialList.find((material) => material.id === value);
    coordinateMaterial(selectedMaterial);
  };

  const handleMaterialDescriptionChange = (value: SelectValue): void => {
    const selectedMaterial = materialList.find((material) => material.id === value);
    coordinateMaterial(selectedMaterial);
  };

  const goodsFormOptions = {
    customerCode: {
      rules: [{ required: true, message: locale.customerCode.required }],
      initialValue: props.onEditGoods ? props.onEditGoods.customerCode : '',
    },
    customerName: {
      rules: [{ max: 50, message: locale.errorMessage.tooLong50 }],
      initialValue: props.onEditGoods ? props.onEditGoods.customerName : '',
    },
    postcode: {
      rules: [
        { required: true, message: locale.postcode.required },
        { max: 50, message: locale.errorMessage.tooLong50 },
      ],
      initialValue: props.onEditGoods ? props.onEditGoods.postcode : '',
    },
    doNumber: {
      rules: [
        { required: true, message: locale.doNumber.required },
        { max: 50, message: locale.errorMessage.tooLong50 },
      ],
      initialValue: props.onEditGoods ? props.onEditGoods.doNumber : '',
    },
    deliveryDate: {
      rules: [{ required: true, message: locale.deliveryDate.required }],
      initialValue: props.onEditGoods ? moment(props.onEditGoods.deliveryDate, 'YYYY-MM-DD') : '',
    },
    materialCode: {
      rules: [
        { required: true, message: locale.materialCode.required },
        { enum: materialList.map((x) => x.materialNumber), message: 'Material Number Not Found' },
      ],
      initialValue: props.onEditGoods ? props.onEditGoods.materialCode : '',
    },
    materialName: {
      rules: [
        { enum: materialList.map((x) => x.description), message: 'Material Description Not Found' },
      ],
      initialValue: props.onEditGoods ? props.onEditGoods.materialName : '',
    },
    packageType: {
      rules: [{ required: true, message: locale.packageType.required }],
      initialValue: props.onEditGoods ? props.onEditGoods.material.package?.description : '',
    },
    orderQuantity: {
      rules: [
        { required: true, message: locale.orderQuantity.required },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (currentMaterial) {
              if (!Number.isInteger(value / currentMaterial.weight)) {
                return Promise.reject(new Error(locale.errorMessage.notDivisible));
              }
            }
            return Promise.resolve();
          },
        }),
      ],
      initialValue: props.onEditGoods ? props.onEditGoods.orderQuantity : null,
    },
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Form.Item
        name="customerCode"
        {...goodsFormOptions.customerCode}
        label={locale.customerCode.label + ':'}
      >
        <Input autoFocus data-test-id="customerCode" />
      </Form.Item>
      <Form.Item
        name="customerName"
        {...goodsFormOptions.customerName}
        label={locale.customerName.label + ':'}
      >
        <Input data-test-id="customerName" />
      </Form.Item>
      <Form.Item
        name="materialCode"
        {...goodsFormOptions.materialCode}
        label={locale.materialCode.label}
      >
        {
          <Select
            showSearch
            filterOption={(inputValue, option): boolean =>
              option ? option.children.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0 : false
            }
            onChange={handleMaterialCodeChange}
            disabled={props.onEditGoods !== undefined}
            data-test-id="selectMaterialCode"
          >
            {materialList.map((x) => (
              <Select.Option key={x.id} value={x.id} data-test-id={x.id}>
                {x.materialNumber}
              </Select.Option>
            ))}
          </Select>
        }
      </Form.Item>
      <Form.Item
        name="materialName"
        {...goodsFormOptions.materialName}
        label={locale.materialName.label}
      >
        <Select
          showSearch
          filterOption={(inputValue, option): boolean =>
            option ? option.children.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0 : false
          }
          onChange={handleMaterialDescriptionChange}
          disabled={props.onEditGoods !== undefined}
          data-test-id="selectMaterialName"
        >
          {materialList.map((x) => (
            <Select.Option key={x.id} value={x.id} data-test-id={x.id}>
              {x.description}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="packageType"
        {...goodsFormOptions.packageType}
        label={locale.packageType.label + ':'}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="orderQuantity"
        {...goodsFormOptions.orderQuantity}
        label={locale.orderQuantity.label + ':'}
      >
        <Input type="number" min={1} data-test-id="orderQuantity" />
      </Form.Item>
      <Form.Item name="postcode" {...goodsFormOptions.postcode} label={locale.postcode.label + ':'}>
        <Input data-test-id="postcode" />
      </Form.Item>
      <Form.Item name="doNumber" {...goodsFormOptions.doNumber} label={locale.doNumber.label + ':'}>
        <Input data-test-id="doNumber" />
      </Form.Item>
      <Form.Item
        name="deliveryDate"
        {...goodsFormOptions.deliveryDate}
        label={locale.deliveryDate.label + ':'}
      >
        <DatePicker data-test-id="deliveryDate" />
      </Form.Item>

      <Divider />
      <div style={{ textAlign: 'right' }}>
        <Button onClick={props.postCancel} data-test-id="cancel">
          {globalLocale.cancel}
        </Button>
        <Button htmlType="submit" type="primary" style={{ marginLeft: '8px' }}>
          {globalLocale.save}
        </Button>
      </div>
    </Form>
  );
};

export default DomesticGoodsForm;
