import React, { useEffect } from 'react';

import { Form, Input, Select, Divider, Button } from 'antd';

import { Rule } from 'antd/lib/form';
import { CreateTaskLocale, Locale } from '../../../locale/types';
import { useLocaleData } from '../../../locale';
import { NewTaskType, PalletBaseSpec } from '../types';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { listPalletBases, postTask } from '../../../utils/api';

const CreateTaskForm = () => {
  const [form] = Form.useForm();
  const locale = useLocaleData<CreateTaskLocale>((locale: Locale) => locale.createTask);
  const history = useHistory();
  const { data: palletBaseTypeList, isLoading: isFetchingPalletBaseTypeList } = useQuery<
    PalletBaseSpec[]
  >('palletBaseList', listPalletBases);

  const formOptions = {
    name: {
      rules: [
        { required: true, type: 'string', message: locale.name.required },
        { type: 'string', max: 50, message: locale.nameTooLong },
      ] as Rule[],
      normalize: (value) => value.trim(),
    },
    orderType: {
      rules: [{ required: true, type: 'string', message: locale.orderType.required }] as Rule[],
      normalize: (value) => value.trim(),
      initialValue: 'export',
    },
    palletType: {
      rules: [{ required: true, type: 'string', message: locale.palletType.required }] as Rule[],
      normalize: (value) => value.trim(),
    },
  };

  const queryClient = useQueryClient();

  const postTaskMutation = useMutation(postTask, {
    onSuccess: (data) => {
      queryClient.setQueryData(['task', data.id], data);
      history.push(`/task/${data.id}/orderData/`);
    },
  });

  const handleSubmit = (values) => {
    const newTask: NewTaskType = {
      name: values.name,
      subtasks: [],
      goods: [],
      label: 'draft',
      detail: {
        orderType: values.orderType,
        pallet: values.palletType,
      },
    };

    postTaskMutation.mutate(newTask);
  };

  useEffect(() => {
    !!palletBaseTypeList && form.setFieldsValue({ palletType: palletBaseTypeList[0].id });
  }, [palletBaseTypeList]);

  const renderPalletSelectOptions = (type: PalletBaseSpec) => (
    <Select.Option key={type.id} value={type.id} data-test-id={type.name}>
      {type.name}
    </Select.Option>
  );
  return (
    <Form onFinish={handleSubmit} form={form} layout="vertical">
      <Form.Item
        name="name"
        label={locale.name.label}
        {...formOptions.name}
        style={{ textAlign: 'center' }}
      >
        <Input data-test-id="name" />
      </Form.Item>
      <Form.Item name="orderType" {...formOptions.orderType} label={locale.orderType.label}>
        <Select loading={isFetchingPalletBaseTypeList} data-test-id="selectChannel">
          <Select.Option key="export" value="export" data-test-id="export">
            {locale.export}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="palletType" {...formOptions.palletType} label={locale.palletType.label}>
        <Select loading={isFetchingPalletBaseTypeList} data-test-id="selectPallet">
          {palletBaseTypeList?.map(renderPalletSelectOptions)}
        </Select>
      </Form.Item>

      <Divider />

      <div className="form-action-button-group" style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginLeft: '8px' }}
          data-test-id="createTask"
          loading={postTaskMutation.isLoading}
        >
          {locale.create}
        </Button>
      </div>
    </Form>
  );
};

export default CreateTaskForm;
