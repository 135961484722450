import React from 'react';
import { Select } from 'antd';
import { LocaleLanguage } from '../../locale/types';
import './index.css';

const { Option } = Select;

type Props = {
  onSelectLocale: (value: LocaleLanguage) => void;
  value: LocaleLanguage;
};

const LocaleSelect = (props: Props): JSX.Element => (
  <Select
    value={props.value}
    onSelect={props.onSelectLocale}
    style={{ width: '100px' }}
    data-test-id="selectLanguage"
  >
    <Option value={LocaleLanguage.EN_US} data-test-id="enUS">
      English
    </Option>
    <Option value={LocaleLanguage.th} data-test-id="th">
      ไทย
    </Option>
    <Option value={LocaleLanguage.ZH_CN} data-test-id="zhCN">
      简体中文
    </Option>
  </Select>
);

export { LocaleSelect };
