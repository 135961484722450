import React, { useState, useEffect } from 'react';
import './index.css';
import { Row, Typography, Col, Card, Button, Input, Table, message } from 'antd';
import { useLocaleData } from '../../locale';
import { ContactLocale, Locale } from '../../locale/types';
import { ContactType, ContactBookType } from './types';
import { EditOutlined } from '@ant-design/icons';
import DeleteConfirm from '../../components/deleteConfirm';
import ContactForm from './contactForm';
import FormModal from '../../components/formModal';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { listContactBooks, deleteContact } from '../../utils/api';

type ContactTableFormat = {
  key: string;
  index: number;
  bookId: string;
  email: string;
  firstName: string;
};

function ContactBook() {
  const [onEditContact, setOnEditContact] = useState<ContactType | undefined>(undefined);
  const [cargoFormModalVisible, setContactFormVisible] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const locale = useLocaleData<ContactLocale>((locale: Locale) => locale.contactBook);
  const queryClient = useQueryClient();

  const { data: contactBookList, isLoading } = useQuery('contactBookList', listContactBooks);

  const useTableDataSource = (contactBookList: ContactBookType[]): ContactTableFormat[] => {
    const [dataSource, setDataSource] = useState<ContactTableFormat[]>([]);

    useEffect(() => {
      if (contactBookList) {
        const parsed = contactBookList.flatMap((contactBook: ContactBookType) =>
          contactBook.contacts.map((contact: ContactType, index: number) => ({
            key: contact.id,
            index: index + 1,
            firstName: contact.firstName,
            email: contact.email,
            bookId: contact.bookId,
          }))
        );
        setDataSource(parsed);
      } else {
        setDataSource([]);
      }
    }, [contactBookList]);

    return dataSource;
  };
  const tableDataSource = useTableDataSource(contactBookList);

  // event handling
  const openModal = (): void => setContactFormVisible(true);
  const closeModal = (): void => setContactFormVisible(false);

  const addNewContact = (): void => {
    setOnEditContact(undefined);
    openModal();
  };

  const deleteContactMutation = useMutation(deleteContact, {
    onSuccess: () => {
      message.success(locale.message.emailDeleted);
      queryClient.invalidateQueries('contactBookList');
    },
  });

  const editContact =
    (contact: ContactTableFormat) =>
    (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      e && e.preventDefault();
      setOnEditContact(
        contactBookList
          .flatMap((contactBook) => contactBook.contacts)
          .find((x) => x.id === contact.key)
      );
      openModal();
    };

  const handleDelete =
    (contact: ContactTableFormat) =>
    (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined): void => {
      e && e.preventDefault();
      deleteContactMutation.mutate({ contactId: contact.key, bookId: contact.bookId });
    };

  const renderActions = (contact: ContactTableFormat): JSX.Element => (
    <div>
      <EditOutlined
        onClick={editContact(contact)}
        style={{ marginRight: '20px' }}
        data-test-id="edit"
      />
      <DeleteConfirm onConfirm={handleDelete(contact)} />
    </div>
  );

  // Data table column definition
  const tableColDef = [
    { title: locale.table.index, dataIndex: 'index', key: 'index' },
    { title: locale.table.firstName, dataIndex: 'firstName', key: 'firstName' },
    { title: locale.table.email, dataIndex: 'email', key: 'email' },
    {
      title: locale.table.action.name,
      key: 'action',
      render: renderActions,
    },
  ];

  const contactFormModalSettings = {
    openModal,
    closeModal,
    title: onEditContact ? locale.edit : locale.add,
    visible: cargoFormModalVisible,
    component: ContactForm,
    componentProps: { onEditContact },
  };

  return (
    <div>
      <Row>
        <Typography.Title level={2}>{locale.title}</Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            title={
              <h3>
                <span>
                  <Button onClick={addNewContact} type="link" data-test-id="addEmail">
                    {locale.add}
                  </Button>
                </span>

                <span style={{ float: 'right', width: '350px' }}>
                  <Input.Search
                    placeholder={locale.searchPlaceHolder}
                    onChange={(e) => setSearchFilter(e.target.value)}
                    allowClear
                  />
                </span>
              </h3>
            }
          >
            <Table
              columns={tableColDef}
              dataSource={tableDataSource.filter((x) => x.email.includes(searchFilter))}
              loading={isLoading}
            />
          </Card>
        </Col>
      </Row>
      <FormModal {...contactFormModalSettings} />
    </div>
  );
}

export default ContactBook;
