import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useLocaleData } from '../../../../locale';
import { UsersLocale } from '../../../../locale/types';
import { useForgetPasswordHook } from '../../../../hooks/forgetPassword';

type Props = {
  email: string;
  disable?: boolean;
};

// Forget password start of Reset password process.
const ResetPasswordButton = (props: Props) => {
  const locale = useLocaleData<UsersLocale>('users');
  const { setForgetPassword, isLoading } = useForgetPasswordHook();

  const onClick = () => {
    setForgetPassword(props.email);
  };

  return (
    <Button
      type="link"
      loading={isLoading}
      onClick={onClick}
      disabled={props.disable}
      data-test-id="resetPassword"
    >
      {locale.resetPassword}
    </Button>
  );
};

export default ResetPasswordButton;
