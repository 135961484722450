import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { useLocaleData } from '../../../../locale';
import { TaskImportCargoLocale, Locale } from '../../../../locale/types';
import FileDownloader from '../../../../components/fileDownloader/fileDownloader';
import { useRecoilValue } from 'recoil';
import { OrderSpecs, TaskType } from '../../types';
import { currentTaskState } from '../../atoms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useErrorMessagesHandler } from '../../../../utils/errorMessageHandler';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { getTaskOrderData, listPackages, putOrderData, importOrders } from '../../../../utils/api';
import { PackageSpec } from '../../../masterDataManagement/material/types';

type Props = {
  disabled: boolean;
};

const ImportOrdersModal = (props: Props): JSX.Element => {
  const locale = useLocaleData<TaskImportCargoLocale>((locale: Locale) => locale.task.importCargo);
  const { taskId } = useParams<{ taskId: string }>();
  const reportError = useErrorMessagesHandler();

  const currentTask = useRecoilValue<TaskType>(currentTaskState);

  const [showModal, setShowModal] = useState(false);
  const openModal = (): void => setShowModal(true);
  const closeModal = (): void => setShowModal(false);

  const { data: orderData } = useQuery(['taskOrderData', taskId], getTaskOrderData(taskId), {
    initialData: [],
  });

  const { isLoading: isFetchingPackageList } = useQuery<PackageSpec[]>('packageList', listPackages);

  const queryClient = useQueryClient();
  const putTaskOrderData = useMutation(
    (orderData: OrderSpecs[]) => putOrderData(taskId, currentTask.detail.orderType, orderData),
    {
      onSettled: () => queryClient.invalidateQueries('taskOrderData'),
    }
  );

  const uploadMutation = useMutation(importOrders, {
    onSuccess: (result: OrderSpecs[]) => {
      notification.destroy();
      putTaskOrderData.mutate(
        orderData
          .map((order) => ({
            ...order,
            id: undefined,
          }))
          .concat(result)
      );
    },
    onError: (error: AxiosError) => {
      error.response && reportError(error.response.data);
    },
  });

  return (
    <div style={{ display: 'inline' }}>
      <Button
        disabled={props.disabled || isFetchingPackageList}
        type="link"
        onClick={openModal}
        data-test-id="importOrderData"
      >
        {locale.title}
      </Button>
      <Modal title={locale.title} visible={showModal} onCancel={closeModal} footer={null}>
        <Dragger
          multiple={false}
          accept=".xls,.xlsx"
          customRequest={(options) =>
            uploadMutation.mutate({ options, orderType: currentTask.detail.orderType })
          }
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{locale.draggerText}</p>
        </Dragger>

        <p style={{ marginTop: '20px' }}>
          {locale.downloadTemplate}
          <FileDownloader
            text={locale.here}
            url={`/download/order_template_${currentTask.detail.orderType}.xlsx`}
            fileName={`cargo_upload_template_${currentTask.detail.orderType}.xlsx`}
          />
        </p>

        <p></p>
      </Modal>
    </div>
  );
};

export default ImportOrdersModal;
