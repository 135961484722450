import * as React from 'react';
import { Modal } from 'antd';
import { PlanData } from '../../../../components/interactiveScene/types';
import ScenePreview from '../../../../components/interactiveScene/ScenePreview';
import { useLocaleData } from '../../../../locale';
import { TaskResultLocale } from '../../../../locale/types';

type Props = {
  onClose: () => void;
  planFile: PlanData[];
};

const SceneModal = (props: Props) => {
  const locale: TaskResultLocale = useLocaleData<TaskResultLocale>('taskResult');
  return (
    <Modal
      visible={props.planFile?.length !== 0}
      onCancel={props.onClose}
      title={locale.preview}
      footer={null}
      width={1230}
      maskClosable={false}
      destroyOnClose={true}
    >
      <ScenePreview planData={props.planFile} />
    </Modal>
  );
};

export default SceneModal;
