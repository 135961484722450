export const round = (value: number, digit?: number) => {
  const offset = Math.pow(10, digit ? digit : 0);
  return Math.round(value * offset) / offset;
};

export const toISODateTime = (value: Date) =>
  !!value
    ? `${value.getFullYear()}-${
        value.getMonth() + 1
      }-${value.getDate()} ${value.toLocaleTimeString()}`
    : '';

export const stripId = (data) => {
  const { id, ...rest } = data;
  return rest;
};

export const validateEmailFormat = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
