import { ErrorMessageLocale, LocaleLanguage } from '../locale/types';
import { notification } from 'antd';
import { useLocaleData } from '../locale';

export interface ErrorMessageType {
  errorType: string;
  errorCode: string;
  errorDetail?: any;
  data?: any;
}

const ellipsisString = (array: any[], locale: ErrorMessageLocale) => {
  if (!Array.isArray(array)) return '';
  if (array.length < 10) {
    return array.toString();
  } else {
    return `[${array.slice(0, 10).join(',')}... ${locale.and} ${array.length - 10} ${locale.more}]`;
  }
};

export const useErrorMessagesHandler = () => {
  const locale = useLocaleData<ErrorMessageLocale>('errorMessage');

  const reportError = (errorMessages: ErrorMessageType[]) => {
    Array.isArray(errorMessages) &&
      errorMessages.forEach((errorMessage: ErrorMessageType) => {
        switch (errorMessage.errorCode) {
          case 'data.001':
            notification.error({
              message: locale.unknownAPIError,
            });
            break;

          case 'data.002':
            notification.error({
              message: locale.notFound,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.notFound}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'data.003':
            notification.error({
              message: locale.notAllowed,
            });

            break;

          case 'data.004':
            notification.error({
              message: locale.missingColumn,
              description: errorMessage.data
                .map((x) => `${locale.missingColumn}: ${x.columns.join(',\n')}`)
                .join('\n'),
              duration: 0,
            });

            break;

          case 'data.005':
            notification.error({
              message: locale.notUnique,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.notUnique}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'data.006':
            notification.error({
              message: locale.notValidNumber,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.notValidNumber}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'data.007':
            notification.error({
              message: locale.notValidType,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.notValidType}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'data.008':
            notification.error({
              message: locale.tooSmall,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.tooSmall}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'data.009':
            notification.error({
              message: locale.tooLarge,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.tooLarge}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'data.010':
            notification.error({
              message: locale.notPositive,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.notPositive}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'data.011':
            notification.error({
              message: locale.notNegative,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.notNegative}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'data.012':
            notification.error({
              message: locale.outOfRange,
            });
            break;

          case 'data.013':
            notification.error({
              message: locale.notEndWith,
            });
            break;

          case 'data.014':
            notification.error({
              message: locale.invalidConfigValue,
            });
            break;

          case 'data.015':
            notification.error({
              message: locale.emptyValue,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.emptyValue}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'data.016':
            notification.error({
              message: locale.alreadyExists,
              description: errorMessage.data
                .map(
                  (x) =>
                    `${x.columns} ${locale.alreadyExists}, ${locale.rows} ${ellipsisString(
                      x.rows,
                      locale
                    )}`
                )
                .join('\n'),
              duration: 0,
            });
            break;

          case 'sys.001':
            notification.error({
              message: locale.systemError,
            });
            break;

          case 'db.001':
            notification.error({
              message: locale.notFound,
              description: errorMessage.data.map(
                (x) =>
                  `${x.columns} ${locale.notFound}, ${locale.rows} ${ellipsisString(
                    x.rows,
                    locale
                  )}`
              ),
              duration: 0,
            });
            break;

          case 'auth.001':
            notification.error({
              message: locale.valueNotAllowed,
            });
            break;

          case 'auth.002':
            notification.error({
              message: locale.crossDepartment,
            });
            break;

          case 'auth.003':
            notification.error({
              message: locale.notNewPassword,
            });
            break;

          case 'file.001':
            notification.error({
              message: locale.invalidFileExtension,
            });
            break;

          case 'file.002':
            notification.error({
              message: locale.fileNotReadable,
            });
            break;

          case 'file.003':
            notification.error({
              message: locale.fileEmpty,
            });
            break;

          case 'task.002':
            notification.error({
              message: locale.notDivisible,
              description: errorMessage.data
                .map((x) => `${locale.rows} ${ellipsisString(x.rows, locale)}`)
                .join('\n'),
              duration: 0,
            });
            break;

          default:
            notification.error({
              message: errorMessage.errorType,
              duration: 0,
            });
        }
      });
  };
  return reportError;
};
