import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Button } from 'antd';
import { useLocaleData } from '../../locale';
import { DeleteConfirmLocale } from '../../locale/types';

type Props = {
  onConfirm: (params: any) => any;
  content?: 'icon' | 'default';
  disabled?: boolean;
};

const DeleteConfirm = ({ onConfirm, content, disabled = false }: Props): JSX.Element => {
  const locale = useLocaleData<DeleteConfirmLocale>('deleteConfirm');
  return (
    <Popconfirm
      title={locale.title}
      okText={locale.okText}
      cancelText={locale.cancelText}
      onConfirm={onConfirm}
    >
      {content === 'default' ? (
        <Button disabled={disabled} danger type="default" data-test-id="delete">
          {locale.deleteText}
        </Button>
      ) : (
        <DeleteOutlined style={{ marginLeft: '10px' }} data-test-id="delete" />
      )}
    </Popconfirm>
  );
};

export default DeleteConfirm;
