import * as React from 'react';
import { Modal, Form, Select, Button, Input, message } from 'antd';
import { useQuery } from 'react-query';
import { getContactBooks, sendPlanToEmails } from '../../../../utils/api';
import { ContactBookType, ContactType } from '../../../contactBook/types';
import { useRecoilValue } from 'recoil';
import { currentSubtaskState, currentTaskState } from '../../atoms';
import { ResultRuleType } from '../../../../components/interactiveScene/types';
import { toISODateTime, validateEmailFormat } from '../../../../utils/utils';
import RuleDescription from '../components/ruleDescription';
import { TaskResultLocale } from '../../../../locale/types';
import { useLocaleData } from '../../../../locale';

type Props = {
  rule: ResultRuleType;
  onClose: () => void;
};

const SendEmailModal = (props: Props) => {
  const [form] = Form.useForm();
  const { data: contactBooks, isLoading: isFetchingContactBook } = useQuery<ContactBookType>(
    'contactBooks',
    getContactBooks
  );
  const currentTask = useRecoilValue(currentTaskState);
  const currentSubtask = useRecoilValue(currentSubtaskState);
  const locale: TaskResultLocale = useLocaleData<TaskResultLocale>('taskResult');

  const [isSendingEmail, setIsSendingEmail] = React.useState(false);

  const renderContacts = (x: ContactType) => (
    <Select.Option key={x.id} value={x.id}>
      {x.firstName}
    </Select.Option>
  );

  const getFromContactBook = (value: string) => {
    return contactBooks.contacts.find((x) => x.id === value);
  };

  const contactFilterOptions = (input: string, option) => {
    const currentOptionObject = getFromContactBook(option.value);
    return currentOptionObject?.firstName.toLocaleLowerCase().includes(input.toLowerCase());
  };

  const getEmailAddressList = (recipient: string[]) =>
    recipient.map((x) => {
      const contactResult = getFromContactBook(x);
      return {
        name: contactResult?.firstName ?? x,
        email: contactResult?.email ?? x,
      };
    });

  const onEmailSentSuccessful = () => {
    setIsSendingEmail(false);
    form.resetFields();
    props.onClose();
  };

  const onFinish = async (values) => {
    const { recipientList, extraMessage } = values;
    const emailAddressList = getEmailAddressList(recipientList);
    setIsSendingEmail(true);
    await sendPlanToEmails(
      emailAddressList,
      currentTask.id,
      currentSubtask.id,
      props.rule,
      `[ASP] Loading Plan for ${props.rule.value.week_number} Destination:${props.rule.value.destination}`,
      extraMessage
    )
      .then((res) => {
        message.success(`${emailAddressList.length} ${locale.emailSent} `);
        onEmailSentSuccessful();
      })
      .catch((err) => {
        message.error(locale.somethingWrong);
        setIsSendingEmail(false);
      });
  };

  const validateRecipientList = (_, valueList: string[]) => {
    for (const value of valueList) {
      const searchResult = contactBooks.contacts.find((x) => x.id === value);
      if (!searchResult && !validateEmailFormat(value)) return Promise.reject(locale.invalidEmail);
    }
    return Promise.resolve();
  };

  return (
    <Modal
      title={locale.sendEmail}
      visible={!!props.rule}
      maskClosable={false}
      onCancel={props.onClose}
      footer={null}
      width={600}
    >
      <Form.Item validateTrigger={['onSubmit']}>
        <h3>{locale.sendResult}:</h3>
        <p>
          {locale.task}: <strong>{currentTask?.name}</strong>
        </p>
        <p>
          {locale.result}: <strong>{toISODateTime(new Date(currentSubtask?.created))}</strong>
        </p>
        <RuleDescription rule={props.rule} />
      </Form.Item>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="recipientList"
          label={locale.recipient}
          rules={[
            { required: true, message: locale.recipientInputPlaceholder },
            { validator: validateRecipientList },
          ]}
        >
          <Select mode="tags" loading={isFetchingContactBook} filterOption={contactFilterOptions}>
            {contactBooks?.contacts.map(renderContacts)}
          </Select>
        </Form.Item>
        <Form.Item name="extraMessage" label={locale.extraMessage}>
          <Input.TextArea allowClear autoSize={{ minRows: 3, maxRows: 10 }} maxLength={500} />
        </Form.Item>
        <div style={{ textAlign: 'right' }}>
          <Button htmlType="submit" type="primary" loading={isSendingEmail}>
            {locale.send}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SendEmailModal;
