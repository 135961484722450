import React, { useState, useEffect } from 'react';

import { Row, Card, Table, Typography, Col, Button, message } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EditOutlined } from '@ant-design/icons';
import { useLocaleData } from '../../../locale';
import { ContainerListLocale, Locale } from '../../../locale/types';
import { deleteContainer, listContainers } from '../../../utils/api';
import HelpTextDecorator from '../../../components/helpTextDecor';
import DeleteConfirm from '../../../components/deleteConfirm';
import ContainerForm from './containerForm';
import FormModal from '../../../components/formModal';
import { ContainerSpec } from './types';

type TableFormat = {
  key: string;
  index: number;
  name: string;
  type: string;
  dimension: string;
  weight: string;
  capacity: string;
  maxPallet: string;
  refCost: number;
};

const ContainerManagementPage = (): JSX.Element => {
  // Hooks
  const locale = useLocaleData<ContainerListLocale>((locale: Locale) => locale.containerList);
  const { data: containerList, isLoading } = useQuery<ContainerSpec[]>(
    'containerList',
    listContainers()
  );
  const [containerFormModalVisible, setContainerFormVisible] = useState(false);
  const [onEditContainer, setOnEditContainer] = useState<ContainerSpec | undefined>(undefined);
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteContainer, {
    onSuccess: () => {
      message.success(locale.message.containerDeleted);
      queryClient.invalidateQueries('containerList');
    },
  });

  const useDataSource = (containerList: ContainerSpec[]): TableFormat[] => {
    const [dataSource, setDataSource] = useState<TableFormat[]>([]);
    useEffect(() => {
      if (containerList) {
        setDataSource(
          containerList.map((spec: ContainerSpec, index: number) => ({
            key: spec.id,
            index: index + 1,
            name: spec.name,
            type: spec.type === 'shipping' ? locale.shipping : locale.truck,
            dimension: `${spec.length} x ${spec.width} x ${spec.height} mm`,
            weight: `${spec.weight} kg`,
            capacity: `${spec.maxLoad} kg`,
            maxPallet: spec.maxPallet ? `${spec.maxPallet}` : '--',
            refCost: spec.refCost,
          }))
        );
      } else {
        setDataSource([]);
      }
    }, [containerList]);

    return dataSource;
  };

  // event handling
  const openModal = (): void => setContainerFormVisible(true);
  const closeModal = (): void => setContainerFormVisible(false);

  const editContainer = (containerId: string) => {
    setOnEditContainer(containerList.find((container) => container.id === containerId));
    openModal();
  };
  const tableDataSource = useDataSource(containerList);
  const renderActions = (container: TableFormat): JSX.Element => (
    <div>
      <EditOutlined
        onClick={() => editContainer(container.key)}
        style={{ marginRight: '20px' }}
        data-test-id="edit"
      />
      {containerList.length > 1 && (
        <DeleteConfirm onConfirm={() => deleteMutation.mutate(container.key)} />
      )}
    </div>
  );
  const tableColDef = [
    { title: locale.table.index, dataIndex: 'index', key: 'index' },
    { title: locale.table.name, dataIndex: 'name', key: 'name' },
    { title: locale.table.type, dataIndex: 'type', key: 'type' },
    { title: locale.table.dimension, dataIndex: 'dimension', key: 'dimension' },
    { title: locale.table.weight, dataIndex: 'weight', key: 'weight' },
    { title: locale.table.capacity, dataIndex: 'capacity', key: 'capacity' },
    { title: locale.table.maxPallet, dataIndex: 'maxPallet', key: 'maxPallet' },
    {
      title: (
        <HelpTextDecorator help={locale.table.cost.help} decoratee={locale.table.cost.label} />
      ),
      dataIndex: 'refCost',
      key: 'refCost',
    },
    {
      title: locale.table.action.name,
      key: 'action',
      render: renderActions,
    },
  ];

  const addNewContainer = (): void => {
    setOnEditContainer(undefined);
    openModal();
  };

  const containerFormModalSettings = {
    openModal,
    closeModal,
    title: onEditContainer ? locale.edit : locale.add,
    visible: containerFormModalVisible,
    component: ContainerForm,
    componentProps: { onEditContainer },
    style: {
      top: '20px',
    },
  };
  // View
  return (
    <div>
      <Row>
        <Typography.Title level={2}>{locale.title}</Typography.Title>
      </Row>

      <Row>
        <Col span={24}>
          <Card
            title={
              <h3>
                <span>
                  <Button onClick={addNewContainer} type="link" data-test-id="addContainer">
                    {locale.add}
                  </Button>
                </span>
              </h3>
            }
          >
            <Table columns={tableColDef} dataSource={tableDataSource} loading={isLoading} />
          </Card>
        </Col>
      </Row>

      <FormModal {...containerFormModalSettings} />
    </div>
  );
};
export default ContainerManagementPage;
