import axios, { AxiosInstance, AxiosError } from 'axios';

import { getToken, getDistrict, clearStorage } from './storage';
import { message } from 'antd';
import { getLocaleData } from '../locale';
import { GlobalLocale } from '../locale/types';
import history from './history';

const requestErrorHandler = (error: AxiosError) => {
  if (
    error.response.status === 401 &&
    error.response.data.title === 'token is invalid or expired'
  ) {
    message.destroy();
    message.warn(getLocaleData<GlobalLocale>('global').invalidTokenError);
    clearStorage();
    return history.push('/login');
  }
  if (error.response.status === 403) {
    return history.push('/');
  }
  return Promise.reject(error);
};

export const baseRequest = (): AxiosInstance => {
  return axios.create({
    baseURL: '/',
  });
};

export const authRequest = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: '/',
    headers: {
      Authorization: 'Bearer ' + getToken(),
      district: getDistrict(),
      ContentType: 'application/json',
    },
  });
  instance.interceptors.response.use((config) => config, requestErrorHandler);
  return instance;
};

export const authExcelRequest = (fileName: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: '/',
    headers: {
      Authorization: 'Bearer ' + getToken(),
      district: getDistrict(),
      'Content-Disposition': 'attachment;filename="' + fileName + '"',
    },
  });
  instance.interceptors.response.use((config) => config, requestErrorHandler);
  return instance;
};
