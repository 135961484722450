import { Descriptions, Skeleton } from 'antd';
import * as React from 'react';
import { ResultRuleType } from '../../../../components/interactiveScene/types';
import { useLocaleData } from '../../../../locale';
import { TaskResultLocale } from '../../../../locale/types';
type Props = {
  rule: ResultRuleType;
};
const RuleDescription = (props: Props) => {
  const locale: TaskResultLocale = useLocaleData<TaskResultLocale>('taskResult');

  return props.rule ? (
    <Descriptions>
      <Descriptions.Item label={locale.weekNo}>
        <strong>{props.rule.value.week_number}</strong>
      </Descriptions.Item>
      <Descriptions.Item label={locale.customerCode}>
        <strong>{props.rule.value.customer_code}</strong>
      </Descriptions.Item>
      <Descriptions.Item label={locale.destination}>
        <strong>{props.rule.value.destination}</strong>
      </Descriptions.Item>
    </Descriptions>
  ) : (
    <Skeleton active />
  );
};
export default RuleDescription;
