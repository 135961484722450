import React from 'react';
import { Menu } from 'antd';
import { PlanData } from './types';

type Props = {
  containerList: PlanData[];
  selectedKeys: string[];
  onSelectContainer: (index: number) => void;
};
const ContainerSelectionMenu = (props: Props): JSX.Element => {
  const onSelect = (e) => {
    props.onSelectContainer(parseInt(e.key));
  };

  return (
    <Menu selectedKeys={props.selectedKeys} onClick={onSelect} theme="dark">
      {props.containerList?.map((x, index) => (
        <Menu.Item key={index}>{(index + 1).toFixed() + ': ' + x.trailer_type}</Menu.Item>
      ))}
    </Menu>
  );
};
export default ContainerSelectionMenu;
