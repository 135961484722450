import React from 'react';
import { Button } from 'antd';
import downloadFile from './downloadUtils';

type Props = {
  url: string;
  text: string | undefined;
  fileName: string;
  disabled?: boolean;
};

const FileDownloader = (props: Props): JSX.Element => {
  const [progress, setProgress] = React.useState(0);
  const handleDownload = (e): void => {
    downloadFile(props.url, props.fileName, setProgress);
  };

  const isLoading = progress > 0;

  return (
    <Button
      loading={isLoading}
      onClick={handleDownload}
      type="link"
      disabled={props.disabled || !props.url}
    >
      {isLoading ? `${progress}%` : props.text}
    </Button>
  );
};

export default FileDownloader;
