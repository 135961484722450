import React, { Fragment, useState } from 'react';
import { Button, Modal, Form, Input, Divider, message } from 'antd';
import { useLocaleData } from '../../../locale';
import { ChangePasswordModalLocale, Locale, UsersLocale } from '../../../locale/types';
import { useMutation } from 'react-query';
import { updatePassword } from '../../../utils/api';
import { AxiosError } from 'axios';
import { clearStorage } from '../../../utils/storage';
import { useResetRecoilState } from 'recoil';
import { accountInfo } from '../atoms';
import { useHistory } from 'react-router';
import { validatePasswordStrength } from '../accountUtil';

type Props = {
  userId: string;
};

const ChangePasswordModal = (props: Props): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false);
  const resetUserAccountInfo = useResetRecoilState(accountInfo);
  const history = useHistory();
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const locale = useLocaleData<ChangePasswordModalLocale>(
    (locale: Locale) => locale.account.accountSecurity.changePassword
  );
  const userLocale = useLocaleData<UsersLocale>('users');
  const [form] = Form.useForm();
  const updatePasswordMutation = useMutation(updatePassword, {
    onError: (e: AxiosError) => {
      switch (e.response.status) {
        case 400:
          if (
            Array.isArray(e.response.data.data) &&
            e.response.data.data[0]['type'] === '/api/problems/auth.010'
          ) {
            message.error(userLocale.message.invalidCurrentPassword);
          }
          break;
        case 401:
          message.error(userLocale.message.invalidCurrentPassword);
          break;
        default:
          message.error(userLocale.message.serverErrorUpdateFailed);
      }
    },
    onSuccess: () => {
      clearStorage();
      resetUserAccountInfo();
      history.push('/login');
    },
  });

  const confirmPasswordValidator = (rule, value) => {
    if (!value || form.getFieldValue('newPassword') === value) return Promise.resolve();
    else return Promise.reject(new Error(locale.message.notMatchingNewPassword));
  };

  const newPasswordValidator = (rule, value) => {
    if (!validatePasswordStrength(value))
      return Promise.reject(new Error(locale.message.passwordNotStrongEnough));

    if (form.getFieldValue('currentPassword') === value)
      return Promise.reject(new Error(locale.message.sameAsCurrentPassword));
    return Promise.resolve();
  };

  const formOptions = {
    currentPassword: {
      rules: [{ required: true, message: locale.message.currentPasswordEmpty }],
      normalize: (value) => value.trim(),
    },
    newPassword: {
      rules: [
        { required: true, min: 8, message: locale.message.lengthNotEnough },
        { validator: newPasswordValidator },
      ],
      normalize: (value) => value.trim(),
    },
    confirmPassword: {
      rules: [
        { required: true, message: locale.message.confirmPasswordEmpty },
        { validator: confirmPasswordValidator },
      ],
      normalize: (value) => value.trim(),
    },
  };

  const onCancel = () => {
    closeModal();
    form.resetFields();
  };

  const onFinish = (values) => {
    const { newPassword, currentPassword } = values;
    updatePasswordMutation.mutate({
      user: props.userId,
      password: currentPassword,
      newPassword,
    });
  };

  return (
    <Fragment>
      <Button type="link" onClick={openModal} style={{ padding: ' 0 10px', height: 0 }}>
        {locale.title}
      </Button>
      <Modal
        visible={modalVisible}
        title={locale.title}
        maskClosable={false}
        onCancel={onCancel}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="currentPassword"
            label={locale.currentPassword}
            {...formOptions.currentPassword}
          >
            <Input.Password data-test-id="currentPassword" />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label={locale.newPassword}
            hasFeedback
            {...formOptions.newPassword}
          >
            <Input.Password data-test-id="newPassword" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label={locale.confirmPassword}
            dependencies={['newPassword']}
            hasFeedback
            {...formOptions.confirmPassword}
          >
            <Input.Password data-test-id="confirmPassword" />
          </Form.Item>

          <Divider />

          <div style={{ textAlign: 'right' }}>
            <Button onClick={onCancel} data-test-id="cancel">
              {locale.cancel}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginLeft: '8px' }}
              loading={updatePasswordMutation.isLoading}
            >
              {locale.change}
            </Button>
          </div>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default ChangePasswordModal;
