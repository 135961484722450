import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/en-au';
import 'moment/locale/th';

import { getTaskLogs, deleteTaskComment } from '../../../../../utils/api';
import { List, Divider, Card, Space, Tag, Skeleton } from 'antd';
import { useLocaleData } from '../../../../../locale';
import { TaskLogLocale, Locale } from '../../../../../locale/types';
import AddComment from './addComment';
import DeleteConfirm from '../../../../../components/deleteConfirm';
import { useRecoilValue } from 'recoil';
import { accountInfo } from '../../../../account/atoms';

interface TaskLog {
  activityDetail: string;
  activityType: 'rerun' | 'run' | 'email' | 'comment' | 'upload' | 'create' | 'update-old-data';
  created: Date;
  id: string;
  operator: string;
}

function TaskLog() {
  const { taskId } = useParams<{ taskId: string }>();
  const userAccountInfo = useRecoilValue(accountInfo);
  const { data: logs, isLoading: isFetchingComments } = useQuery<TaskLog[]>(
    ['taskLog', taskId],
    getTaskLogs(taskId)
  );
  const locale = useLocaleData<TaskLogLocale>((locale: Locale) => locale.taskLog);

  const [logFilterState, setLogFilter] = React.useState({ system: true, comment: true });

  const queryClient = useQueryClient();
  const mutation = useMutation(deleteTaskComment, {
    onSuccess: () => {
      queryClient.invalidateQueries('taskComments');
    },
  });

  const useDataSource = (logs: TaskLog[]): TaskLog[] => {
    const [dataSource, setDataSource] = useState([]);
    const systemLevelTypeList = logFilterState.system
      ? ['create', 'upload', 'run', 'rerun', 'email', 'update-order-data']
      : [];
    const userLevelTypeList = logFilterState.comment ? ['comment'] : [];
    useEffect(() => {
      logs
        ? setDataSource(
            logs.filter((log) =>
              [...systemLevelTypeList, ...userLevelTypeList].includes(log.activityType)
            )
          )
        : setDataSource([]);
    }, [logs, logFilterState]);

    return dataSource;
  };

  const listDataSource = useDataSource(logs);

  const isRemovable = (operator: string, activityType: string): boolean => {
    const matches = /\(([^)]+)\)/.exec(operator);
    if (matches[1] === userAccountInfo?.email && activityType === 'comment') return true;
    return false;
  };

  const logFilter = () => (
    <Space>
      <Tag.CheckableTag
        checked={logFilterState.system}
        onChange={() => setLogFilter((prevState) => ({ ...prevState, system: !prevState.system }))}
        data-test-id="system"
      >
        {locale.system}
      </Tag.CheckableTag>
      <Tag.CheckableTag
        checked={logFilterState.comment}
        onChange={() =>
          setLogFilter((prevState) => ({ ...prevState, comment: !prevState.comment }))
        }
        data-test-id="feedback"
      >
        {locale.feedback}
      </Tag.CheckableTag>
    </Space>
  );

  return (
    <Card title={locale.taskLogTitle} extra={logFilter()}>
      {isFetchingComments ? (
        <Skeleton active />
      ) : (
        <List
          itemLayout="horizontal"
          style={{ maxHeight: 150, overflow: 'auto' }}
          dataSource={listDataSource}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                description={
                  <div>
                    {item.operator}{' '}
                    <span>
                      {moment(item.created)
                        ?.locale(userAccountInfo?.lang ?? 'en_US')
                        ?.fromNow()}
                    </span>
                    {isRemovable(item.operator, item.activityType) && (
                      <DeleteConfirm
                        onConfirm={() => mutation.mutate({ taskId, logId: item.id })}
                      />
                    )}
                  </div>
                }
                title={
                  <div style={{ wordWrap: 'break-word' }}>
                    {locale.activity[item.activityType]}
                    {item.activityDetail ? `: ${item.activityDetail}` : ''}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      )}
      <Divider />
      <AddComment />
    </Card>
  );
}

export default TaskLog;
