import React, { useState } from 'react';
import { Tag, message, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserGroupTableSpecs, UserRoleType } from '../../types';
import { useLocaleData } from '../../../../locale';
import { UsersLocale } from '../../../../locale/types';
import { useRecoilValue } from 'recoil';
import { accountInfo } from '../../../account/atoms';
import { useMutation, useQueryClient } from 'react-query';
import { updateUser } from '../../../../utils/api';

type Props = {
  user: UserGroupTableSpecs;
  userRoles?: UserRoleType[];
};

const RoleLabel = (props: Props): JSX.Element => {
  const [userRole, changeUserRole] = useState<string | undefined>();
  const queryClient = useQueryClient();
  const userAccountInfo = useRecoilValue(accountInfo);
  const locale = useLocaleData<UsersLocale>('users');

  const updateUserRoleMutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('userRoles');
      changeUserRole(undefined);
    },
  });

  const onClick = (e) => {
    if (userAccountInfo.id === props.user.id) {
      message.warning(locale.message.changingOwnRole);
    } else {
      changeUserRole(props.user.displayTitle);
    }
  };

  const handleSelect = (value: string): void => {
    if (value !== props.user.roles[0]) {
      updateUserRoleMutation.mutate({
        id: props.user.id,
        roles: [value],
      });
    } else {
      changeUserRole(undefined);
    }
  };

  if (userRole) {
    return (
      <Select onSelect={handleSelect} value={userRole} size="small" style={{ width: '150px' }}>
        {props.userRoles.map((userRole: UserRoleType) => (
          <Select.Option key={userRole.id} value={userRole.id} data-test-id={userRole.displayTitle}>
            {userRole.displayTitle}
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (updateUserRoleMutation.isLoading) return <LoadingOutlined />;

  return (
    <Tag onClick={onClick} color="#108ee9" style={{ cursor: 'pointer' }} data-test-id="role">
      {props.user.displayTitle}
    </Tag>
  );
};

export default RoleLabel;
