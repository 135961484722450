import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Typography, Input, Button, message } from 'antd';
import { useLocaleData } from '../../../locale';
import { AccountLocale, UsersLocale } from '../../../locale/types';
import { LocaleSelect } from '../../../components/LocaleSelect';
import { clearStorage } from '../../../utils/storage';
import './resetPasswordPage.css';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { currentLocaleState } from '../atoms';
import { useMutation } from 'react-query';
import { resetPassword } from '../../../utils/api';
import { AxiosError } from 'axios';
import { validatePasswordStrength } from '../accountUtil';

const ResetPasswordPage = (): JSX.Element => {
  clearStorage();
  const uesQuery = () => new URLSearchParams(useLocation().search);
  const token = uesQuery().get('token');
  const setCurrentLocale = useSetRecoilState(currentLocaleState);

  const [form] = Form.useForm();

  const locale = useLocaleData<AccountLocale>('account');
  const userLocale = useLocaleData<UsersLocale>('users');
  const lang = useRecoilValue(currentLocaleState);

  const history = useHistory();

  const resetPasswordMutation = useMutation(resetPassword, {
    onSuccess: () => {
      history?.push('/login');
    },
    onError: (e: AxiosError) => {
      switch (e.response.status) {
        case 400:
          e.response.data && message.error(e.response.data);
          break;
        case 401:
          message.error(userLocale.message.resetLinkExpired);
          break;
        default:
          message.error(userLocale.message.serverErrorResetFailed);
      }
    },
  });

  const newPasswordValidator = (rule, value) => {
    if (!validatePasswordStrength(value))
      return Promise.reject(new Error(locale.message.passwordNotStrongEnough));

    return Promise.resolve();
  };

  const confirmPasswordValidator = (rule, value) => {
    if (!value || form.getFieldValue('newPassword') === value) return Promise.resolve();
    else
      return Promise.reject(locale.accountSecurity.changePassword.message.notMatchingNewPassword);
  };

  const formOptions = {
    newPassword: {
      rules: [
        {
          required: true,
          min: 8,
          message: locale.accountSecurity.changePassword.message.lengthNotEnough,
        },
        {
          validator: newPasswordValidator,
        },
      ],
      normalize: (value) => value.trim(),
    },
    confirmPassword: {
      rules: [
        {
          required: true,
          message: locale.accountSecurity.changePassword.message.confirmPasswordEmpty,
        },
        { validator: confirmPasswordValidator },
      ],
      normalize: (value) => value.trim(),
    },
  };

  const onFinish = (values) => {
    const { newPassword } = values;
    resetPasswordMutation.mutate({ newPassword, token });
  };

  return (
    <div className="reset-password-page" style={{ height: window.innerHeight }}>
      <div style={{ float: 'right', padding: '10px' }}>
        <LocaleSelect value={lang} onSelectLocale={setCurrentLocale} />
      </div>
      <Form form={form} onFinish={onFinish} className="reset-password-form">
        <Typography.Title level={2} className="reset-password-form-title">
          {locale.resetPassword}
        </Typography.Title>

        <Form.Item
          name="newPassword"
          className="reset-password-form-input"
          hasFeedback
          {...formOptions.newPassword}
        >
          <Input.Password
            placeholder={locale.accountSecurity.changePassword.newPassword}
            data-test-id="newPassword"
          />
        </Form.Item>

        <Form.Item
          name="newPasswordConfirm"
          className="reset-password-form-input"
          hasFeedback
          {...formOptions.confirmPassword}
        >
          <Input.Password
            placeholder={locale.accountSecurity.changePassword.confirmPassword}
            data-test-id="newPasswordConfirm"
          />
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={resetPasswordMutation.isLoading}>
          {locale.resetPassword}
        </Button>
      </Form>
    </div>
  );
};

export default ResetPasswordPage;
