import { Locale, HelpTextLocale } from './types';

const thHelp: HelpTextLocale = {
  customerId: 'หมายเลขรหัสสำหรับยืนยันรายการของลูกค้า',
  destinationPort: 'ชื่อท่าเรือของพื้นที่เป้าหมายของรายการนี้',
  orderNum: 'หมายเลขรหัสสำหรับยืนยันรายการนี้',
  subtaskName: 'ชื่อของแบบแผน ใช้สำหรับยืนยันแบบแผนนี้',
  containerList:
    'รายการตู้รถที่สามารถใช้ได้(สามารถเลือกได้หลายแบบ) เมื่อสร้างแบบแผนบรรจุแล้ว อัลกอริทึมจะทำการเลือกเฉพาะประเภทตู้ในรายการเพื่อบรรจุ',
  palletType: 'จำนวนบรรจุรายการสินค้าของประเภทถาดพลาสติก',
  minCostPlanner:
    'แบบแผนทุนต่ำ:แบบแผนนี้ใช้หลักการที่ว่าด้วยต้นทุนต่ำโดยทำการเลือกกลุ่มตู้รถที่มีอยู่ อาทิเช่น สินค้าในใบสั่งนี้สามารถบรรจุได้เต็มเต็มที่เมื่อรวมตู้เป็น [2x20GP] และ [1x40HQ] แบบแผนนั้นจะแนะนำเลือกกลุ่มรวมของ [1x40HQ] โดยมีต้นทุนโดยประมาณที่ตำที่สุด',
  plannerType:
    'ประเภทแบบแผนบรรจุที่เลือกใช้ การเลือกใช้แบบแผนที่ไม่เหมือนกันจะสร้างแบบแผนบรรจุที่ไม่แตกต่างกัน',
  totalGrossWeight:
    'น้ำหนักโดยรวมของสินค้าปัจจุบันรวมกับประเภทของสินค้า รวมถึงน้ำหนักสินค้าไปจนถึงน้ำหนักของประเภทห่อบรรจุสินค้าด้วย',
  totalGrossWeightExtraHint:
    'ข้อควรระวัง:หากมีน้ำหนักรวมที่คำนวณออกมาไม่สอดคล้องกับน้ำหนักรวมจริง กรุณาแก้ไขให้ถูกต้องด้วยตนเอง',
};

export const thLocale: Locale = {
  locale: 'th',
  // ********************* GLOBAL *********************
  global: {
    save: 'บันทึก',
    saved: 'บันทึกแล้ว',
    cancel: 'ยกเลิก',
    saas: 'เป็นคนแรก',
    next: 'ขั้นต่อไป',
    back: 'ขั้นก่อนหน้า',
    edit: 'แก้ไข',
    yes: 'ยืนยัน',
    no: 'ยกเลิก',
    error: 'ผิดพลาด',
    add: 'เพิ่ม',
    submit: 'ส่ง',
    added: 'เพิ่มแล้ว',
    created: 'สร้างใหม่แล้ว',
    login: 'ล็อกอิน',
    logout: 'ล็อกเอาท์',
    deleted: 'ลบแล้ว',
    updated: 'อัปเดตแล้ว',
    submitted: 'ส่งแล้ว',
    stable: 'ทางการ',
    draft: 'แบบร่าง',
    serverError: {
      message: 'เซิร์ฟเวอร์เกิดข้อผิดพลาด',
      description: 'กรุณาลองใหม่ภายหลัง',
    },
    unknownError: 'ข้อผิดพลาดที่ไม่ทราบชื่อ',
    someThingWentWrong: 'กระบวนการผิดปกติ',
    goBackToHome: 'กลับไปหน้าแรก',
    emailSent: 'ส่งจดหมายแล้ว',
    invalidTokenError: 'เซสชั่นหมดอายุ',
    nothingHere: 'ไม่มีอะไรที่นี่',
  },
  utilization: {
    spaceUtilization: 'อัตราพื้นที่บรรจุ',
    loadUtilization: 'อัตราปริมาณบรรจุ',
  },
  // ********************* LOGIN PAGE *********************
  login: {
    email: 'อีเมล',
    password: 'รหัสผ่าน',
    notification: {
      AUTH_ERROR: {
        message: 'อีเมลหรือรหัสผ่านไร้ผล',
      },
    },
  },
  // ********************* SIDE NAV *********************
  nav: {
    main: {
      contactBook: 'ที่อยู่อีเมล',
      home: 'หน้าหลัก',
      newTask: 'ภารกิจใหม่',
      tasks: 'ภารกิจทั้งหมด',
      cargos: 'ประเภทสินค้า',
      containers: 'ประเภทตู้รถ',
      userManual: 'คู่มือผู้ใช้',
      account: 'บัญชี',
      users: 'การจัดการผู้ใช้',
      constraints: 'ข้อจำกัด',
      pallets: 'ประเภทพาเลท',
      masterData: 'ข้อมูลหลัก',
      task: 'งาน',
      none: 'ไม่มี',
    },
    task: {
      home: 'หน้าหลัก',
      inputData: 'ข้อมูลการสั่งซื้อ',
      // orderData: 'ข้อมูลรายการ',
      configTask: 'ตั้งค่าภารกิจ',
      newTask: 'งานใหม่',
      result: 'ผลลัพธ์',
    },
  },

  // ********************* Send Email Modal **************

  sendEmailModal: {
    help: 'ที่อยู่อีเมลบางส่วนไร้ผล',
    title: 'ส่งอีเมลแล้ว',
    hint: '*ใส่อีเมลต่อหนึ่งแถว',
    cancel: 'ยกเลิก',
    send: 'ส่ง',
    subject: 'หัวข้อหลัก',
    recipient: 'ผู้รับ',
    loadingPlan: 'แบบแผนการบรรจุ',
    errorMessage: {
      subjectEmpty: 'กรุณาใส่หัวข้อหลัก',
      recipientEmpty: 'กรุณาใส่ผู้รับอย่างน้อยหนึ่งคน',
    },
  },

  // ********************* Delete Confirm ****************
  deleteConfirm: {
    title: 'ยืนยันที่จะลบหรือไม่?',
    okText: 'ยืนยัน',
    cancelText: 'ยกเลิก',
    deleteText: 'ลบ',
  },
  // ********************* HOME PAGE *********************
  home: {
    title: 'หน้าหลัก',
    chinese: 'เวอร์ชั่นจีน',
    english: 'เวอร์ชั่นอังกฤษ',
    introduction: {
      title: 'ยินดีต้อนรับสู่แพลตฟอร์ม AI-CLP SaaS',
      content:
        'AI-CLP ได้ส่งมอบกลยุทธ์การจองตู้รถและวิธีแก้ปัญหาของแบบแผนการบรรจุที่สามารถแทนที่การดำเนินการด้วยตนเองได้ สิ่งนี้สามารถใช้ได้ในสถานการณ์การที่ซับซ้อนต่างๆ ตอบสนองต่อความต้องการในการบรรทุกที่แตกต่างกันไป เฉกเช่นปริมาณการบรรทุกสินค้าและการจับคู่ข้อกำหนดเฉพาะรวมถึงการจัดสรรสมดุลเป็นต้น และบรรลุอัตราการใช้การบรรจุที่สูงขึ้น',
    },
    tutorial: {
      title: 'คู่มือผู้ใช้',
      content: 'ยินดีต้อนรับสู่แพลตฟอร์ม AI-CLP SaaS! แตะเพื่อดาวน์โหลดคู่มือผู้ใช้:',
    },
    taskStepNew: {
      title: 'ภารกิจใหม่',
      content: "คลิ๊ก 'ภารกิจใหม่' ในหน้าเมนูด้านซ้ายเพื่อเริ่มการตั้งค่า",
    },
    taskStepOrderData: {
      title: 'กรอกข้อมูลรายการสั่งซื้อ',
      content: 'เลือกสินค้าจากประเภทรายการที่กำหนดล่วงหน้า',
    },
    taskStepConfigure: {
      title: 'ดำเนินการตั้งค่าภารกิจ',
      content: 'กำหนดแบบแผนเพื่อค้นหาวิธีการแก้ปัญหาที่ดีที่สุดในฉากนี้',
    },
  },
  // ********************* ACCOUNT PAGE *********************
  account: {
    title: 'ศูนย์บัญชี',
    accountInfo: {
      subtitle: 'ข้อมูลบัญชี',
      userName: 'ชื่อผู้ใช้',
      email: 'อีเมล',
      organization: 'องค์กร',
      department: 'หน่วยงาน',
      role: 'ตัวละคร',
      loginCode: 'รหัสยืนยัน',
    },
    languageSetting: 'Language Setting / ตั้งค่าภาษา',
    accountSecurity: {
      subtitle: 'ความปลอดภัยบัญชี',
      passwordLabel: 'รหัสผ่าน',
      changePassword: {
        title: 'แก้ไขรหัสผ่าน',
        change: 'แก้ไข',
        cancel: 'ยกเลิก',
        currentPassword: 'รหัสผ่านปัจจุบัน',
        newPassword: 'รหัสผ่านใหม่',
        confirmPassword: 'ยืนยันรหัสผ่านใหม่',
        message: {
          changed: 'แก้ไขสำเร็จ',
          currentPasswordEmpty: 'กรุณากรอกรหัสผ่านใหม่',
          lengthNotEnough: 'ความยาวอย่างน้อย8ตัว',
          confirmPasswordEmpty: 'กรุณายืนยันรหัสผ่านใหม่',
          notMatchingNewPassword: 'กรอกรหัสผ่านใหม่ทั้งสองครั้งไม่ตรงกัน',
          sameAsCurrentPassword: 'รหัสผ่านใหม่ไม่สามารถเหมือนกับรหัสผ่านปัจจุบันได้',
          passwordNotStrongEnough:
            'รหัสผ่านต้องใส่ตัวอักษรพิมพ์เล็ก [a-z] อย่างน้อยหนึ่งตัว ตัวอักษรตัวพิมพ์ใหญ่ [A-Z] หนึ่งตัว ตัวเลข [0-9] หนึ่งตัว และอักขระพิเศษหนึ่งตัว',
        },
      },
    },
    logout: 'ล็อกเอาท์',
    login: 'ล็อกอิน',
    getCode: 'รับรหัส',
    forgotPassword: 'ลืมรหัสผ่าน',
    resetPassword: 'รีเซ็ตรหัสผ่าน',
    sendResetEmail: 'ส่งอีเมลรีเซ็ต',
    backToLogin: 'กลับไปล็อกอิน',
    message: {
      emailEmpty: 'กรุณากรอกที่อยู่อีเมล',
      passwordEmpty: 'กรุณากรอกรหัสผ่าน',
      loginSuccessful: 'ล็อกอินสำเร็จ',
      invalidEmailOrPassword: 'ที่อยู่อีเมลหรือรหัสผ่านผิดพลาด',
      serverErrorFailed: 'เซิร์ฟเวอร์เกิดข้อผิดพลาด ล็อกอินล้มเหลว',
      unknownErrorFailed: 'ข้อผิดพลาดที่ไม่รู้จัก ล็อคอินล้มเหลว',
      logoutSuccessful: 'ล็อกเอาท์สำเร็จ',
      accessDenied: 'ปฏิเสธการเข้าถึง',
      loginCodeEmpty: 'กรุณาใส่รหัสยืนยัน',
      invalidVerificationCode: 'รหัสยืนยันไม่ถูกต้อง',
      tooManyRequestPrefix: 'คำขอมากเกินไป โปรดรอสักครู่ ',
      tooManyRequestSuffix: 'วินาทีแล้วลองอีกครั้ง',
      passwordNotStrongEnough:
        'รหัสผ่านต้องใส่ตัวอักษรพิมพ์เล็ก [a-z] อย่างน้อยหนึ่งตัว ตัวอักษรตัวพิมพ์ใหญ่ [A-Z] หนึ่งตัว ตัวเลข [0-9] หนึ่งตัว และอักขระพิเศษหนึ่งตัว',
    },
  },
  // ********************* USERS PAGE *********************
  users: {
    title: 'การจัดการบัญชี',
    userTable: {
      name: 'ชื่อสกุล',
      email: 'อีเมล',
      role: 'ตัวละคร',
      organization: 'องค์กร',
      department: 'หน่วยงาน',
      isActive: 'สถานะบัญชี',
      action: 'การกระทำ',
    },
    admin: 'แอดมิน',
    user: 'ผู้ใช้',
    isActive: 'ปกติ',
    locked: 'ล็อก',
    createUserModal: {
      title: 'สร้างผู้ใช้ใหม่',
      emailLabel: 'อีเมล',
      firstNameLabel: 'ชื่อ',
      lastNameLabel: 'นามสกุล',
      create: 'สร้าง',
      cancel: 'ยกเลิก',
      roleLabel: 'บทบาท',
      addUser: 'เพิ่มผู้ใช้',
      message: {
        emailEmpty: 'กรุณากรอกที่อยู่อีเมลที่มีผล',
        firstNameEmpty: 'กรุณากรอกชื่อ',
        lastNameEmpty: 'กรุณากรอกนามสกุล',
        roleEmpty: 'กรุณาเลือกบทบาท',
        cancel: 'ยกเลิก',
      },
    },
    resetPassword: 'รีเซ็ตรหัสผ่าน',
    message: {
      changingOwnActive: 'ไม่สามารถล็อกตนเองได้',
      changingOwnRole: 'ไม่สามารถแก้ไขตัวละครของตนได้',
      serverErrorCreatFailed: 'เซิร์ฟเวอร์เกิดข้อผิดพลาด การสร้างผู้ใช้ล้มเหลว',
      emailAlreadyRegistered: 'อีเมลนี้ได้ถูกลงทะเบียนไปแล้ว',
      newUserCreated: 'สร้างผู้ใช้ใหม่สำเร็จ',
      passwordChange: 'แก้ไขรหัสผ่านสำเร็จ กรุณาใช้รหัสผ่านใหม่ในการล็อกอินอีกครั้ง',
      invalidCurrentPassword: 'รหัสผ่านปัจจุบันเกิดข้อผิดพลาด',
      serverErrorUpdateFailed: 'เซิร์ฟเวอร์เกิดข้อผิดพลาด การแก้ไขรหัสผ่านล้มเหลว',
      resetEmailSentTo: 'อีเมลรีเซ็ตได้ถูกส่งไปยัง: ',
      resetExceedLimit: 'คำร้องรีเซ็ทรหัสผ่านได้เกินกว่าขีดจำกัด กรุณาลองใหม่ภายหลัง กรุณารอ:',
      minutes: 'นาที',
      secondes: 'วินาที',
      serverErrorResetFailed: 'เซิร์ฟเวอร์เกิดข้อผิดพลาด การรีเซ็ตรหัสผ่านล้มเหลว',
      resetSuccessful: 'รีเซ็ตรหัสผ่านสำเร็จ',
      resetLinkExpired: 'ลิงก์รีเซ็ตรหัสผ่านนี้หมดอายุแล้ว',
      userPasswordIncorrect: 'รหัสผ่านผู้ใช้ไม่ถูกต้อง รหัสผ่านผู้ใช้ไม่ถูกต้อง',
      userAdded: 'ผู้ใช้เพิ่ม',
    },
  },
  // ********************* Material LIST PAGE *********************
  cargoList: {
    title: 'ประเภทสินค้า',
    add: 'เพิ่มประเภทสินค้า',
    edit: 'แก้ไขประเภทสินค้า',
    searchPlaceHolder: 'ค้นหาหมายเลขวัตถุดิบและประเภทห่อบรรจุ',
    table: {
      index: 'หมายเลขซีเรียล',
      materialNum: 'หมายเลขวัสดุ',
      packageType: 'ประเภทห่อบรรจุ',
      weight: 'น้ำหนัก',
      description: 'คำอธิบาย',
      action: { name: 'ปฏิบัติ' },
    },
    notification: {
      'cargo material with this Unique material number already exists.': {
        message: 'หมายเลขวัสดุมีอยู่จริง',
      },
      PACKAGE_ERROR: {
        message: 'ประเภทห่อบรรจุไร้ผล',
        description: 'กรุณารีเฟรสใหม่แล้วลองอีกครั้ง',
      },
    },
  },
  // ********************* CARGO CREATE PAGE *********************
  cargoAdd: {
    title: 'เพิ่มประเภทสินค้า',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    materialNum: {
      label: 'หมายเลขวัสดุ',
      required: 'กรุณากรอกหมายเลขวัสดุของประเภทสินค้านี้',
    },
    package: {
      label: 'ประเภทห่อบรรจุ',
      required: 'กรุณาเลือกประเภทห่อบรรจุ',
    },
    packageWeight: {
      label: 'น้ำหนักห่อบรรจุ',
      required: 'กรุณากรอกน้ำหนักห่อบรรจุ',
    },
    netWeight: {
      label: 'น้ำหนักสุทธิ',
      required: 'กรุณากรอกน้ำหนักสุทธิของประเภทสินค้านี้',
    },
    weight: {
      label: 'น้ำหนัก',
      required: 'กรุณากรอกน้ำหนักของประเภทสินค้านี้',
    },
    materialDescription: {
      label: 'คำอธิบาย',
      required: 'กรุณากรอกคำอธิบายของประเภทสินค้านี้',
    },
    repeatedMaterialNumber: 'มีหมายเลขวัสดุอยู่แล้ว',
  },

  // ********************* CARGO IMPORT PAGE *********************
  cargoImport: {
    title: 'นำเข้า',
    dropZoneText: 'คลิ๊กหรือลากไฟล์นี้ไปยังพื้นที่อัปโหลด',
    closeText: 'ปิด',
    downloadTemplate: 'ดาวน์โหลดแม่แบบไฟล์:',
    here: 'ดาวน์โหลด',
  },
  // ********************* CONTAINER LIST PAGE *********************
  containerList: {
    title: 'ประเภทตู้รถ',
    add: 'เพิ่มคอนเทนเนอร์',
    edit: 'แก้ไขคอนเทนเนอร์',
    shipping: 'คอนเทนเนอร์',
    truck: 'รถบรรทุก',
    table: {
      index: 'หมายเลขซีเรียล',
      name: 'ชื่อ',
      type: 'พิมพ์',
      maxPallet: 'แม็กซ์พาเลท',
      dimension: 'ขนาด',
      weight: 'ความหนัก',
      capacity: 'ความจุของที่บรรจุ',
      cost: {
        label: 'ค่าใช้จ่ายอ้างอิง',
        help: 'ตัวอย่างค่าใช้จ่ายแต่ละตู้รถคร่าวๆ',
      },
      action: { name: 'ปฏิบัติ' },
    },
    message: {
      containerDeleted: 'คอนเทนเนอร์ถูกลบ',
    },
  },
  // ********************* CONSTRAINT PAGE *********************
  constraint: {
    title: 'ข้อจำกัด',
    palletType: 'ประเภทพาเลท',
    packageType: 'ประเภทแพ็คเกจ',
    packagePerLayer: 'แพ็คเกจต่อชั้น',
    layerPerPallet: 'เลเยอร์ในพาเลท',
    layerPerTrailer: 'เลเยอร์ในตัวอย่าง',
  },
  // ********************* TASK LIST PAGE *********************
  taskList: {
    title: 'ภารกิจทั้งหมด',
    table: {
      index: 'หมายเลขซีเรียล',
      name: 'ชื่อภารกิจ',
      label: 'ฉลาก',
      owner: 'ผู้ครอบครอง',
      created: 'วันที่สร้าง',
      completed: 'สำเร็จ',
      action: {
        name: 'ปฏิบัติ',
        rowItems: {
          detail: 'ตรวจดู',
        },
      },
    },
  },
  taskDetail: {
    title: 'รายละเอียดภารกิจ',
    downloadInput: 'ดาวน์โหลดใบรายการ',
    table: {
      favorite: 'เลือกแบบแผน',
      name: 'ชื่อแบบแผน',
      plannerType: 'ประเภทแบบแผน',
      containerList: 'ตู้รถที่เลือกไว้',
      status: 'สถานะ',
      numContainers: 'ตู้รถที่ใช้',
      action: {
        name: 'ปฏิบัติ',
        rowItems: {
          inspect: 'ดูตัวอย่าง',
          download: 'ดาวน์โหลดPDF',
        },
      },
    },
  },
  // ********************* TASK PAGE *********************
  task: {
    taskType: 'ประเภทงาน',
    // orderDataTitle: 'ข้อมูลคำสั่งซื้อ',
    inputDataTitle: 'ข้อมูลการสั่งซื้อ ข้อมูลการสั่งซื้อ',
    configureTaskTitle: 'ตั้งค่าภารกิจ',
    add: 'เพิ่มสินค้า',
    edit: 'หมายเลขสินค้า',
    fill: 'กรอก',
    submitted: 'ได้ส่งภารกิจแผนการบรรจุแล้ว',
    lineNumber: 'เลขแถว',
    label: 'ฉลาก',
    domestic: 'ภายในประเทศ',
    export: 'ส่งออก',
    newTask: 'งานใหม่',
    createNewTask: 'สร้างงานใหม่',
    configuration: 'การกำหนดค่า',
    generatePlan: 'สร้างแผน',
    selectTruckContainer: 'เลือกรถบรรทุก/ตู้คอนเทนเนอร์',
    emptyContainers: 'โปรดเลือกคอนเทนเนอร์ที่จะโหลด',
    emptyGoods: 'กรุณาเพิ่มข้อมูลการสั่งซื้อ',
    batchDelete: 'ลบจำนวนมาก',
    selectAll: 'เลือกทั้งหมด',
    unSelectAll: 'ไม่เลือกทั้งหมด',
    selectCurrentPage: 'เลือกหน้าปัจจุบัน',
    unSelectCurrentPage: 'ยกเลิกการเลือกหน้าปัจจุบัน',
    inverseSelect: 'เลือกผกผัน',
    cancel: 'ยกเลิก',
    delete: 'ลบ',
    quickSelection: 'เลือกด่วน',
    errorMessages: {
      tooMuchGoodsWarning: 'ปริมาณหรือคุณภาพสินค้าค่อนข้างใหญ่ ระยะเวลาในการคำนวณอาจค่อนข้างนาน',
      tooMuchGoodsError: 'ปริมาณหรือคุณภาพสินค้าค่อนข้างใหญ่ กรุณาลดสินค้าแล้วลองใหม่อีกครั้ง',
      noGoodsError: 'ไม่ได้เพิ่มสินค้าใดๆ',
      noSubtaskError: 'ไม่ได้ตั้งค่าแบบแผนใดๆ',
      zeroAttributeRowError: 'ตรวจพบความผิดปกติของปริมาณสินค้าหรือน้ำหนักรวมในหมายเลขซีเรียลแถวนี้',
      abnormalDensityWarning:
        'ตรวจพบเจอน้ำหนักรวม(ขึ้นอยู่กับปริมาณสินค้าในปัจจุบัน)ในหมายเลขซีเรียลแถวนี้อาจผิดปกติ',
      orderNumberEmptyMessage: 'กรุณากรอกหมายเลขคำสั่งซื้อหรือเนื้อหาใดๆ เพื่อสะดวกต่อการตรวจสอบ',
    },
    taskInfo: {
      title: 'ข้อมูลงาน',
      customerId: {
        label: 'หมายเลขลูกค้า',
        help: thHelp.customerId,
      },
      destinationPort: {
        label: 'พื้นที่เป้าหมายท่าเรือ',
        help: thHelp.destinationPort,
      },
      orderNum: {
        label: 'หมายเลขคำสั่งซื้อ',
        help: thHelp.orderNum,
      },
      palletType: {
        label: 'ประเภทถาดพลาสติก',
        help: thHelp.palletType,
      },
      taskType: {
        label: 'ประเภทงาน',
      },
    },
    domesticCargoList: {
      title: 'ประเภทสินค้า',
      add: 'เพิ่มประเภทสินค้า',
      table: {
        index: 'หมายเลขซีเรียล',
        customerCode: 'รหัสลูกค้า',
        customerName: 'ชื่อลูกค้า',
        postcode: 'รหัสไปรษณีย์',
        doNumber: 'DO ตัวเลข',
        deliveryDate: 'วันที่จัดส่ง',
        materialCode: 'หมายเลขวัสดุ',
        materialName: 'ชื่อวัสดุ',
        orderQuantity: 'ปริมาณการสั่งซื้อ',
        actions: 'ปฏิบัติ',
      },
    },
    exportCargoList: {
      title: 'ประเภทสินค้า',
      add: 'เพิ่มประเภทสินค้า',
      table: {
        index: 'หมายเลขซีเรียล',
        customerCode: 'รหัสลูกค้า',
        customerName: 'ชื่อลูกค้า',
        materialCode: 'หมายเลขวัสดุ',
        materialName: 'ชื่อวัสดุ',
        orderQuantity: 'ปริมาณการสั่งซื้อ',
        actions: 'ปฏิบัติ',
        soNumber: 'SO ตัวเลข',
        retaDate: 'RETA วันที่',
        destination: 'ปลายทาง',
      },
    },
    selectCargo: {
      title: 'เพิ่มสินค้า',
      materialNum: {
        label: 'หมายเลขวัสดุ',
        required: 'กรุณาเลือกหมายเลขวัสดุเพื่อระบุประเภทของสินค้า',
      },
      materialDescription: {
        label: 'คำอธิบาย',
        required: 'กรุณาเลือกคำอธิบายเพื่อระบุประเภทของสินค้า',
      },
      package: {
        label: 'ประเภทห่อบรรจุ',
        required: 'กรุณาเลือกประเภทห่อบรรจุ',
      },
      quantity: {
        label: 'จำนวน',
        required: 'กรุณากรอกจำนวนของสินค้าเพื่อระบุประเภทสินค้า',
      },
      totalWeight: {
        label: 'น้ำหนักรวม',
        required: 'กรุณากรอกน้ำหนักรวมของสินค้าเพื่อระบบประเภทสินค้า',
        help: thHelp.totalGrossWeight + '\n' + thHelp.totalGrossWeightExtraHint,
      },
    },
    importCargo: {
      title: 'นำเข้าจากไฟล์',
      draggerText: 'คลิ๊กหรือลากไฟล์เข้าสู่พื้นที่อัปโหลด',
      downloadTemplate: 'คลิ๊กเพื่อดาวน์โหลดไฟล์แม่แบบ:',
      here: 'ดาวน์โหลด',
    },
    configure: {
      title: 'รายการแบบแผน',
      addSubTask: 'เพิ่มแบบแผ่นประสิทธิภาพ',
      deleteWarning: 'ต้องการลบแบบแผนนี้หรือไม่?',
      table: {
        index: 'หมายเลขซีเรียล',
        name: 'ชื่อ',
        plannerType: {
          label: 'ประเภทแบบแผน',
          help: thHelp.plannerType,
        },
        containerList: {
          label: 'รายการตู้รถ',
          help: thHelp.containerList,
        },
        maxTime: 'เวลาโดยประมาณ',
        maxTimeUnit: 'นาที',
        moreThan: 'มากกว่า',
        lessThan: 'น้อยกว่า',
        action: 'ปฏิบัติ',
      },
    },
    addSubtask: {
      title: 'เพิ่มแบบแผน',
      addContainer: 'เพิ่มตู้รถ',
      add: 'เพิ่ม',
      cancel: 'ยกเลิก',
      numberLabel: 'ตัวเลข: ',
      name: {
        label: 'ชื่อ',
        default: 'ชื่อตั้งต้น',
        help: thHelp.subtaskName,
      },
      maxTime: {
        label: 'เวลาโดยประมาณ(นาที)',
      },
      plannerType: {
        label: 'ประเภทแบบแผน',
        required: 'กรุณาเลือกประเภทแบบแผน',
        help: thHelp.plannerType,
        selections: [
          ,
          {
            label: 'ต้นทุนขั้นต่ำ',
            help: thHelp.minCostPlanner,
          },
        ],
      },
      containerList: {
        label: 'รายการตู้รถ',
        required: 'กรุณาเลือกประเภทตู้รถอย่างน้อยหนึ่งอย่าง',
        help: thHelp.containerList,
      },
    },
    review: {
      title: 'ตรวจสอบแล้วส่งมอบ',
      taskName: 'ชื่อภารกิจ',
      numCargos: 'จำนวนสินค้า',
      taskType: 'ประเภทงาน',
      numSubtasks: 'จำนวนแบบแผน',
      orderNum: 'หมายเลขคำสั่งซื้อ',
      customerId: 'หมายเลขลูกค้า',
      destinationPort: 'พื้นที่เป้าหมายท่าเรือ',
      palletType: 'ประเภทถาดพลาสติก',
      label: 'ฉลาก',
    },
    notification: {
      emptyFile: {
        message: 'ไฟล์ว่างเปล่า',
        description: 'กรุณาตรวจสอบไฟล์แล้วลองใหม่อีกครั้ง',
      },
      fileTypeError: {
        message: 'ไฟล์เกิดข้อผิดพลาด',
        description: 'รูปแบบไฟล์ที่ไม่ได้รับการสนับสนุน กรุณาดาวน์โหลดใช้แม่แบบไฟล์',
      },
    },
  },

  errorMessage: {
    and: 'และ',
    more: 'อื่นๆ',
    rows: 'เลขแถว:',
    column: 'แถวตั้ง',
    notFound: 'หาข้อมูลที่เกี่ยวข้องไม่พบ',
    notAllowed: 'การปฏิบัติถูกระงับ',
    alreadyExists: 'มีอยู่จริง',
    unknownAPIError: 'ตัวเชื่อมที่ไม่ทราบชื่อเกิดข้อผิดพลาด',
    notAuthorized: 'สิทธิไม่เพียงพอ',
    samePassword: 'รหัสใหม่และเก่าไม่ควรตรงกัน',
    invalidFileExtension: 'ไม่รองรับนามสกุลไฟล์นี้',
    fileNotReadable: 'ไม่สามารถอ่านไฟล์นี้ได้',
    fileEmpty: 'ไฟล์ว่างเปล่า',
    missingColumn: 'แถวขาด',
    notUnique: 'ข้อมูลมีการซ้อนทับ',
    notCategorical: 'ประเภทข้อมูลที่ผิดพลาด',
    notValidNumber: 'ไม่ใช่ค่าตัวเลข',
    notValidType: 'ประเภทข้อมูลเกิดข้อผิดพลาด',
    tooSmall: 'จำนวนค่าน้อยเกินไป',
    tooLarge: 'จำนวนค่ามากเกินไป',
    notPositive: 'จำนวนค่าไม่ใช่เลขบวก',
    outOfRange: 'จำนวนค่าเกินขอบเขต',
    notNegative: 'จำนวนค่าไม่ใช่เลขลบ',
    notEndWith: 'หางเลขของค่าบวก',
    invalidConfigValue: 'การกำหนดที่ไร้ผล',
    emptyValue: 'ค่าเปล่า',
    systemError: 'ระบบเกิดข้อผิดพลาด',
    valueNotAllowed: 'ค่าที่ไร้ผล',
    crossDepartment: 'ระงับการข้ามหน่วยงาน',
    notNewPassword: 'รหัสผ่านใหม่และเก่าตรงกัน',
    notDivisible: 'ปริมาณสินค้าทั้งหมดไม่สามารถหารด้วยน้ำหนักสุทธิของวัสดุได้ทั้งหมด',
    backToTaskList: 'กลับรายการงาน',
  },

  contactBook: {
    title: 'ที่อยู่อีเมล',
    add: 'เพิ่มอีเมล',
    edit: 'แก้ไขอีเมล',
    searchPlaceHolder: 'ค้นหา',
    table: {
      index: 'ดัชนี',
      email: 'อีเมล',
      bookName: 'กลุ่ม',
      firstName: 'ชื่อ',
      action: { name: 'ปฏิบัติ' },
    },
    message: {
      emailDeleted: 'อีเมลที่ถูกลบ',
    },
  },
  contactAdd: {
    title: 'เพิ่มอีเมล',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    firstName: {
      label: 'ชื่อ',
      required: 'กรุณาใส่ชื่อ',
    },
    firstNameTooLong: 'ชื่อยาวเกินไป',
    invalidEmail: 'กรุณาใส่อีเมลที่ถูกต้อง',
    email: {
      label: 'อีเมล',
      required: 'กรุณาใส่ที่อยู่อีเมล',
    },
    message: {
      emailAdded: 'เพิ่มอีเมลแล้ว',
      emailDeleted: 'อีเมลถูกลบ',
      emailUpdated: 'อีเมล',
      repeatedEmail: 'มีอีเมลอยู่แล้ว',
    },
  },
  createTask: {
    title: 'งานใหม่',
    create: 'สร้าง',
    cancel: 'ยกเลิก',
    nameTooLong: 'ชื่อยาวเกินไป',
    name: {
      label: 'ชื่องาน',
      required: 'กรุณาใส่ชื่องาน',
    },
    orderType: {
      label: 'ประเภทการสั่งซื้อ',
      required: 'กรุณาเลือกประเภทการสั่งซื้อ',
    },
    palletType: {
      label: 'ประเภทพาเลท',
      required: 'กรุณาเลือกพาเลท',
    },
    domestic: 'ภายในประเทศ',
    export: 'ส่งออก',
  },
  taskDomesticCargo: {
    title: 'เพิ่มสินค้าภายในประเทศ',
    customerCode: { label: 'รหัสลูกค้า', required: 'ต้องระบุรหัสลูกค้า' },
    customerName: { label: 'ชื่อลูกค้า' },
    postcode: { label: 'รหัสไปรษณีย์', required: 'ต้องระบุรหัสไปรษณีย์' },
    doNumber: { label: 'DO ตัวเลข', required: 'DO ต้องระบุหมายเลข' },
    deliveryDate: { label: 'วันที่จัดส่ง', required: 'ต้องระบุวันที่จัดส่ง' },
    materialCode: { label: 'รหัสวัสดุ', required: 'ต้องระบุรหัสวัสดุ' },
    materialName: { label: 'ชื่อวัสดุ', required: 'ต้องระบุชื่อวัสดุ' },
    orderQuantity: { label: 'ปริมาณการสั่งซื้อ', required: 'ต้องมีปริมาณการสั่งซื้อ' },
    packageType: { label: 'ประเภทแพ็คเกจ', required: 'ต้องระบุประเภทแพ็คเกจ' },
    errorMessage: {
      tooLong50: 'ข้อความยาวเกินไป',
      notDivisible: 'ปริมาณการสั่งซื้อจะต้องหารด้วยน้ำหนักของวัสดุ',
    },
  },
  taskExportCargo: {
    title: 'เพิ่มสินค้าภายในประเทศ',
    customerCode: { label: 'รหัสลูกค้า', required: 'ต้องระบุรหัสลูกค้า' },
    customerName: { label: 'ชื่อลูกค้า' },
    soNumber: { label: 'SO ตัวเลข', required: 'So ต้องระบุหมายเลข' },
    retaDate: { label: 'RETA วันที่', required: 'RETA ต้องระบุวันที่' },
    destination: { label: 'ปลายทาง', required: 'ต้องระบุปลายทาง' },
    materialCode: { label: 'รหัสวัสดุ', required: 'ต้องระบุรหัสวัสดุ' },
    materialName: { label: 'ชื่อวัสดุ', required: 'ต้องระบุชื่อวัสดุ' },
    orderQuantity: { label: 'ปริมาณการสั่งซื้อ', required: 'ต้องมีปริมาณการสั่งซื้อ' },
    packageType: { label: 'ประเภทแพ็คเกจ', required: 'ต้องระบุประเภทแพ็คเกจ' },
    errorMessage: {
      tooLong50: 'ข้อความยาวเกินไป',
      notDivisible: 'ปริมาณการสั่งซื้อจะต้องหารด้วยน้ำหนักของวัสดุ',
      notFound: 'ไม่พบ กรุณาลองใหม่อีกครั้ง',
    },
  },
  palletList: {
    title: 'ประเภทพาเลท',
    add: 'เพิ่มพาเลท',
    edit: 'แก้ไขพาเลท',
    table: {
      index: 'ดัชนี',
      name: 'ชื่อ',
      dimension: 'มิติ',
      weight: 'น้ำหนัก',
      action: { name: 'ปฏิบัติ' },
    },
  },
  palletAdd: {
    title: 'เพิ่มพาเลท',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    name: {
      label: 'ชื่อ',
      required: 'ต้องระบุชื่อพาเลท',
    },
    length: {
      label: 'ความยาว',
      required: 'ต้องระบุความยาวของพาเลท',
    },
    width: {
      label: 'ความกว้าง',
      required: 'ต้องการความกว้างของพาเลท',
    },
    height: {
      label: 'ส่วนสูง',
      required: 'ต้องใช้ความสูงของพาเลท',
    },
    weight: {
      label: 'น้ำหนัก',
      required: 'ต้องการน้ำหนักพาเลท',
    },
    nameTooLong: 'ชื่อพาเลทยาวเกินไป',
    repeatedName: 'มีชื่อพาเลทอยู่แล้ว',
  },
  containerAdd: {
    title: 'เพิ่มคอนเทนเนอร์',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    name: {
      label: 'ชื่อ',
      required: 'ต้องระบุชื่อคอนเทนเนอร์',
    },
    type: {
      label: 'พิมพ์',
      required: 'ต้องระบุชื่อคอนเทนเนอร์',
    },
    length: {
      label: 'ความยาว',
      required: 'ต้องระบุความยาวของคอนเทนเนอร์',
    },
    width: {
      label: 'ความกว้าง',
      required: 'ต้องระบุความกว้างของคอนเทนเนอร์',
    },
    height: {
      label: 'ส่วนสูง',
      required: 'ต้องระบุความสูงของคอนเทนเนอร์',
    },
    weight: {
      label: 'น้ำหนัก',
      required: 'ต้องการน้ำหนักคอนเทนเนอร์',
    },
    maxLoad: {
      label: 'ความจุน้ำหนักบรรทุก',
      required: 'ต้องการโหลดสูงสุดของคอนเทนเนอร์',
    },
    maxPallet: {
      label: 'แม็กซ์พาเลท',
      required: 'ต้องใช้พาเลทสูงสุดของคอนเทนเนอร์',
    },
    refCost: {
      label: 'ค่าอ้างอิง',
      required: 'ต้องมีต้นทุนอ้างอิงของคอนเทนเนอร์',
    },
    message: {
      containerAdded: 'เพิ่มคอนเทนเนอร์',
      containerUpdated: 'อัพเดทคอนเทนเนอร์',
    },
    nameTooLong: 'ชื่อคอนเทนเนอร์ยาวเกินไป',
    shipping: 'คอนเทนเนอร์',
    truck: 'รถบรรทุก',
    repeatedName: 'มีชื่อนี้แล้ว',
  },
  taskLog: {
    add: 'เพิ่ม',
    commentLabel: 'ทิ้งข้อความไว้',
    commentRequired: 'ความคิดเห็นว่างเปล่า',
    comment140: 'ความคิดเห็นคือ 140 ตัวอักษรด้านบน',
    feedback: 'ข้อเสนอแนะ',
    system: 'ระบบ',
    taskLogTitle: 'บันทึกงานและคำติชม',
    activity: {
      create: 'สร้างงาน',
      upload: 'อัพโหลดไฟล์',
      run: 'เรียกใช้งาน',
      rerun: 'รันงานใหม่',
      email: 'ส่งอีเมล',
      comment: 'ความคิดเห็น',
      'update-order-data': 'อัพเดทสินค้า',
    },
  },
  taskResult: {
    resultHistory: 'ประวัติผลลัพธ์',
    resultDetail: 'รายละเอียดผลลัพธ์',
    time: 'เวลา',
    error: 'ข้อผิดพลาด',
    status: 'สถานะ',
    weekNo: 'สัปดาห์ที่',
    customerCode: 'รหัสลูกค้า',
    destination: 'ปลายทาง',
    actions: 'การกระทำ',
    result: 'ผลลัพธ์',
    notEnoughContainers: 'มีตู้คอนเทนเนอร์ไม่เพียงพอที่จะโหลดวัสดุที่สั่งซื้อทั้งหมด',
    waiting: 'ซึ่งรอคอย',
    timeout: 'หมดเวลา',
    completed: 'สมบูรณ์',
    preview: 'ดูตัวอย่าง',
    email: 'ดอีเมล',
    sendEmail: 'ส่งอีเมล',
    suggestions: 'คำแนะนำ',
    code: 'รหัส',
    name: 'ชื่อ',
    quantity: 'ปริมาณ',
    forTheOrders: 'สำหรับการสั่งซื้อ:',
    addMoreMaterials:
      'เราสามารถเพิ่มวัสดุเพิ่มเติมเพื่อเติมเต็มพื้นที่ที่เหลือโดยรายการชุดค่าผสมด้านล่าง:เราสามารถเพิ่มวัสดุเพิ่มเติมเพื่อเติมพื้นที่ที่เหลือโดยรายการชุดค่าผสมด้านล่าง:',
    sendResult: 'ส่งอีเมลผลลัพธ์ของ:',
    task: 'งาน',
    send: 'ส่ง',
    recipient: 'ผู้รับ',
    recipientInputPlaceholder: 'กรุณาระบุผู้รับ',
    extraMessage: 'ข้อความพิเศษ',
    invalidEmail: 'ผู้รับมีที่อยู่อีเมลที่ไม่ถูกต้อง',
    somethingWrong: 'บางสิ่งผิดปกติ. โปรดตรวจสอบที่อยู่อีเมลและลองอีกครั้ง',
    emailSent: 'อีเมลที่ส่งแล้ว',
    canNotFindMaterial: 'ไม่พบวัสดุ: ',
    maximizeWeightUtilization: 'ใช้โหลดให้เกิดประโยชน์สูงสุด',
    minimizeTypesOfMaterial: 'ลดประเภทของวัสดุให้น้อยที่สุด',
    columnError:
      'ความกว้างของคอนเทนเนอร์น้อยเกินไปหรือความกว้างของพาเลทมากเกินไปไม่สามารถโหลดได้ ความกว้างของคอนเทนเนอร์ควรเป็น [1,2] เท่าของความกว้างของพาเลท',
    rowError: 'ความยาวคอนเทนเนอร์น้อยเกินไปหรือความยาวพาเลทมากเกินไป ไม่สามารถโหลดได้',
    overHeightPallet: 'พาเลทสินค้าบางพาเลทสูงเกินไป เกินความสูงของคอนเทนเนอร์ ไม่สามารถโหลดได้',
    overWeightPallet: 'พาเลทสินค้าบางรายการมีน้ำหนักมากเกินไป ไม่สามารถโหลดได้',
    summary: 'สรุป',
  },
};
