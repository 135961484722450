import { ColumnProps } from 'antd/lib/table';
import { TaskLocale } from '../../../locale/types';
import { OrderSpecs } from '../types';

// Data table column definition
export const domesticOrderDataColumns = (
  locale: TaskLocale,
  renderActions: (order: OrderSpecs) => JSX.Element
) => {
  const result: ColumnProps<OrderSpecs>[] = [
    {
      title: locale.domesticCargoList.table.customerCode,
      dataIndex: 'customerCode',
      key: 'customerCode',
    },
    {
      title: locale.domesticCargoList.table.customerName,
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: locale.domesticCargoList.table.materialCode,
      dataIndex: 'materialCode',
      key: 'materialCode',
    },
    {
      title: locale.exportCargoList.table.materialName,
      dataIndex: 'materialName',
      key: 'materialName',
    },
    {
      title: locale.domesticCargoList.table.orderQuantity,
      dataIndex: 'orderQuantity',
      key: 'orderQuantity',
    },
    {
      title: locale.domesticCargoList.table.postcode,
      dataIndex: 'postcode',
      key: 'postcode',
    },
    {
      title: locale.domesticCargoList.table.doNumber,
      dataIndex: 'doNumber',
      key: 'doNumber',
    },
    {
      title: locale.domesticCargoList.table.deliveryDate,
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
    },
  ];

  !!renderActions &&
    result.push({
      title: locale.domesticCargoList.table.actions,
      key: 'action',
      render: renderActions,
    });
  return result;
};

export const exportOrderDataColumns = (
  locale: TaskLocale,
  renderActions?: (order: OrderSpecs) => JSX.Element
) => {
  const result: ColumnProps<OrderSpecs>[] = [
    {
      title: locale.exportCargoList.table.customerCode,
      dataIndex: 'customerCode',
      key: 'customerCode',
    },
    {
      title: locale.exportCargoList.table.customerName,
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: locale.exportCargoList.table.soNumber,
      dataIndex: 'soNumber',
      key: 'soNumber',
    },
    {
      title: locale.exportCargoList.table.destination,
      dataIndex: 'destination',
      key: 'destination',
    },
    {
      title: locale.exportCargoList.table.retaDate,
      dataIndex: 'retaDate',
      key: 'retaDate',
    },
    {
      title: locale.exportCargoList.table.materialCode,
      dataIndex: 'materialCode',
      key: 'materialCode',
    },
    {
      title: locale.exportCargoList.table.materialName,
      dataIndex: 'materialName',
      key: 'materialName',
    },
    {
      title: locale.exportCargoList.table.orderQuantity,
      dataIndex: 'orderQuantity',
      render: (value) => `${value} kg`,
    },
  ];
  !!renderActions &&
    result.push({
      title: locale.exportCargoList.table.actions,
      key: 'action',
      render: renderActions,
    });
  return result;
};
