import React from 'react';
import { useLocaleData } from '../../locale';
import { UtilizationLocale, Locale } from '../../locale/types';

type Props = {
  container: object;
};
const UtilizationDisplay = (props: Props): JSX.Element => {
  const locale = useLocaleData<UtilizationLocale>((locale: Locale) => locale.utilization);
  const getSpaceUtilization = (container: any): number => {
    if (!container) return 0;
    const palletLength = container.pallet_width ? container.pallet_width : 1172;
    const containerVolume = container.trailer_dims.reduce((a: number, b: number) => a * b);
    const totalPalletVolume = container.actions
      .map((x) => x.pallet_height * palletLength * palletLength)
      .reduce((a, b) => a + b);
    return (totalPalletVolume / containerVolume) * 100;
  };

  const getLoadUtilization = (container: any): number => {
    if (!container) return 0;
    const totalWeight = container.actions.map((x) => x.pallet_weight).reduce((a, b) => a + b);
    return (totalWeight / container.trailer_load_bearing) * 100;
  };
  return (
    <div style={{ padding: '10px' }}>
      <h3>{`${locale.loadUtilization}:${getLoadUtilization(props.container).toFixed(2)}%  ${
        locale.spaceUtilization
      }:${getSpaceUtilization(props.container).toFixed(2)}%  `}</h3>
    </div>
  );
};
export default UtilizationDisplay;
