import React, { ReactNode } from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

type Props = {
  help: ReactNode | undefined;
  decoratee: ReactNode;
  placement?: TooltipPlacement;
};

const HelpTextDecorator = (props: Props): JSX.Element => (
  <Tooltip title={props.help} {...props.placement}>
    <span>
      {props.decoratee}
      <QuestionCircleFilled
        style={{ color: 'rgba(0,0,0, 0.65)', marginLeft: '7px', fontSize: '13px' }}
      />
    </span>
  </Tooltip>
);

export default HelpTextDecorator;
