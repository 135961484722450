import { Layout } from 'antd';
import * as React from 'react';
import ContainerSelectionMenu from './containerSelectionMenu';
import { sceneManager } from './sceneManager';
import { PlanData } from './types';
import UtilizationDisplay from './utilizationDisplay';

type Props = {
  planData: PlanData[];
  size?: { width: number; height: number };
};
const ScenePreview = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const onSelectContainer = (index: number) => {
    if (index === selectedIndex) return;
    setSelectedIndex(index);
    sceneManager.renderPlan(props.planData[index]);
  };

  React.useEffect(() => {
    const canvasWrapper = document.getElementById('canvas-wrapper');
    !!canvasWrapper && sceneManager.appendRenderers(canvasWrapper);
  }, []);

  React.useEffect(() => {
    if (props.size) {
      const { width, height } = props.size;
      sceneManager.camera.aspect = width / height;
      sceneManager.camera.updateProjectionMatrix();
      sceneManager.renderer.setSize(width, height);
    }
  }, [props.size]);

  React.useEffect(() => {
    props.planData.length > 0 && onSelectContainer(0);
  }, [props.planData]);

  return (
    <div className="interactive-scene">
      <Layout>
        <Layout.Sider>
          <ContainerSelectionMenu
            containerList={props.planData}
            selectedKeys={[selectedIndex.toFixed()]}
            onSelectContainer={onSelectContainer}
          />
        </Layout.Sider>
        <Layout.Content style={{ overflow: 'hidden' }}>
          <UtilizationDisplay container={props.planData[selectedIndex]} />
          <div id="canvas-wrapper" />
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default ScenePreview;
