/* eslint-disable react/display-name */
import React, { useState, useMemo } from 'react';

import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Button,
  Skeleton,
  PaginationProps,
  Dropdown,
  Menu,
} from 'antd';
import { OrderSpecs, TaskType } from '../types';
import { Link, useParams } from 'react-router-dom';
import { TaskLocale, GlobalLocale } from '../../../locale/types';
import { useLocaleData } from '../../../locale';
import FormModal from '../../../components/formModal';
import DomesticGoodsForm from './components/domesticGoodsForm';
import ExportOrderForm from './components/exportGoodsForm';
import ImportOrdersModal from './components/importOrdersModal';
import { useRecoilValue } from 'recoil';
import { currentTaskState } from '../atoms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { putOrderData, getTaskOrderData } from '../../../utils/api';
import { EditOutlined, DownOutlined } from '@ant-design/icons';
import DeleteConfirm from '../../../components/deleteConfirm';
import { stripId } from '../../../utils/utils';
import { TableRowSelection } from 'antd/lib/table/interface';
import OrderDataDownloader from '../../../components/fileDownloader/orderDataDownloader';
import { domesticOrderDataColumns, exportOrderDataColumns } from './columns';

enum SelectionChoice {
  selectAll = 'select all',
  unSelectAll = 'unSelect all',
  selectCurrentPage = 'select current page',
  unSelectCurrentPage = 'unSelect current page',
  inverseSelect = 'inverse select',
}

const TaskOrderDataPage = (): JSX.Element => {
  // Hooks
  const { taskId } = useParams<{ taskId: string }>();
  const locale: TaskLocale = useLocaleData<TaskLocale>('task');
  const globalLocale: GlobalLocale = useLocaleData<GlobalLocale>('global');
  const currentTask = useRecoilValue<TaskType>(currentTaskState);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageState, setPageState] = useState({ current: 1, pageSize: 10 });
  // order data
  const { data: orderData = [], isFetching: isFetchingOrderData } = useQuery<OrderSpecs[]>(
    ['taskOrderData', taskId],
    getTaskOrderData(taskId)
  );

  const queryClient = useQueryClient();
  const orderDataMutation = useMutation(
    (orderData: OrderSpecs[]) => putOrderData(taskId, currentTask.detail.orderType, orderData),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['taskOrderData', taskId]);
        setIsSelecting(false);
        setSelectedRowKeys([]);
      },
    }
  );

  const [orderModalVisible, setOrderModalVisible] = useState(false);
  const [onEditOrder, setOnEditOrder] = useState<OrderSpecs | undefined>(undefined);

  const addNewGoods = (): void => {
    setOrderModalVisible(true);
  };
  const startEditing = (key: string) => (e) => {
    e.preventDefault();
    setOnEditOrder(orderData.find((order) => order.id === key));
    setOrderModalVisible(true);
  };

  const deleteGoods = (keys: string[]) => (e) => {
    const updated = orderData.filter((x) => !keys.includes(x.id)).map(stripId);
    orderDataMutation.mutate(updated);
  };

  const renderActions = (order): JSX.Element => {
    return (
      <div>
        <EditOutlined onClick={startEditing(order.id)} data-test-id="edit" />
        <DeleteConfirm onConfirm={deleteGoods([order.id])} />
      </div>
    );
  };

  const openOrderFormModal = () => setOrderModalVisible(true);
  const closeOrderFormModal = () => {
    setOrderModalVisible(false);
    setOnEditOrder(undefined);
  };
  const goodsFormModalSettings = {
    visible: orderModalVisible,
    openModal: openOrderFormModal,
    closeModal: closeOrderFormModal,
    title: onEditOrder ? locale.edit : locale.add,
    component: currentTask?.detail.orderType === 'domestic' ? DomesticGoodsForm : ExportOrderForm,
    componentProps: { orderData, onEditGoods: onEditOrder, onSubmit: orderDataMutation.mutate },
    style: {
      top: '20px',
    },
  };

  const currentPageKeys = useMemo(
    () =>
      orderData
        .slice((pageState.current - 1) * pageState.pageSize, pageState.current * pageState.pageSize)
        .map((orders) => orders.id),
    [pageState, orderData]
  );

  const handleMenuClick = (e) => {
    const value = e.key as SelectionChoice;
    switch (value) {
      case SelectionChoice.selectAll:
        setSelectedRowKeys(orderData.map((goods) => goods.id));
        break;
      case SelectionChoice.selectCurrentPage:
        setSelectedRowKeys([...new Set(selectedRowKeys.concat(currentPageKeys))]);
        break;
      case SelectionChoice.unSelectAll:
        setSelectedRowKeys([]);
        break;
      case SelectionChoice.unSelectCurrentPage:
        setSelectedRowKeys(selectedRowKeys.filter((key) => !currentPageKeys.includes(key)));
        break;
      case SelectionChoice.inverseSelect:
        setSelectedRowKeys(
          orderData.filter((goods) => !selectedRowKeys.includes(goods.id)).map((goods) => goods.id)
        );
        break;
      default:
        break;
    }
  };

  const rowSelectionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        disabled={orderData.length === selectedRowKeys.length}
        key={SelectionChoice.selectAll}
        data-test-id="selectAll"
      >
        {locale.selectAll}
      </Menu.Item>
      <Menu.Item
        disabled={currentPageKeys.every((key) => selectedRowKeys.includes(key))}
        key={SelectionChoice.selectCurrentPage}
        data-test-id="selectCurrentPage"
      >
        {locale.selectCurrentPage}
      </Menu.Item>
      <Menu.Item
        disabled={selectedRowKeys.length === 0}
        key={SelectionChoice.unSelectAll}
        data-test-id="unSelectAll"
      >
        {locale.unSelectAll}
      </Menu.Item>
      <Menu.Item
        disabled={!currentPageKeys.some((key) => selectedRowKeys.includes(key))}
        key={SelectionChoice.unSelectCurrentPage}
        data-test-id="unSelectCurrentPage"
      >
        {locale.unSelectCurrentPage}
      </Menu.Item>
      <Menu.Item key={SelectionChoice.inverseSelect} data-test-id="inverseSelect">
        {locale.inverseSelect}
      </Menu.Item>
    </Menu>
  );

  const renderRowSelectionTitle = () => (
    <Dropdown overlay={rowSelectionMenu} trigger={['click']}>
      <Button type="link" data-test-id="quickSelection">
        {locale.quickSelection} <DownOutlined />
      </Button>
    </Dropdown>
  );

  const rowSelection: TableRowSelection<OrderSpecs> = {
    onSelect: (record: OrderSpecs, selected: boolean) => {
      if (selected) {
        setSelectedRowKeys([...selectedRowKeys, record.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter((key) => key !== record.id));
      }
    },
    hideSelectAll: true,
    selectedRowKeys,
    columnTitle: renderRowSelectionTitle,
  };

  const paginationSettings: PaginationProps = {
    pageSize: pageState.pageSize,
    current: pageState.current,
    onChange: (page: number, pageSize: number) => setPageState({ pageSize, current: page }),
  };

  const hideOrShowSelection = () => {
    if (isSelecting) {
      setSelectedRowKeys([]);
      setIsSelecting(false);
    } else {
      setIsSelecting(true);
    }
  };

  if (!currentTask) return <Skeleton active />;
  else
    return (
      <div>
        <Row>
          <Typography.Title level={2}>{locale.inputDataTitle}</Typography.Title>
        </Row>

        <Card title={<h3>{locale.taskInfo.title}</h3>} style={{ overflow: 'auto' }}>
          <Row>
            <Col span={6}>
              {locale.taskInfo.taskType.label}:
              {currentTask.detail.orderType === 'domestic' && locale.domestic}
              {currentTask.detail.orderType === 'export' && locale.export}
            </Col>
            <Col span={6}>
              {locale.taskInfo.palletType.label}: {currentTask.detail.pallet?.name}
            </Col>
          </Row>
        </Card>

        <div style={{ marginTop: '20px' }}>
          <Card
            title={
              <h3>
                {locale.domesticCargoList.title}
                <span>
                  <ImportOrdersModal disabled={isSelecting} />
                  <Button
                    disabled={isSelecting}
                    onClick={addNewGoods}
                    type="link"
                    data-test-id="addOrderData"
                  >
                    {locale.add}
                  </Button>
                  <OrderDataDownloader
                    fileName={`${currentTask.name}-order-data.xlsx`}
                    data={orderData}
                  />
                  {orderData.length > 0 && (
                    <Button
                      onClick={hideOrShowSelection}
                      type="link"
                      data-test-id="toggleSelection"
                    >
                      {isSelecting ? locale.cancel : locale.batchDelete}
                    </Button>
                  )}
                  {isSelecting && (
                    <DeleteConfirm
                      content="default"
                      disabled={selectedRowKeys.length === 0}
                      onConfirm={deleteGoods(selectedRowKeys)}
                    />
                  )}
                </span>
              </h3>
            }
          >
            <Table
              rowKey="id"
              rowSelection={isSelecting && rowSelection}
              columns={
                currentTask.detail.orderType === 'domestic'
                  ? domesticOrderDataColumns(locale, renderActions)
                  : exportOrderDataColumns(locale, renderActions)
              }
              loading={isFetchingOrderData || orderDataMutation.isLoading}
              dataSource={orderData}
              pagination={paginationSettings}
            />
          </Card>
          <FormModal {...goodsFormModalSettings} />
        </div>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Button type="primary">
            <Link to={`/task/${taskId}/config/`} data-test-id="next">
              {globalLocale.next}
            </Link>
          </Button>
        </div>
      </div>
    );
};

export default TaskOrderDataPage;
