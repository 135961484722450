import * as React from 'react';
import { Modal, message, Table, Divider } from 'antd';
import { useQuery } from 'react-query';
import { ResultType, SuggestionType } from '../../../../components/interactiveScene/types';
import { listMaterials } from '../../../../utils/api';
import { MaterialType } from '../../../masterDataManagement/material/types';
import { ColumnProps } from 'antd/lib/table';
import { TaskResultLocale } from '../../../../locale/types';
import { useLocaleData } from '../../../../locale';
import RuleDescription from '../components/ruleDescription';

type Props = {
  result: ResultType;
  onClose: () => void;
};
const SuggestionModal = (props: Props) => {
  const { data: materials } = useQuery<MaterialType[]>('materials', listMaterials);
  // PARSE THE PALLET TO MATERIAL QUANTITY IN SUGGESTION.
  const locale: TaskResultLocale = useLocaleData<TaskResultLocale>('taskResult');

  const calculateSuggestedMaterial = (materials: MaterialType[], suggestion: SuggestionType) => {
    if (!materials || !suggestion) return [];
    // pallets are at index 4....
    const suggestPalletList = suggestion.pallets.map((x) => x[4]);
    //material code is at index 3
    const palletComposition: MaterialType[][] = suggestPalletList.map((eachPallet) => {
      const palletMaterialCodes = eachPallet.map((eachMaterial) => eachMaterial[3]);
      const palletMaterial: MaterialType[] = palletMaterialCodes.map((eachMaterialCode) => {
        const searchResult = materials.find(
          (material) => material.materialNumber == eachMaterialCode
        );
        if (!!searchResult) return searchResult;
        else message.error(`Can not find material:${eachMaterialCode}`);
      });
      return palletMaterial;
    });
    // suggestion are joined by index.
    const suggestionList = props.result.suggestions.suggestions;

    const suggestedMaterialList = suggestionList.map((eachSuggestion) =>
      eachSuggestion.map((eachSuggestedPalletQuantity, index) => {
        const suggestedMaterial = palletComposition[index].reduce((pre, cur) => ({
          ...pre,
          weight: pre.weight + cur.weight,
        }));

        return {
          ...suggestedMaterial,
          weight: suggestedMaterial.weight * eachSuggestedPalletQuantity,
        };
      })
    );
    return suggestedMaterialList;
  };

  const columns: ColumnProps<MaterialType>[] = [
    { title: locale.code, dataIndex: 'materialNumber' },
    { title: locale.name, dataIndex: 'description' },
    { title: locale.quantity, dataIndex: 'weight', render: (value) => `${value} kg` },
  ];

  const getSuggestionResultTable = (item: MaterialType[], index) => (
    <div key={index}>
      {index !== 0 ? (
        <div style={{ fontSize: '1.2rem', fontWeight: 600, textAlign: 'center' }}>Or</div>
      ) : null}
      <strong>
        {
          <div>
            {index === 0
              ? 'Maximize Weight Utilization'
              : index === 1
              ? 'Minimize Types of Material'
              : ''}
          </div>
        }
      </strong>
      <Table
        columns={columns}
        dataSource={item}
        pagination={false}
        style={{ width: '100%' }}
        rowKey={(x) => x.id}
      />
      <Divider />
    </div>
  );
  return (
    <Modal
      title={locale.suggestions}
      visible={!!props.result}
      onCancel={props.onClose}
      width={1024}
      footer={null}
    >
      <div>
        <h3>{locale.suggestions}</h3>
        <RuleDescription rule={props.result?.rule} />
        <Divider />
        {locale.addMoreMaterials}
        {calculateSuggestedMaterial(materials, props.result?.suggestions)?.map(
          getSuggestionResultTable
        )}
      </div>
    </Modal>
  );
};

export default SuggestionModal;
