import React, { Fragment, useState } from 'react';
import { Button, Upload, Modal, notification } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useErrorMessagesHandler } from '../../../../utils/errorMessageHandler';
import { CargoImportLocale } from '../../../../locale/types';
import { useLocaleData } from '../../../../locale';
import { uploadMaterial } from '../../../../utils/api';
import FileDownloader from '../../../../components/fileDownloader/fileDownloader';

const ImportCargoesModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const errorMessageHandler = useErrorMessagesHandler();

  const locale: CargoImportLocale = useLocaleData('cargoImport');

  const uploadMutation = useMutation(uploadMaterial, {
    onSuccess: () => {
      notification.destroy();
      queryClient.invalidateQueries('materials');
    },
    onError: (error: AxiosError) => {
      errorMessageHandler(error.response.data);
    },
  });

  const closeModal = () => {
    setModalVisible(false);
  };
  const CloseModalButton = (
    <Button type="primary" key="closeModal" onClick={closeModal}>
      {locale.closeText}
    </Button>
  );
  return (
    <Fragment>
      <Button type="link" onClick={(e) => setModalVisible(true)} data-test-id="importCargo">
        {locale.title}
      </Button>
      <Modal
        visible={modalVisible}
        title={locale.title}
        footer={[CloseModalButton]}
        onCancel={() => setModalVisible(false)}
      >
        <Upload.Dragger
          multiple={false}
          accept=".xls,.xlsx,.csv"
          customRequest={uploadMutation.mutate}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{locale.dropZoneText}</p>
        </Upload.Dragger>
        <p style={{ marginTop: '20px' }}>
          {locale.downloadTemplate}
          <FileDownloader
            text={locale.here}
            url="/download/cargo_type_template.xlsx"
            fileName="cargo_upload_template.xlsx"
          />
        </p>
      </Modal>
    </Fragment>
  );
};

export default ImportCargoesModal;
