import * as React from 'react';
import { Table, Tag, Tooltip } from 'antd';
import { SubtaskStatusReasonType, SubtaskStatusType, SubtaskType } from '../../types';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { toISODateTime } from '../../../../utils/utils';
import { useRecoilValue } from 'recoil';
import { currentSubtaskState, currentTaskState } from '../../atoms';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useLocaleData } from '../../../../locale';
import { TaskResultLocale } from '../../../../locale/types';
import { useHistory, useParams } from 'react-router';

const ResultHistoryTable = () => {
  const { taskId } = useParams<{ taskId: string; subtaskId: string }>();
  const currentTask = useRecoilValue(currentTaskState);
  const currentSubtask = useRecoilValue(currentSubtaskState);
  const locale: TaskResultLocale = useLocaleData<TaskResultLocale>('taskResult');
  const history = useHistory();

  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);

  const ErrorStatus = (props: { title: string; statusText: string }) => (
    <Tooltip title={props.title}>
      <Tag color="error">
        {props.statusText}
        <InfoCircleOutlined style={{ marginLeft: '3px' }} />
      </Tag>
    </Tooltip>
  );

  const renderErrorStatus = (statusReason?: SubtaskStatusReasonType) => {
    switch (statusReason.code) {
      case 'pipeline.201':
        return <ErrorStatus title={locale.notEnoughContainers} statusText={locale.error} />;
      case 'pipeline.202':
        if (statusReason.name === 'InvalidColumnsNumError')
          return <ErrorStatus title={locale.columnError} statusText={locale.error} />;
        if (statusReason.name === 'InvalidRowsNumError')
          return <ErrorStatus title={locale.rowError} statusText={locale.error} />;
      case 'pipeline.203':
        if (statusReason.name === 'OverweightPalletError')
          return <ErrorStatus title={locale.overWeightPallet} statusText={locale.error} />;
        if (statusReason.name === 'OverheightPalletError')
          return <ErrorStatus title={locale.overHeightPallet} statusText={locale.error} />;
      default:
        return <Tag color="error">{locale.error}</Tag>;
    }
  };

  const renderStatus = (value: SubtaskStatusType, statusReason?: SubtaskStatusReasonType) => {
    switch (value) {
      case 'running':
        return <LoadingOutlined />;
      case 'waiting':
        return <Tag color="warning">{locale.waiting}</Tag>;
      case 'submitted':
        return <Tag color="warning">{locale.waiting}</Tag>;
      case 'finished':
        return <Tag color="success">{locale.completed}</Tag>;
      case 'terminated':
        return <Tag color="warning">{locale.timeout}</Tag>;
      case 'error':
      default:
        return renderErrorStatus(statusReason);
    }
  };
  const resultHistoryColumns: ColumnProps<SubtaskType>[] = [
    {
      title: locale.time,
      dataIndex: 'created',
      render: (value) => toISODateTime(new Date(value)),
    },
    {
      title: locale.status,
      dataIndex: 'status',
      align: 'center',
      render: (value, record) => renderStatus(value, record.statusReason),
    },
  ];

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const onSelect = (record: SubtaskType) => {
    history.push(`/task/${taskId}/result/${record.id}`);
    setSelectedRowKeys([record.id]);
  };

  const rowSelection: TableRowSelection<SubtaskType> = {
    type: 'radio',
    selectedRowKeys,
    onSelect,
  };

  const pageSize = 3;
  const pagination = {
    defaultPageSize: pageSize,
    current: currentPageNumber,
    onChange: (page, pageSize) => setCurrentPageNumber(page),
  };

  React.useEffect(() => {
    setSelectedRowKeys([currentSubtask?.id]);
    const pageNumber =
      Math.floor(
        currentTask?.subtaskList.findIndex((x) => x.id === currentSubtask?.id) / pageSize
      ) + 1;
    pageNumber > 0 ? setCurrentPageNumber(pageNumber) : setCurrentPageNumber(1);
  }, [currentSubtask]);

  //on row click
  return (
    <Table
      columns={resultHistoryColumns}
      dataSource={currentTask?.subtaskList}
      pagination={pagination}
      rowKey={(x) => x.id}
      rowSelection={rowSelection}
      rowClassName="result-history-hoverable"
      onRow={(record) => ({
        onClick: (e) => onSelect(record),
      })}
    />
  );
};

export default ResultHistoryTable;
