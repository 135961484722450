import * as React from 'react';
import { Card, Col, Row, Skeleton, Typography } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { toISODateTime } from '../../../utils/utils';
import TaskLog from './components/taskLog/taskLog';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentSubtaskState, currentTaskState } from '../atoms';
import { useLocaleData } from '../../../locale';
import { TaskResultLocale } from '../../../locale/types';
import ResultOrderDataModal from './components/resultOrderDataModal';
import ResultDetailTable from './components/resultDetailTable';
import ResultHistoryTable from './components/resultHistoryTable';
import './resultPage.css';

const ResultPage = () => {
  // const { subtaskId } = useParams<{ taskId: string; subtaskId: string }>();
  const locale: TaskResultLocale = useLocaleData<TaskResultLocale>('taskResult');
  const currentTask = useRecoilValue(currentTaskState);
  const currentSubtask = useRecoilValue(currentSubtaskState);

  const ResultDetailTitle = () => {
    const createdTime = currentSubtask?.created
      ? toISODateTime(new Date(currentSubtask?.created))
      : '';

    return (
      <div>
        <span>
          {locale.resultDetail} : {createdTime}
        </span>
        <ResultOrderDataModal />
      </div>
    );
  };

  if (!currentTask) return <Skeleton active />;
  return (
    <div>
      <Typography.Title level={2}>{locale.result}</Typography.Title>
      <Row gutter={10}>
        <Col md={{ span: 24 }} lg={{ span: 12 }}>
          <Card title={locale.resultHistory} style={{ overflow: 'auto' }}>
            <ResultHistoryTable />
          </Card>
        </Col>

        <Col lg={{ span: 24 }} xl={{ span: 12 }}>
          <TaskLog />
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <Card title={<ResultDetailTitle />} style={{ overflow: 'auto' }}>
            <ResultDetailTable />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ResultPage;
