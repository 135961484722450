import React from 'react';
import { PalletAddLocale, Locale } from '../../../locale/types';
import { useLocaleData } from '../../../locale';
import { Form, Input, Divider, Button, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PalletType } from './types';
import { updatePallet, postPallet, listPalletBases } from '../../../utils/api';

interface ContactFormProps {
  onEditPallet?: PalletType;
  postSubmit: () => void;
  postCancel: () => void;
}

function PalletForm(props: ContactFormProps): JSX.Element {
  const [form] = Form.useForm();
  const locale = useLocaleData<PalletAddLocale>((locale: Locale) => locale.palletAdd);
  const { data: palletBaseList, isLoading: isFetchingPalletBaseList } = useQuery<PalletType[]>(
    'palletList',
    listPalletBases
  );

  const queryClient = useQueryClient();

  const updateMutation = useMutation(updatePallet, {
    onSuccess: () => {
      queryClient.invalidateQueries('palletList');
    },
  });

  const addMutation = useMutation(postPallet, {
    onSuccess: () => {
      queryClient.invalidateQueries('palletList');
    },
  });

  const formOptions = {
    name: {
      rules: [
        { required: true, type: 'string', message: locale.name.required },
        { type: 'string', max: 50, message: locale.nameTooLong },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (
              palletBaseList.find(
                (pallet) =>
                  (pallet.name === value &&
                    props.onEditPallet &&
                    props.onEditPallet.id !== pallet.id) ||
                  (pallet.name === value && !props.onEditPallet)
              )
            ) {
              return Promise.reject(new Error(locale.repeatedName));
            }

            return Promise.resolve();
          },
        }),
      ] as Rule[],
      normalize: (value) => value.trim(),
      initialValue: props.onEditPallet ? props.onEditPallet.name : undefined,
    },
    length: {
      rules: [{ required: true, type: 'number', message: locale.length.required }] as Rule[],
      initialValue: props.onEditPallet ? props.onEditPallet.length : undefined,
    },
    width: {
      rules: [{ required: true, type: 'number', message: locale.width.required }] as Rule[],
      initialValue: props.onEditPallet ? props.onEditPallet.width : undefined,
    },
    height: {
      rules: [{ required: true, type: 'number', message: locale.height.required }] as Rule[],
      initialValue: props.onEditPallet ? props.onEditPallet.height : undefined,
    },
    weight: {
      rules: [{ required: true, type: 'number', message: locale.weight.required }] as Rule[],
      initialValue: props.onEditPallet ? props.onEditPallet.weight : undefined,
    },
  };
  const handleSubmit = (values) => {
    const pallet = {
      id: props.onEditPallet?.id,
      name: values.name,
      length: values.length,
      width: values.width,
      height: values.height,
      weight: values.weight,
    };
    if (props.onEditPallet) {
      updateMutation.mutate({ palletId: pallet.id, pallet });
    } else {
      addMutation.mutate({ pallet });
    }
    props.postSubmit();
  };
  return (
    <Form onFinish={handleSubmit} form={form} layout="vertical">
      <Form.Item
        name="name"
        label={locale.name.label}
        {...formOptions.name}
        style={{ textAlign: 'center' }}
      >
        <Input data-test-id="name" />
      </Form.Item>
      <Form.Item
        name="length"
        label={locale.length.label}
        {...formOptions.length}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={10}
          max={2000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="length"
        />
      </Form.Item>
      <Form.Item
        name="width"
        label={locale.width.label}
        {...formOptions.width}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={10}
          max={2000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="width"
        />
      </Form.Item>
      <Form.Item
        name="height"
        label={locale.height.label}
        {...formOptions.height}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={10}
          max={2000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="height"
        />
      </Form.Item>
      <Form.Item
        name="weight"
        label={locale.weight.label}
        {...formOptions.weight}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={0}
          max={2000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="weight"
        />
      </Form.Item>
      <Divider />
      <div className="form-action-button-group" style={{ textAlign: 'right' }}>
        <Button onClick={props.postCancel} data-test-id="cancel">
          {locale.cancel}
        </Button>
        <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }}>
          {locale.save}
        </Button>
      </div>
    </Form>
  );
}

export default PalletForm;
