import React, { useEffect, useMemo, useState } from 'react';
import { ContainerAddLocale, Locale } from '../../../locale/types';
import { useLocaleData } from '../../../locale';
import { Form, Input, Select, Divider, Button, InputNumber, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ContainerSpec } from './types';
import { listContainers, updateContainer, postContainer } from '../../../utils/api';

interface ContactFormProps {
  onEditContainer?: ContainerSpec;
  postSubmit: () => void;
  postCancel: () => void;
}

function ContainerForm(props: ContactFormProps): JSX.Element {
  const [form] = Form.useForm();
  const locale = useLocaleData<ContainerAddLocale>((locale: Locale) => locale.containerAdd);
  const [containerType, setContainerType] = useState('shipping');
  const { data } = useQuery<ContainerSpec[]>('containerList', listContainers());

  useEffect(() => {
    if (props.onEditContainer) {
      setContainerType(props.onEditContainer.type);
    }
  }, []);

  const queryClient = useQueryClient();

  const updateMutation = useMutation(updateContainer, {
    onSuccess: () => {
      message.success(locale.message.containerUpdated);
      queryClient.invalidateQueries('containerList');
    },
  });

  const addMutation = useMutation(postContainer, {
    onSuccess: () => {
      message.success(locale.message.containerAdded);
      queryClient.invalidateQueries('containerList');
    },
  });

  const handleContainerTypeChange = (value: string): void => {
    setContainerType(value);
  };

  const formOptions = {
    name: {
      rules: [
        { required: true, type: 'string', message: locale.name.required },
        { type: 'string', max: 50, message: locale.nameTooLong },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (
              data.find(
                (contact) =>
                  (contact.name === value &&
                    props.onEditContainer &&
                    props.onEditContainer.id !== contact.id) ||
                  (contact.name === value && !props.onEditContainer)
              )
            ) {
              return Promise.reject(new Error(locale.repeatedName));
            }

            return Promise.resolve();
          },
        }),
      ] as Rule[],
      normalize: (value) => value.trim(),
      initialValue: props.onEditContainer ? props.onEditContainer.name : undefined,
    },
    type: {
      rules: [{ required: true, type: 'string', message: locale.type.required }] as Rule[],
      initialValue: props.onEditContainer ? props.onEditContainer.type : 'shipping',
    },
    length: {
      rules: [{ required: true, type: 'number', message: locale.length.required }] as Rule[],
      initialValue: props.onEditContainer ? props.onEditContainer.length : undefined,
    },
    width: {
      rules: [{ required: true, type: 'number', message: locale.width.required }] as Rule[],
      initialValue: props.onEditContainer ? props.onEditContainer.width : undefined,
    },
    height: {
      rules: [{ required: true, type: 'number', message: locale.height.required }] as Rule[],
      initialValue: props.onEditContainer ? props.onEditContainer.height : undefined,
    },
    weight: {
      rules: [{ required: true, type: 'number', message: locale.weight.required }] as Rule[],
      initialValue: props.onEditContainer ? props.onEditContainer.weight : undefined,
    },
    maxLoad: {
      rules: [{ required: true, type: 'number', message: locale.maxLoad.required }] as Rule[],
      initialValue: props.onEditContainer ? props.onEditContainer.maxLoad : undefined,
    },
    maxPallet: {
      rules: [{ required: true, type: 'number', message: locale.maxPallet.required }] as Rule[],
      initialValue: props.onEditContainer ? props.onEditContainer.maxPallet : undefined,
    },
    refCost: {
      rules: [{ required: true, type: 'number', message: locale.refCost.required }] as Rule[],
      initialValue: props.onEditContainer ? props.onEditContainer.refCost : undefined,
    },
  };
  const handleSubmit = (values) => {
    const container = {
      id: props.onEditContainer?.id,
      name: values.name,
      type: values.type,
      length: values.length,
      width: values.width,
      height: values.height,
      weight: values.weight,
      maxLoad: values.maxLoad,
      maxPallet: values.maxPallet,
      refCost: values.refCost,
    };
    if (props.onEditContainer) {
      updateMutation.mutate({ containerId: container.id, container });
    } else {
      addMutation.mutate({ container });
    }
    props.postSubmit();
  };
  return (
    <Form onFinish={handleSubmit} form={form} layout="vertical">
      <Form.Item
        name="name"
        label={locale.name.label}
        {...formOptions.name}
        style={{ textAlign: 'center' }}
      >
        <Input data-test-id="name" />
      </Form.Item>
      <Form.Item name="type" {...formOptions.type} label={locale.type.label}>
        <Select
          showSearch
          onChange={handleContainerTypeChange}
          disabled={props.onEditContainer !== undefined}
          data-test-id="type"
        >
          <Select.Option key="shipping" value="shipping" data-test-id="shipping">
            {locale.shipping}
          </Select.Option>
          <Select.Option key="truck" value="truck" data-test-id="truck">
            {locale.truck}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="length"
        label={locale.length.label}
        {...formOptions.length}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={1000}
          max={20000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="length"
        />
      </Form.Item>
      <Form.Item
        name="width"
        label={locale.width.label}
        {...formOptions.width}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={1000}
          max={3000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="width"
        />
      </Form.Item>
      <Form.Item
        name="height"
        label={locale.height.label}
        {...formOptions.height}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={1000}
          max={3000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="height"
        />
      </Form.Item>
      <Form.Item
        name="weight"
        label={locale.weight.label}
        {...formOptions.weight}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={1000}
          max={20000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="weight"
        />
      </Form.Item>
      <Form.Item
        name="maxLoad"
        label={locale.maxLoad.label}
        {...formOptions.maxLoad}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={1000}
          max={200000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="maxLoad"
        />
      </Form.Item>
      {containerType === 'truck' && (
        <Form.Item
          name="maxPallet"
          label={locale.maxPallet.label}
          {...formOptions.maxPallet}
          style={{ textAlign: 'center' }}
        >
          <InputNumber
            min={1}
            max={2000}
            precision={0}
            style={{ width: '100%' }}
            data-test-id="maxPallet"
          />
        </Form.Item>
      )}
      <Form.Item
        name="refCost"
        label={locale.refCost.label}
        {...formOptions.refCost}
        style={{ textAlign: 'center' }}
      >
        <InputNumber
          min={0}
          max={20000}
          precision={0}
          style={{ width: '100%' }}
          data-test-id="refCost"
        />
      </Form.Item>
      <Divider />
      <div className="form-action-button-group" style={{ textAlign: 'right' }}>
        <Button onClick={props.postCancel} data-test-id="cancel">
          {locale.cancel}
        </Button>
        <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }}>
          {locale.save}
        </Button>
      </div>
    </Form>
  );
}

export default ContainerForm;
