import * as React from 'react';
import * as qs from 'query-string';
import { isEqual } from 'lodash';
import { useLocation, useParams } from 'react-router';
import { useQuery } from 'react-query';
import { getPlanData } from '../../utils/api';
import { ResultRuleType, ResultType } from '../../components/interactiveScene/types';
import ScenePreview from '../../components/interactiveScene/ScenePreview';
import RuleDescription from '../task/resultPage/components/ruleDescription';

const ExternalResultPage = () => {
  const { search } = useLocation();
  const { planId } = useParams<{ planId: string }>();
  const searchParsed = qs.parse(search)?.rule;
  const urlQuery = JSON.parse(searchParsed as string);

  const { data: resultData } = useQuery('planData', getPlanData(planId));

  const getMatchingPlan = (plan: ResultType[], query: ResultRuleType) => {
    return plan?.find((x) => isEqual(x.rule, query));
  };

  const result = getMatchingPlan(resultData, urlQuery);

  return (
    <div>
      <div style={{ padding: '10px 10px', backgroundColor: '#eee' }}>
        <RuleDescription rule={result?.rule} />
      </div>
      <ScenePreview
        planData={result?.plan ?? []}
        size={{ width: window.innerWidth - 250, height: window.innerHeight - 120 }}
      />
    </div>
  );
};

export default ExternalResultPage;
