import * as React from 'react';
import { message, Skeleton } from 'antd';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { currentSubtaskState, currentTaskState } from '../atoms';
import ConfigurationPage from '../configurationPage';
import TaskOrderDataPage from '../orderDataPage';
import ResultPage from '../resultPage';
import { getTask } from '../../../utils/api';
import { AxiosError } from 'axios';
import { useLocaleData } from '../../../locale';
import { ErrorMessageLocale } from '../../../locale/types';

const CurrentTaskMasterPage = () => {
  const { taskId, subtaskId } = useParams<{ taskId: string; subtaskId: string }>();
  const history = useHistory();
  const locale = useLocaleData<ErrorMessageLocale>('errorMessage');
  const [autoRefetchInterval, setAutoRefetchInterval] = React.useState(undefined);
  const [localCurrentTask, setLocalCurrentTask] = useRecoilState(currentTaskState);
  const setCurrentSubtask = useSetRecoilState(currentSubtaskState);

  // polling for task status when pending subtask exists
  React.useEffect(() => {
    const isAllSubtaskSettled = localCurrentTask?.subtaskList
      .map((x) => x.status !== 'running' && x.status !== 'waiting')
      .reduce((acc, cur) => acc && cur, true);
    isAllSubtaskSettled ? setAutoRefetchInterval(undefined) : setAutoRefetchInterval(2000);
  }, [localCurrentTask?.subtaskList]);

  // fetch remote task state and cache locally
  const { data: remoteCurrentTask, isLoading } = useQuery(['task', taskId], getTask(taskId), {
    refetchInterval: autoRefetchInterval,
    onError: (err: AxiosError) => {
      if (err.response.status === 404) {
        message.error(`${locale.notFound}, ${locale.backToTaskList}`);
        history.push('/tasks');
      }
    },
  });

  React.useEffect(() => {
    setLocalCurrentTask(remoteCurrentTask);
  }, [remoteCurrentTask]);

  // React.useEffect(() => {
  //   if (taskId !== localCurrentTask?.id) {
  //     setLocalCurrentTask(undefined);
  //     setCurrentSubtask(undefined);
  //   }
  // }, [taskId, localCurrentTask]);

  // if specified subtaskId, then set it.
  // if not then use the latest one, if there are no subtask, then the latest one should be undefined.
  // which is correct.
  React.useEffect(() => {
    !!subtaskId
      ? setCurrentSubtask(localCurrentTask?.subtaskList.find((x) => x.id === subtaskId))
      : setCurrentSubtask(localCurrentTask?.subtaskList[0]);
  }, [subtaskId, localCurrentTask?.subtaskList]);

  if (isLoading) {
    return <Skeleton active />;
  } else
    return (
      <div>
        <Switch>
          <Route path="/task/:taskId/orderData" component={TaskOrderDataPage} />
          <Route path="/task/:taskId/config" component={ConfigurationPage} />
          <Route path="/task/:taskId/result/:subtaskId" component={ResultPage} />
          <Route path="/task/:taskId/result/" component={ResultPage} />
        </Switch>
      </div>
    );
};

export default CurrentTaskMasterPage;
