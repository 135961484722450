import { useRecoilValue } from 'recoil';
import { currentLocaleState, accountInfo } from '../pages/account/atoms';
import { useMutation } from 'react-query';
import { forgetPassword } from '../utils/api';
import { AxiosError } from 'axios';
import { message } from 'antd';
import { useLocaleData } from '../locale';
import { UsersLocale } from '../locale/types';
import { useState } from 'react';

export const useForgetPasswordHook = () => {
  const currentLocale = useRecoilValue(currentLocaleState);
  const locale = useLocaleData<UsersLocale>('users');
  const [currentEmail, setCurrentEmail] = useState<string | undefined>(undefined);
  const userAccountInfo = useRecoilValue(accountInfo);

  const forgetPasswordMutation = useMutation(forgetPassword, {
    onSuccess: () => {
      setCurrentEmail(undefined);
    },
    onError: (e: AxiosError) => {
      switch (e.response.status) {
        case 429:
          const waitSeconds = parseInt(e.response.headers['retry-after']);
          const waitMin = Math.round(waitSeconds / 60);
          message.error(
            `${locale.message.resetExceedLimit} ${
              waitSeconds > 60
                ? waitMin + ` ${locale.message.minutes}`
                : waitSeconds + ` ${locale.message.secondes}`
            }.`
          );
          break;
        default:
          message.error(locale.message.serverErrorResetFailed);
      }
      setCurrentEmail(undefined);
    },
  });

  const setForgetPassword = (email: string) => {
    setCurrentEmail(email);
    const redirectUrl = window.location.origin + '/resetPassword';
    forgetPasswordMutation.mutate(
      {
        email,
        redirectUrl,
        lang: currentLocale,
        accountInfo: userAccountInfo,
      },
      {
        onSuccess: () => {
          message.success(`${locale.message.resetEmailSentTo} ${email}`);
        },
      }
    );
  };

  return { setForgetPassword, isLoading: !!currentEmail };
};
