import React from 'react';
import { Layout } from 'antd';
import logo from '../../assets/app-logo.png';
import './index.css';
import { useLocaleData } from '../../locale';
import { Locale } from '../../locale/types';

const AntHeader = Layout.Header;

const AppHeader: React.FC = () => {
  const locale = useLocaleData<Locale>();

  return (
    <AntHeader className="header">
      <div className="header-content">
        <img className="header-logo" src={logo} height="80px" alt="header-logo" />
        <div className="header-text">
          <h2>
            <strong>AI C</strong>ontainer <strong>L</strong>oading <strong>P</strong>lanner
          </h2>
          <span style={{ padding: '5px' }} />
          <h2>
            <i>{locale.global.saas}</i>
          </h2>
        </div>
      </div>
    </AntHeader>
  );
};

export default AppHeader;
