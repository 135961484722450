import * as React from 'react';
import { Button, Card, Typography } from 'antd';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { useLocaleData } from '../../../locale';
import { GlobalLocale, TaskLocale } from '../../../locale/types';
import { getTask } from '../../../utils/api';
import { currentConfigurationState } from '../atoms';
import { CandidateContainer, TaskType } from '../types';
import EditContainerListTagGroup from './editContainerListTagGroup';
import GeneratePlanButton from './generatePlanButton';

const ConfigurationPage = () => {
  const history = useHistory();
  const { taskId } = useParams<{ taskId: string }>();
  const globalLocale = useLocaleData<GlobalLocale>('global');
  const { data: currentTask } = useQuery<TaskType>(['task', taskId], getTask(taskId));
  const [localConfiguration, setLocalConfiguration] = useRecoilState(currentConfigurationState);
  const locale: TaskLocale = useLocaleData<TaskLocale>('task');

  React.useEffect(() => {
    if (currentTask) {
      const latestSubtask = currentTask?.subtaskList[0];
      const latestSubtaskContainerList = latestSubtask?.containerList.map((x) => ({
        container: x.container.id,
        number: x.number,
      }));
      setLocalConfiguration({
        ...localConfiguration,
        containerList: latestSubtaskContainerList ?? [],
      });
    }
  }, [currentTask]);

  const onContainerListChange = (newList: CandidateContainer[]) => {
    setLocalConfiguration({
      ...localConfiguration,
      containerList: newList,
    });
  };

  return (
    <div>
      <Typography.Title level={2}>{locale.configuration}</Typography.Title>
      <Card title={locale.selectTruckContainer}>
        <EditContainerListTagGroup
          value={localConfiguration.containerList}
          onChange={onContainerListChange}
          orderType={currentTask?.detail.orderType}
        />
      </Card>

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button
          onClick={() => history.push(`/task/${taskId}/orderData`)}
          style={{ marginRight: '20px' }}
          data-test-id="back"
        >
          {globalLocale.back}
        </Button>
        <GeneratePlanButton />
      </div>
    </div>
  );
};

export default ConfigurationPage;
