import React, { useEffect, useState, Fragment } from 'react';
import { Typography, Table, Card } from 'antd';
import { UserGroupTableSpecs, UserType, UserRoleType } from './types';
import { ColumnProps } from 'antd/lib/table';
import AddUserFormModal from './components/addUserFormModal';
import ResetPasswordButton from './components/resetPasswordButton';
import RoleLabel from './components/roleLabel';
import { useLocaleData } from '../../locale';
import { UsersLocale } from '../../locale/types';
import { useQuery } from 'react-query';
import { getUserRoles } from '../../utils/api';
import { useRecoilValue } from 'recoil';
import { accountInfo } from '../account/atoms';

const AllUserPage = (): JSX.Element => {
  const userAccountInfo = useRecoilValue(accountInfo);
  const locale = useLocaleData<UsersLocale>('users');

  const [tableData, setTableData] = useState<UserGroupTableSpecs[]>([]);
  const { data: userRoles, isLoading } = useQuery<UserRoleType[]>('userRoles', getUserRoles, {});

  const convertor = (user: UserType, displayTitle: string): UserGroupTableSpecs => ({
    ...user,
    name: `${user.firstName} ${user.lastName}`,
    displayTitle,
  });

  useEffect(() => {
    if (userRoles) {
      const result = userRoles
        .flatMap((userRole) => userRole.users.map((user) => convertor(user, userRole.displayTitle)))
        .sort((a, b) => new Date(a.created).valueOf() - new Date(b.created).valueOf());
      setTableData(result);
    }
  }, [userRoles]);

  // generate roleMap for user creation
  // Where did you actually use this?
  useEffect(() => {
    if (userRoles) {
      const result = new Map<string, string>();
      userRoles.forEach((role) => result.set(role.title, role.id));
    }
  }, [userRoles, userAccountInfo]);

  const renderActions = (_, record) => (
    <Fragment>
      <ResetPasswordButton email={record.email} disable={!record.isActive} />
    </Fragment>
  );

  const renderRoleLabel = (role, record) => <RoleLabel user={record} userRoles={userRoles} />;

  const columnConfig: ColumnProps<UserGroupTableSpecs>[] = [
    {
      title: locale.userTable.name,
      dataIndex: 'name',
    },
    {
      title: locale.userTable.email,
      dataIndex: 'email',
    },
    {
      title: locale.userTable.role,
      dataIndex: 'role',
      align: 'center',
      render: renderRoleLabel,
    },
    {
      title: locale.userTable.action,
      dataIndex: 'id',
      align: 'center',
      render: renderActions,
    },
  ];

  return (
    <div className="all-users page">
      <Typography.Title level={2}>{locale.title}</Typography.Title>
      <Card>
        <AddUserFormModal />
        <Table
          dataSource={tableData}
          columns={columnConfig}
          loading={isLoading}
          rowKey={(x) => x.id}
        />
      </Card>
    </div>
  );
};

export default AllUserPage;
