import { LocaleLanguage } from '../locale/types';

export const getToken = (): string | null => window.localStorage.getItem(`doraclp-basf-token`);
export const setToken = (value: string): void =>
  window.localStorage.setItem('doraclp-basf-token', value);

export const getDistrict = (): string | null =>
  window.localStorage.getItem(`doraclp-basf-district`);
export const setDistrict = (value: string): void =>
  window.localStorage.setItem('doraclp-basf-district', value);

export const clearStorage = (): void => window.localStorage.clear();

export const setLocalLanguage = (value: LocaleLanguage): void =>
  window.localStorage.setItem('doraclp-basf-language', value);

export const getLocalLanguage = (): LocaleLanguage =>
  window.localStorage.getItem('doraclp-basf-language') as LocaleLanguage;
