import React, { useEffect, useState } from 'react';
import { Row, Typography, Card, Table, Col, Button } from 'antd';

import { useLocaleData } from '../../locale';
import { TaskListLocale, Locale } from '../../locale/types';
import { TaskType } from './types';
import { useQuery } from 'react-query';
import { listTasks } from '../../utils/api';
import { toISODateTime } from '../../utils/utils';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { currentTaskState } from './atoms';

export interface TaskTableSpec {
  id: string;
  index: number;
  label: 'draft' | 'stable';
  name: string;
  created: string;
  ownerName: string;
  completedSubTask: string;
}

const AllTasks = () => {
  const pageSize = 20;
  const history = useHistory();
  const locale = useLocaleData<TaskListLocale>((locale: Locale) => locale.taskList);
  const setCurrentTask = useSetRecoilState(currentTaskState);
  const [pageNum, setPageNum] = useState(1);
  const [totalTaskNumber, setTotalTaskNumber] = useState(0);

  const { data: taskList, isLoading: isFetchingAllTasks } = useQuery<TaskType[]>(
    ['allTasks', pageNum],
    () =>
      listTasks(pageNum).then((data) => {
        setTotalTaskNumber(data.count);
        return data.results;
      })
  );

  const onSelectTask = (record: TaskType) => (e) => {
    setCurrentTask(record);
    history.push(
      `/task/${record.id}/result/${record.subtaskList.length > 0 ? record.subtaskList[0].id : ''}`
    );
  };

  const renderActions = (record: TaskType) => (
    <Button
      onClick={onSelectTask(record)}
      key={record.id}
      style={{ marginRight: '16px' }}
      type="link"
    >
      {locale.table.action.rowItems?.detail}
    </Button>
  );
  const tableColDef = [
    { title: locale.table.index, dataIndex: 'index', key: 'key' },
    { title: locale.table.name, dataIndex: 'name', key: 'name' },
    { title: locale.table.created, dataIndex: 'created', key: 'created' },
    { title: locale.table.owner, dataIndex: 'ownerName', key: 'ownerName' },
    { title: locale.table.completed, dataIndex: 'completedSubTask', key: 'completedSubTask' },
    {
      title: locale.table.action.name,
      key: 'action',
      render: renderActions,
    },
  ];

  const paginationSettings = {
    defaultPageSize: pageSize,
    total: totalTaskNumber,
    onChange: setPageNum,
    showSizeChanger: false,
  };

  const dataSource = taskList?.map((task: TaskType, index: number) => {
    const completedSubTaskNumber = task.subtaskList
      .map((x) => (x.status === 'finished' ? 1 : 0))
      .reduce((a, b) => a + b, 0);
    return {
      ...task,
      index: index + (pageNum - 1) * pageSize + 1,
      created: toISODateTime(new Date(task.created)),
      completedSubTask: `${completedSubTaskNumber} / ${task.subtaskList.length}`,
    };
  });

  return (
    <div>
      <Row>
        <Typography.Title level={2}>{locale.title}</Typography.Title>
      </Row>

      <Row>
        <Col span={24}>
          <Card>
            <Table
              columns={tableColDef}
              dataSource={dataSource}
              pagination={paginationSettings}
              loading={isFetchingAllTasks}
              rowKey={(x) => x.id}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default AllTasks;
