import * as React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Home from './base/homePage';
import AppHeader from '../components/header';
import MaterialManagementPage from './masterDataManagement/material';
import Tasks from './task/allTask';
import { Layout, Menu, Divider } from 'antd';
import { useLocaleData } from '../locale';
import { GlobalLocale, PageNavigationLocale } from '../locale/types';
import SubMenu from 'antd/lib/menu/SubMenu';
import {
  TableOutlined,
  HomeOutlined,
  BranchesOutlined,
  RadiusSettingOutlined,
  UnorderedListOutlined,
  GoldOutlined,
  ContainerOutlined,
  UserOutlined,
  TeamOutlined,
  ContactsOutlined,
  HeatMapOutlined,
} from '@ant-design/icons';

import packageJson from '../../package.json';
import AccountCenterPage from './account/accountCenterPage/accountCenterPage';
import AllUserPage from './users/allUsersPage';
import ContactBook from './contactBook';
import Pallet from './masterDataManagement/pallet';
import ConstraintPage from './masterDataManagement/constraint/constraint';
import ContainerManagementPage from './masterDataManagement/container';
import NewTaskPage from './task/newTaskPage';
import CurrentTaskMasterPage from './task/currentTaskMasterPage';
import NotFound from './base/notFoundPage';
import { useRecoilValue, useRecoilState } from 'recoil';
import { TaskType } from './task/types';
import { currentTaskState } from './task/atoms';
import { accountInfo } from './account/atoms';
import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { getAccountInfo } from '../utils/api';

const { Footer, Content, Sider } = Layout;

type NavItemSpec = {
  iconType: React.ReactNode;
  text: string;
  path: string;
  subMenu?: NavItemSpec[];
  disabled?: boolean;
};

const AppNav = (props: { items: NavItemSpec[] }) => {
  const history = useHistory();
  const changePath = (path: string) => (e) => history.push(path);
  const [openKeys, setOpenKeys] = React.useState(['/master-data', '/task']);
  const [selectedKeys, setSelectedKeys] = React.useState([history.location.pathname]);

  React.useEffect(() => {
    const routeArray = history.location.pathname.split('/');
    if (routeArray[1] === 'task') {
      const taskId = routeArray[2];
      const submodule = routeArray[3];
      setSelectedKeys([`/task/${taskId}/${submodule}/`]);
    }
  }, [history.location.pathname]);

  const renderNavItem = (item: NavItemSpec) => {
    const renderTitle = (item: NavItemSpec) => (
      <span data-test-id={item.path}>
        {item.iconType}
        <span>{item.text}</span>
      </span>
    );

    const toggleKeyOpen = (path) => () => {
      openKeys.includes(path)
        ? setOpenKeys(openKeys.filter((x) => x !== path))
        : setOpenKeys([...openKeys, path]);
    };

    if (item.subMenu)
      return (
        <SubMenu
          key={item.path}
          title={renderTitle(item)}
          onTitleClick={toggleKeyOpen(item.path)}
          disabled={item.disabled}
        >
          {item.subMenu.map(renderNavItem)}
        </SubMenu>
      );
    else {
      return (
        <Menu.Item key={item.path} onClick={changePath(item.path)} disabled={item.disabled}>
          {item.iconType}
          <span className="nav-text" data-test-id={item.path}>
            {item.text}
          </span>
        </Menu.Item>
      );
    }
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={selectedKeys}
      openKeys={['/master-data', '/task']}
    >
      {props.items.map(renderNavItem)}
    </Menu>
  );
};

const AppLayout = () => {
  const globalLocale = useLocaleData<GlobalLocale>('global');
  const navLocale = useLocaleData<PageNavigationLocale>('nav');
  const currentTask = useRecoilValue<TaskType | undefined>(currentTaskState);
  const [userAccountInfo, setUserAccountInfo] = useRecoilState(accountInfo);

  const getAccountInfoMutation = useMutation(getAccountInfo, {
    onSuccess: (res: AxiosResponse) => {
      setUserAccountInfo(res.data);
    },
    onError: () => {
      setUserAccountInfo(undefined);
    },
  });

  React.useEffect(() => {
    getAccountInfoMutation.mutate();
  }, []);

  const taskDetailNavItems: NavItemSpec[] = [
    {
      iconType: <TableOutlined />,
      text: navLocale.task.inputData,
      path: `/task/${currentTask?.id}/orderData/`,
      disabled: currentTask ? false : true,
    },
    {
      iconType: <BranchesOutlined />,
      text: navLocale.task.configTask,
      path: `/task/${currentTask?.id}/config/`,
      disabled: currentTask ? false : true,
    },
    {
      iconType: <BranchesOutlined />,
      text: navLocale.task.result,
      path: `/task/${currentTask?.id}/result/`,
      disabled: currentTask ? false : true,
    },
  ];

  const masterDataNavItems: NavItemSpec[] = [
    { iconType: <ContainerOutlined />, text: navLocale.main.containers, path: '/containers' },
    {
      iconType: (
        <span className="icon-wrapper">
          <span className="icon iconfont pallet"></span>
        </span>
      ),
      text: navLocale.main.pallets,
      path: '/pallets',
    },
    { iconType: <GoldOutlined />, text: navLocale.main.cargos, path: '/materials' },
    { iconType: <HeatMapOutlined />, text: navLocale.main.constraints, path: '/constraints' },
    { iconType: <TeamOutlined />, text: navLocale.main.users, path: '/users' },
  ];

  const MainNav = () => {
    let items: NavItemSpec[] = [
      { iconType: <HomeOutlined />, text: navLocale.main.home, path: '/' },
      {
        iconType: <UnorderedListOutlined />,
        text: navLocale.main.tasks,
        path: '/tasks',
      },
      {
        iconType: <RadiusSettingOutlined />,
        text: navLocale.main.newTask,
        path: '/tasks/new',
      },
      {
        iconType: <RadiusSettingOutlined />,
        text: `${navLocale.main.task}: ${currentTask ? currentTask.name : navLocale.main.none}`,
        path: '/task',
        subMenu: taskDetailNavItems,
        disabled: currentTask ? false : true,
      },
      {
        iconType: <ContainerOutlined />,
        text: navLocale.main.masterData,
        path: '/master-data',
        subMenu: masterDataNavItems,
      },
      { iconType: <ContactsOutlined />, text: navLocale.main.contactBook, path: '/contact-book' },
      { iconType: <UserOutlined />, text: navLocale.main.account, path: '/account' },
    ];

    // remove nav item when user is not admin
    if (!userAccountInfo?.isAdmin) items = items.filter((x) => x.path !== '/master-data');

    return <AppNav items={items} />;
  };

  return (
    <div className="layout" style={{ height: '100%' }}>
      <Layout style={{ minHeight: '100%' }}>
        <Sider width={250}>
          <div style={{ padding: '10px' }}>
            <h3 style={{ color: 'rgba(255,255,255,0.65)', textAlign: 'center' }}>
              <strong>AI-CLP</strong>
            </h3>
            <h4 style={{ color: 'rgba(255,255,255,0.65)', textAlign: 'center' }}>
              {globalLocale.saas}
            </h4>
          </div>
          <MainNav />
        </Sider>
        <Layout>
          <AppHeader />
          <Divider style={{ margin: '10px 0' }} />
          <Content>
            <div style={{ padding: '20px' }}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/tasks" component={Tasks} />
                <Route exact path="/tasks/new" component={NewTaskPage} />
                <Route path="/task/:taskId/result/:subtaskId" component={CurrentTaskMasterPage} />
                <Route path="/task/:taskId/:submodule" component={CurrentTaskMasterPage} />
                <Route path="/contact-book" component={ContactBook} />
                <Route path="/account" component={AccountCenterPage} />
                {userAccountInfo?.isAdmin && <Route path="/pallets" component={Pallet} />}
                {userAccountInfo?.isAdmin && (
                  <Route path="/constraints" component={ConstraintPage} />
                )}
                {userAccountInfo?.isAdmin && (
                  <Route path="/containers" component={ContainerManagementPage} />
                )}
                {userAccountInfo?.isAdmin && (
                  <Route path="/materials" component={MaterialManagementPage} />
                )}
                {userAccountInfo?.isAdmin && <Route path="/users" component={AllUserPage} />}
                <Route path="*" component={NotFound} />
              </Switch>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            v{packageJson.version} Copyright@2021 Dorabot All rights reserved.
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default AppLayout;
