import React, { CSSProperties } from 'react';
import { Modal } from 'antd';

type Props = {
  visible: boolean;
  openModal: () => void;
  closeModal: () => void;
  title: string;
  component: any;
  footer?: JSX.Element[];
  componentProps?: any;
  width?: number;
  style?: CSSProperties;
};

const FormModal = (props: Props): JSX.Element => {
  const effects = {
    postCancel: props.closeModal,
    postSubmit: props.closeModal,
  };

  return (
    <Modal
      visible={props.visible}
      title={props.title}
      footer={props.footer ? props.footer : null}
      onCancel={props.closeModal}
      width={props.width}
      destroyOnClose
      style={props.style ? props.style : null}
    >
      {props.visible ? <props.component {...props.componentProps} {...effects} /> : null}
    </Modal>
  );
};

export default FormModal;
