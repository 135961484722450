import React from 'react';
import { Form, Input, Button } from 'antd';
import { TaskLogLocale, Locale } from '../../../../../locale/types';
import { useLocaleData } from '../../../../../locale';
import { Rule } from 'antd/lib/form';
import { useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { postTaskComment } from '../../../../../utils/api';

function AddComment(): JSX.Element {
  const [form] = Form.useForm();
  const { taskId } = useParams<{ taskId: string }>();
  const locale = useLocaleData<TaskLogLocale>((locale: Locale) => locale.taskLog);

  const queryClient = useQueryClient();
  const mutation = useMutation(postTaskComment, {
    onSuccess: () => {
      queryClient.invalidateQueries('taskComments');
    },
  });

  const formOptions = {
    comment: {
      rules: [
        { required: true, type: 'string', message: locale.commentRequired },
        { type: 'string', max: 140, message: locale.comment140 },
      ] as Rule[],
    },
  };

  const handleSubmit = (values) => {
    const payload = {
      taskId,
      activityType: 'comment',
      activityDetail: values.comment,
    };
    mutation.mutate(payload);
    form.resetFields();
  };

  return (
    <Form onFinish={handleSubmit} form={form} layout="vertical">
      <Form.Item name="comment" {...formOptions.comment}>
        <Input.TextArea
          allowClear
          placeholder={locale.commentLabel}
          autoSize={{ minRows: 2, maxRows: 5 }}
        />
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button
          loading={mutation.isLoading}
          type="primary"
          htmlType="submit"
          style={{ marginLeft: '8px' }}
          data-test-id="comment"
        >
          {locale.add}
        </Button>
      </div>
    </Form>
  );
}

export default AddComment;
