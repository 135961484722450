import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Space, InputNumber, Button, Select, Skeleton } from 'antd';
import { useLocaleData } from '../../../locale';
import { TaskAddSubtaskLocale, Locale } from '../../../locale/types';
import { ContainerSpec } from '../../masterDataManagement/container/types';
import { useQuery } from 'react-query';
import { listContainers } from '../../../utils/api';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { CandidateContainer } from '../types';

type Props = {
  onChange: (value: CandidateContainer[]) => void;
  value: CandidateContainer[];
  orderType: string;
};

const initialContainerSpec = {
  container: '',
  number: 1,
};

const EditContainerListTagGroup = (props: Props) => {
  const valueIdList = props.value.map((x) => x.container);
  const locale = useLocaleData<TaskAddSubtaskLocale>((locale: Locale) => locale.task.addSubtask);
  const [isEditing, setIsEditing] = useState(false);

  const { data: containerTypes, isLoading: fetchingContainerTypes } = useQuery<ContainerSpec[]>(
    'containerTypes',
    listContainers()
  );

  const [selectedContainerSpec, setSelectedContainerSpec] = useState(initialContainerSpec);
  const [checked, setChecked] = useState(false);

  const handleSelect = (value: string): void => {
    setSelectedContainerSpec((prevState) => ({
      ...prevState,
      container: value,
    }));
  };
  const handleNumberChange = (value: number): void => {
    setSelectedContainerSpec((prevState) => ({
      ...prevState,
      number: value,
    }));
  };

  const handleCancel = (): void => {
    setIsEditing(false);
    setChecked(false);
    setSelectedContainerSpec(initialContainerSpec);
  };
  const handleOk = (): void => {
    const payload = {
      container: selectedContainerSpec.container,
      number: checked ? selectedContainerSpec.number : 1000,
    };
    props.onChange(props.value.concat([payload]));
    setIsEditing(false);
    setChecked(false);
    setSelectedContainerSpec(initialContainerSpec);
  };

  const handleClose = (id: string) => () =>
    props.onChange(props.value.filter((x) => x.container !== id));

  const startAdding = (): void => setIsEditing(true);

  const filterContainerTypesByOrderType = (containerTypes: ContainerSpec[], orderType) => {
    if (orderType === 'export') {
      return containerTypes.filter((x) => x.type === 'shipping');
    } else if (orderType === 'domestic') return containerTypes.filter((x) => x.type === 'truck');
    else return containerTypes;
  };

  const renderContainerSelectionOptions = (x) => (
    <Select.Option key={x.name} value={x.id}>
      {x.name}
    </Select.Option>
  );

  if (fetchingContainerTypes) {
    return <Skeleton active />;
  } else
    return (
      <div>
        {props.value.map((selected) => (
          <Tag
            key={selected.container}
            closable
            onClose={handleClose(selected.container)}
            color="blue"
            style={{ marginBottom: 10 }}
          >
            {
              containerTypes?.find(
                (container: ContainerSpec) => container.id === selected.container
              )?.name
            }
            {selected.number === 1000 ? '' : ` x ${selected.number}`}
          </Tag>
        ))}

        {isEditing ? (
          <div>
            <Space>
              <Select
                size="small"
                onSelect={handleSelect}
                defaultOpen
                autoFocus
                loading={fetchingContainerTypes}
                style={{ width: '200px', lineHeight: '24px' }}
              >
                {filterContainerTypesByOrderType(containerTypes, props.orderType)
                  .filter((x) => !valueIdList.includes(x.id))
                  .map(renderContainerSelectionOptions)}
              </Select>
              <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
              <div>{locale.numberLabel}</div>
              <InputNumber
                disabled={!checked}
                size="small"
                min={1}
                max={999}
                precision={0}
                value={selectedContainerSpec.number}
                onChange={handleNumberChange}
              />
              <Button
                type="primary"
                size="small"
                disabled={!selectedContainerSpec.container}
                onClick={handleOk}
                data-test-id="add"
              >
                {locale.add}
              </Button>
              <Button size="small" onClick={handleCancel} data-test-id="cancel">
                {locale.cancel}
              </Button>
            </Space>
          </div>
        ) : (
          valueIdList?.length !== containerTypes?.length && (
            <Tag
              onClick={startAdding}
              style={{ background: '#fff', borderStyle: 'dashed' }}
              data-test-id="startAdding"
            >
              <PlusOutlined /> {locale.addContainer}
            </Tag>
          )
        )}
      </div>
    );
};

export default EditContainerListTagGroup;
