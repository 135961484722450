import React from 'react';
import { Input, Select, Button, Divider, Form } from 'antd';
import { MaterialType, PackageSpec } from '../types';
import { useLocaleData } from '../../../../locale';
import { CargoAddLocale, Locale } from '../../../../locale/types';
import { round } from '../../../../utils/utils';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { updateMaterial, addMaterial, listPackages } from '../../../../utils/api';

interface CargoFormProps {
  onEditMaterial?: MaterialType;
  postSubmit: () => void;
  postCancel: () => void;
}

const MaterialForm = (props: CargoFormProps): JSX.Element => {
  const [form] = Form.useForm();
  const locale = useLocaleData<CargoAddLocale>((locale: Locale) => locale.cargoAdd);
  const queryClient = useQueryClient();
  const materialList = queryClient.getQueryData<MaterialType[]>('materials');
  const { data: packageList } = useQuery('packageList', listPackages);
  const updateMutation = useMutation(updateMaterial, {
    onSuccess: () => {
      queryClient.invalidateQueries('materials');
    },
  });

  const addMutation = useMutation(addMaterial, {
    onSuccess: () => {
      queryClient.invalidateQueries('materials');
    },
    onMutate: (newMaterial: MaterialType) => {
      const materialPackage = packageList.find((item) => item === newMaterial.package);

      const previousMaterials = queryClient.getQueryData('materials');
      queryClient.setQueriesData('materials', (old: MaterialType[]) => [
        { ...newMaterial, package: materialPackage, id: Date.now() },
        ...old,
      ]);
      return { previousMaterials };
    },
    onError: (err, newMaterial, context) => {
      queryClient.setQueryData('materials', context.previousMaterials);
    },
  });

  const cargoFormOptions = {
    materialNumberOptions: {
      rules: [
        {
          required: true,
          message: locale.materialNum.required,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (
              materialList.find(
                (material) =>
                  (material.materialNumber === value &&
                    props.onEditMaterial &&
                    props.onEditMaterial.id !== material.id) ||
                  (material.materialNumber === value && !props.onEditMaterial)
              )
            ) {
              return Promise.reject(new Error(locale.repeatedMaterialNumber));
            }
            return Promise.resolve();
          },
        }),
      ],
      normalize: (value) => value.trimRight(),
      initialValue: props.onEditMaterial ? props.onEditMaterial.materialNumber : undefined,
    },

    netWeightOptions: {
      rules: [
        {
          required: true,
          message: locale.netWeight.required,
        },
      ],
      initialValue: props.onEditMaterial ? round(props.onEditMaterial.weight, 2) : undefined,
      normalize: (x) => round(x, 2),
    },

    packageWeightOptions: {
      initialValue: props.onEditMaterial ? props.onEditMaterial.package.weight : undefined,
    },
    // weight means gross weight
    weightOptions: {
      initialValue: props.onEditMaterial
        ? round(props.onEditMaterial.weight + props.onEditMaterial.package.weight, 2)
        : undefined,
    },

    packageOptions: {
      rules: [
        {
          required: true,
          message: locale.package.required,
        },
      ],
      initialValue: props.onEditMaterial ? props.onEditMaterial.package?.id : undefined,
    },
    descriptionOptions: {
      rules: [
        {
          required: true,
          message: locale.materialDescription.required,
        },
      ],
      normalize: (value: string) => value.trimLeft(),
      initialValue: props.onEditMaterial ? props.onEditMaterial.description : undefined,
    },
  };

  // event handlers
  const handleSubmit = (values) => {
    const payload = {
      id: props.onEditMaterial?.id,
      materialNumber: values.materialNumber,
      package: values.package,
      weight: round(parseFloat(values.netWeight), 2),
      description: values.description,
    };
    if (props.onEditMaterial) {
      updateMutation.mutate(payload);
    } else {
      addMutation.mutate(payload);
    }

    props.postSubmit();
  };

  const handlePackageChange = (value) => {
    const packageType = packageList.find((x) => x.id === value);
    const netWeight = form.getFieldValue('netWeight');
    form.setFieldsValue({
      packageWeight: packageType?.weight,
      weight: round(packageType?.weight + (!isNaN(netWeight) ? netWeight : 0), 2),
    });
  };

  const handleNetWeightChange = (value) => {
    const netWeight = parseFloat(value.target.value) ? parseFloat(value.target.value) : 0;
    const packageWeight = form.getFieldValue('packageWeight');
    form.setFieldsValue({
      weight: round(netWeight + (!isNaN(packageWeight) ? packageWeight : 0), 2),
    });
  };

  // view
  return (
    <Form onFinish={handleSubmit} form={form} layout="vertical">
      <Form.Item
        name="materialNumber"
        label={locale.materialNum.label}
        {...cargoFormOptions.materialNumberOptions}
      >
        <Input data-test-id="materialNumber" />
      </Form.Item>

      <Form.Item name="package" label={locale.package.label} {...cargoFormOptions.packageOptions}>
        <Select onChange={handlePackageChange} data-test-id="package">
          {packageList &&
            packageList.map((packageType: PackageSpec) => (
              <Select.Option
                key={packageType.id}
                value={packageType.id}
                data-test-id={packageType.id}
              >
                {packageType.description}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="netWeight"
        label={locale.netWeight.label}
        {...cargoFormOptions.netWeightOptions}
      >
        <Input
          type="number"
          min={0.01}
          step={0.01}
          max={3000}
          suffix={' KG '}
          onChange={handleNetWeightChange}
          data-test-id="netWeight"
        />
      </Form.Item>

      <Form.Item
        name="packageWeight"
        label={locale.packageWeight.label}
        {...cargoFormOptions.packageWeightOptions}
      >
        <Input type="number" min={0.01} step={0.01} max={3000} suffix={' KG '} disabled />
      </Form.Item>

      <Form.Item name="weight" label={locale.weight.label} {...cargoFormOptions.weightOptions}>
        <Input type="number" min={0.01} step={0.01} max={3000} suffix={' KG '} disabled />
      </Form.Item>

      <Form.Item
        name="description"
        label={locale.materialDescription.label}
        {...cargoFormOptions.descriptionOptions}
      >
        <Input data-test-id="description" />
      </Form.Item>

      <Divider />
      <div className="form-action-button-group" style={{ textAlign: 'right' }}>
        <Button onClick={props.postCancel} data-test-id="cancel">
          {locale.cancel}
        </Button>
        <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }}>
          {locale.save}
        </Button>
      </div>
    </Form>
  );
};
export default MaterialForm;
