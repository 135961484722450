import { Locale, HelpTextLocale } from './types';

const enUsHelp: HelpTextLocale = {
  customerId: 'Code for identifying the customer of this order',
  destinationPort: 'Destination port of this order',
  orderNum: 'Order identifier code',
  subtaskName: 'Name to track this sub task',
  containerList: 'List of containers to be used during the load planning of this sub-task',
  palletType: 'Type of pallet for shipping this order',
  minCostPlanner:
    'Target at minimizing the total combination cost of pre-selected containers. E.g., the planner will prefer option [1x40HQ] over [2x20GP] since two 20GP containers is more costly ',
  plannerType:
    'A planner type corresponds to a particular load optimization goal, hence generates different loading plans',
  totalGrossWeight:
    'Auto calculated based on equation: Total gross weight = material weight + package weight',
  totalGrossWeightExtraHint:
    'Caution: please overwrite the calculated total gross weight if it deviates from the actual',
};

export const enUsLocale: Locale = {
  locale: 'en_US',
  // ********************* GLOBAL *********************
  global: {
    save: 'Save',
    saved: 'Saved',
    cancel: 'Cancel',
    saas: 'BTL SaaS Platform',
    next: 'Next',
    back: 'Back',
    edit: 'Edit',
    yes: 'Yes',
    no: 'No',
    error: 'Error',
    add: 'Add',
    submit: 'Submit',
    added: 'Added',
    created: 'Created',
    login: 'Log in',
    logout: 'Log out',
    deleted: 'Deleted',
    updated: 'Updated',
    submitted: 'Submitted',
    emailSent: 'Email Sent',
    stable: 'Stable',
    draft: 'Draft',
    serverError: {
      message: 'Server Error',
      description: 'Please try again later',
    },
    unknownError: 'Unknown error',
    someThingWentWrong: 'Something went wrong.',
    goBackToHome: 'Go back to Home',
    invalidTokenError: 'Session expired',
    nothingHere: 'nothing here',
  },
  utilization: {
    spaceUtilization: 'Space Utilization',
    loadUtilization: 'Load Utilization',
  },
  // ********************* LOGIN PAGE *********************
  login: {
    email: 'Email',
    password: 'Password',
    notification: {
      AUTH_ERROR: {
        message: 'Invalid email or password',
      },
    },
  },
  // ********************* SIDE NAV *********************
  nav: {
    main: {
      contactBook: 'Email Addresses',
      home: 'Home',
      newTask: 'New Task',
      tasks: 'All Tasks',
      cargos: 'Material Types',
      pallets: 'Pallet Types',
      containers: 'Container Types',
      userManual: 'User Manual',
      account: 'Account',
      users: 'User Management',
      constraints: 'Constraints',
      masterData: 'Master Data',
      task: 'Task',
      none: 'None',
    },
    task: {
      home: 'Home',
      inputData: 'Order Data',
      configTask: 'Configuration',
      newTask: 'New Task',
      result: 'Result',
    },
  },

  // ********************* Send Email Modal **************

  sendEmailModal: {
    help: 'Seems to be an error in the addresses',
    title: 'Send Email',
    hint: '*Please put each email in one line',
    cancel: 'Cancel',
    send: 'Send',
    subject: 'Subject',
    recipient: 'Recipient',
    loadingPlan: 'Loading Plan',
    errorMessage: {
      subjectEmpty: 'Please input a email subject',
      recipientEmpty: 'Please input at least one recipient',
    },
  },

  // ********************* Delete Confirm ****************
  deleteConfirm: {
    title: 'Are you sure to delete?',
    okText: 'Ok',
    cancelText: 'Cancel',
    deleteText: 'Delete',
  },
  // ********************* HOME PAGE *********************
  home: {
    title: 'Home',
    chinese: 'Chinese',
    english: 'English',
    introduction: {
      title: 'Welcome to AI-CLP SaaS Platform',
      content:
        'AI-CLP, providing an optimal container booking strategy and loading planning solution, can replace manual operation. It meets a high utilization rate, and the loading requirements in various complicated scenarios include load-bearing, collocation specification, and weight distribution balance.',
    },
    tutorial: {
      title: 'Tutorial',
      content: 'Click to Download User-Manual: ',
    },
    taskStepNew: {
      title: 'New Task',
      content: 'Click New Task in the left side menu to start configuring.',
    },
    taskStepOrderData: {
      title: 'Input Order Data',
      content: 'Select materials from a list of pre-defined types to load.',
    },
    taskStepConfigure: {
      title: 'Configure Task',
      content: 'Create sub-tasks to find the optimized solution for the scenario.',
    },
  },
  // ********************* ACCOUNT PAGE *********************
  account: {
    title: 'Account Center',
    accountInfo: {
      subtitle: 'Account Info',
      userName: 'User Name',
      email: 'Email',
      organization: 'Organization',
      department: 'Department',
      role: 'Role',
      loginCode: 'Verfication Code',
    },
    languageSetting: 'Language Setting / 语言设置',
    accountSecurity: {
      subtitle: 'Account Security',
      passwordLabel: 'Password',
      changePassword: {
        title: 'Change Password',
        change: 'Change',
        cancel: 'Cancel',
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        message: {
          changed: 'Changed',
          currentPasswordEmpty: 'Please enter current password.',
          lengthNotEnough: 'At least 8 characters.',
          confirmPasswordEmpty: 'Please confirm the new password.',
          notMatchingNewPassword: 'Not matching the new password.',
          sameAsCurrentPassword: 'New password cannot be same as current password',
          passwordNotStrongEnough:
            'Password need to container at lease one lower case letter[a-z], one upper case letter [A-Z], one digit [0~9] and one special character.',
        },
      },
    },
    logout: 'Logout',
    login: 'Login',
    getCode: 'Get Code',
    forgotPassword: 'Forgot Password',
    resetPassword: 'Reset Password',
    sendResetEmail: 'Send Reset Email',
    backToLogin: 'Back to Login',
    message: {
      emailEmpty: 'Please enter Email',
      passwordEmpty: 'Please enter password',
      loginSuccessful: 'Login Successful.',
      invalidEmailOrPassword: 'Invalid Email or Password.',
      serverErrorFailed: 'Server error, login failed.',
      unknownErrorFailed: 'Unknown error, login failed.',
      logoutSuccessful: 'Log out successful.',
      accessDenied: 'Access Denied.',
      loginCodeEmpty: 'Please enter email verification code',
      invalidVerificationCode: 'Invalid verification code',
      tooManyRequestPrefix: 'Too many requests, please wait ',
      tooManyRequestSuffix: 's and try again',
      passwordNotStrongEnough:
        'Password need to container at lease one lower case letter[a-z], one upper case letter [A-Z], one digit [0~9] and one special character.',
    },
  },

  // ********************* USERS PAGE *********************
  users: {
    title: 'User Management',
    userTable: {
      name: 'Name',
      email: 'Email',
      role: 'Role',
      organization: 'Organization',
      department: 'Department',
      isActive: 'Active',
      action: 'Action',
    },
    admin: 'Admin',
    user: 'User',
    isActive: 'Active',
    locked: 'Locked',
    createUserModal: {
      title: 'Create User',
      emailLabel: 'Email',
      firstNameLabel: 'First Name',
      lastNameLabel: 'Last Name',
      roleLabel: 'Role',
      cancel: 'Cancel',
      create: 'Create',
      addUser: 'Add User',
      message: {
        emailEmpty: 'Please enter a valid email',
        firstNameEmpty: 'Please enter first name',
        lastNameEmpty: 'Please enter last name',
        roleEmpty: 'Please select a role',
        cancel: 'Cancel',
      },
    },
    resetPassword: 'Reset Password',
    message: {
      changingOwnActive: 'Can not lock yourself out.',
      changingOwnRole: 'Can not change your own role.',
      serverErrorCreatFailed: 'Server Error, create user failed.',
      emailAlreadyRegistered: 'This Email has already been registered.',
      newUserCreated: 'New User Created.',
      passwordChange: 'Password Changed, please use the new password to login again',
      invalidCurrentPassword: 'Invalid current password.',
      serverErrorUpdateFailed: 'Server error. change password failed.',
      resetEmailSentTo: 'A reset password email has sent to',
      resetExceedLimit: 'Reset request exceeded limit, please try again in',
      minutes: 'minutes',
      secondes: 'seconds',
      serverErrorResetFailed: 'Server error. Failed to reset password.',
      resetSuccessful: 'Successfully reset password.',
      resetLinkExpired: 'Reset password link already expired.',
      userPasswordIncorrect: 'User password is incorrect',
      userAdded: 'User added',
    },
  },
  // ********************* Material LIST PAGE *********************
  cargoList: {
    title: 'Material Types',
    add: 'Add Material Type',
    edit: 'Edit Material Type',
    searchPlaceHolder: 'Search for Material Code, Package Type',
    table: {
      index: 'Index',
      materialNum: 'Material Code',
      packageType: 'Package Type',
      weight: 'Net Weight',
      description: 'Description',
      action: { name: 'Actions' },
    },
    notification: {
      'Material with this Unique Material Code already exists.': {
        message: 'Material Code Already Exists',
      },
      PACKAGE_ERROR: {
        message: 'Invalid Package Type',
        description: 'Please refresh and try again',
      },
    },
  },
  // ********************* Material CREATE PAGE *********************
  cargoAdd: {
    title: 'Add Material Type',
    save: 'Save',
    cancel: 'Cancel',
    materialNum: {
      label: 'Material Code',
      required: 'Please input an unique Material Code',
    },
    package: {
      label: 'Package Type',
      required: 'Please select a package type',
    },
    packageWeight: {
      label: 'Package Weight',
      required: 'Please enter a valid Package Weight',
    },
    netWeight: {
      label: 'Net Weight',
      required: 'Please enter a valid Net Weight for this Material Type',
    },
    weight: {
      label: 'Gross Weight',
      required: 'Please enter a valid weight for this Material Type',
    },
    materialDescription: {
      label: 'Description',
      required: 'Please enter a valid description for Material Type',
    },
    repeatedMaterialNumber: 'Material Code already exists!',
  },

  // ********************* CARGO IMPORT PAGE *********************
  cargoImport: {
    title: 'Import Material',
    dropZoneText: 'Click or drag file to this area to import',
    closeText: 'Close',
    downloadTemplate: 'Download Template: ',
    here: 'Here',
  },
  // ********************* CONTAINER LIST PAGE *********************
  containerList: {
    title: 'Container Type',
    add: 'Add Container',
    edit: 'Edit Container',
    shipping: 'container',
    truck: 'truck',
    table: {
      index: 'Index',
      name: 'Name',
      type: 'Type',
      maxPallet: 'Max Pallets',
      dimension: 'Dimension',
      weight: 'Weight',
      capacity: 'Payload Capacity',
      cost: {
        label: 'Reference Cost',
        help: 'The estimated proportional cost. This reference value enables the algorithm to take cost into account',
      },
      action: { name: 'Actions' },
    },
    message: {
      containerDeleted: 'Container deleted',
    },
  },
  // ********************* CONSTRAINT PAGE *********************
  constraint: {
    title: 'Constraints',
    palletType: 'Pallet Type',
    packageType: 'Package Type',
    packagePerLayer: 'Package Per Layer',
    layerPerPallet: 'Layers in Pallet',
    layerPerTrailer: 'Layers in Trailer',
  },
  // ********************* TASK LIST PAGE *********************
  taskList: {
    title: 'All Tasks',
    table: {
      index: 'Index',
      name: 'Task Name',
      label: 'Label',
      owner: 'Owner',
      created: 'Created At',
      completed: 'Success',
      action: {
        name: 'Action',
        rowItems: {
          detail: 'Detail',
        },
      },
    },
  },
  taskDetail: {
    title: 'Task Detail',
    downloadInput: 'Download Order',
    table: {
      favorite: 'Choose Plan',
      name: 'Plan Name',
      plannerType: 'Planner Type',
      containerList: 'Selected Container',
      status: 'Status',
      numContainers: 'Containers Used',
      action: {
        name: 'Action',
        rowItems: {
          inspect: 'Preview',
          download: 'Download PDF',
        },
      },
    },
  },
  // ********************* TASK PAGE *********************
  task: {
    taskType: 'Task Type',
    inputDataTitle: 'Order Data',
    configureTaskTitle: 'Configure Task',
    add: 'Add Order',
    edit: 'Edit Order',
    fill: 'Fill in',
    lineNumber: 'Line Number',
    submitted: 'Task submitted',
    label: 'Type',
    domestic: 'Domestic',
    export: 'Export',
    newTask: 'New Task',
    createNewTask: 'Create New Task',
    configuration: 'Configuration',
    generatePlan: 'Generate Plan',
    selectTruckContainer: 'Select Truck/Container',
    emptyContainers: 'Please select containers to load.',
    emptyGoods: 'Please add order data',
    batchDelete: 'Batch Delete',
    selectAll: 'Select All',
    unSelectAll: 'Unselect All',
    selectCurrentPage: 'Select Current Page',
    unSelectCurrentPage: 'Unselect Current Page',
    inverseSelect: 'Inverse Select',
    cancel: 'Cancel',
    delete: 'Delete',
    quickSelection: 'Quick Selection',
    errorMessages: {
      tooMuchGoodsWarning: 'Goods Num of Package or Weight might be too much.',
      tooMuchGoodsError: 'Too much goods Num of Package or weight, please reduce and try again',
      noGoodsError: 'There is no goods',
      noSubtaskError: 'There is no configured plan',
      zeroAttributeRowError: 'Detect unusual Num of Package or Total Weight value at these Index ',
      abnormalDensityWarning:
        'Detect unusual Total Weight value (based on the Num of Package provided) at these Index',
      orderNumberEmptyMessage:
        'Please fill in Order Number, You can also put any content for later reference.',
    },
    taskInfo: {
      title: 'Task Information',
      customerId: {
        label: 'Customer ID',
      },
      destinationPort: {
        label: 'Destination Port',
      },
      orderNum: {
        label: 'Order Number',
      },
      palletType: {
        label: 'Pallet Type',
      },
      taskType: {
        label: 'Task Type',
      },
    },

    domesticCargoList: {
      title: 'Order List',
      add: 'Add Order ',
      table: {
        index: 'Index',
        customerCode: 'Customer Code',
        customerName: 'Customer Name',
        postcode: 'Postcode',
        doNumber: 'DO Number',
        deliveryDate: 'Delivery Date',
        materialCode: 'Material Code',
        materialName: 'Material Name',
        orderQuantity: 'Order Quantity',
        actions: 'Actions',
      },
    },

    exportCargoList: {
      title: 'Order List',
      add: 'Add Order',
      table: {
        index: 'Index',
        customerCode: 'Customer Code',
        customerName: 'Customer Name',
        materialCode: 'Material Code',
        materialName: 'Material Name',
        orderQuantity: 'Order Quantity',
        actions: 'Actions',
        soNumber: 'SO Number',
        retaDate: 'RETA Date',
        destination: 'Destination',
      },
    },

    selectCargo: {
      title: 'Select Material',
      materialNum: {
        label: 'Material Code',
        required: 'Please select a Material Code',
      },
      materialDescription: {
        label: 'Description',
        required: 'Please select a Material Description',
      },
      package: {
        label: 'Package Type',
        required: 'Please select a Package Type',
      },
      quantity: {
        label: 'Num of Package',
        required: 'Please input the Number of Package of this Material',
      },
      totalWeight: {
        label: 'Total Weight',
        required: 'Please input the Gross Weight of these Material',
        help: enUsHelp.totalGrossWeight + '\n' + enUsHelp.totalGrossWeightExtraHint,
      },
    },
    importCargo: {
      title: 'Import',
      draggerText: 'Click or drag file to this area to import',
      downloadTemplate: 'Download template: ',
      here: 'Here',
    },
    configure: {
      title: 'Plan List',
      addSubTask: 'Add Plan',
      deleteWarning: 'Are you sure to delete this plan?',
      table: {
        index: 'Index',
        name: 'Name',
        plannerType: {
          label: 'Planner Type',
          help: enUsHelp.plannerType,
        },
        containerList: {
          label: 'Container List',
          help: enUsHelp.containerList,
        },
        maxTime: 'Estimated Run Time',
        maxTimeUnit: ' Mins',
        moreThan: 'More than',
        lessThan: 'Less than',
        action: 'Action',
      },
    },

    addSubtask: {
      title: 'Add Plan',
      addContainer: 'Add Container',
      add: 'Add',
      cancel: 'Cancel',
      numberLabel: 'Number: ',
      name: {
        label: 'Name',
        default: 'default-name',
      },
      maxTime: {
        label: 'Estimated Run Time',
      },
      plannerType: {
        label: 'Planner Type',
        required: 'Please select a planner type',
        help: enUsHelp.plannerType,
        selections: [
          {
            label: 'MIN_COST --- Minimal reference cost',
            help: enUsHelp.minCostPlanner,
          },
        ],
      },
      containerList: {
        label: 'Container List',
        required: 'Please select at least one type of container',
        help: enUsHelp.containerList,
      },
    },

    review: {
      title: 'Review and Submit',
      taskName: 'Task Name',
      taskType: 'Task Type',
      numCargos: 'Number of Entries',
      numSubtasks: 'Plan Number',
      orderNum: 'Order number',
      customerId: 'Customer ID',
      destinationPort: 'Destination Port',
      palletType: 'Pallet Type',
      label: 'Label',
    },
    notification: {
      emptyFile: {
        message: 'File Empty',
        description: 'Please check the file and try again',
      },
      fileTypeError: {
        message: 'FILE ERROR',
        description:
          'File content is not valid format, please download and edit the template provided below.',
      },
    },
  },
  errorMessage: {
    and: 'and',
    more: 'more',
    rows: 'at rows:',
    column: 'column',
    notFound: 'Data not found',
    notAllowed: 'Not allowed',
    alreadyExists: 'Already exists',
    unknownAPIError: 'Unknown API error',
    notAuthorized: 'Not authorized',
    samePassword: 'New password can not be same as the old one',
    invalidFileExtension: 'Invalid file extension',
    fileNotReadable: 'File is not readable',
    fileEmpty: 'File is empty',
    missingColumn: 'Missing column',
    notUnique: 'Data value is not unique',
    notCategorical: 'Invalid data',
    notValidNumber: 'Invalid Number',
    notValidType: 'Invalid data type',
    tooSmall: 'Too small',
    tooLarge: 'Too large',
    notPositive: 'Not Positive',
    outOfRange: 'Out of range',
    notNegative: 'Not Negative',
    notEndWith: 'Not end with',
    invalidConfigValue: 'Invalid Config Value',
    emptyValue: 'Empty Value',
    systemError: 'System Error',
    valueNotAllowed: 'Value Not Allowed',
    crossDepartment: 'Cross-Department Operation Not Allowed',
    notNewPassword: 'Password is Same as the Old one',
    notDivisible: 'total goods quantity is not wholly divisible by the material net weight',
    backToTaskList: 'Back task list.',
  },

  contactBook: {
    title: 'Email Addresses',
    add: 'Add Email',
    edit: 'Edit Email',
    searchPlaceHolder: 'Search',
    table: {
      index: 'Index',
      email: 'Email',
      bookName: 'Group',
      firstName: 'Name',
      action: { name: 'Actions' },
    },
    message: {
      emailDeleted: 'email Deleted',
    },
  },
  contactAdd: {
    title: 'Add Email',
    save: 'Save',
    cancel: 'Cancel',
    firstName: {
      label: 'name',
      required: 'Please input a name',
    },
    firstNameTooLong: 'Name too long',
    invalidEmail: 'Please input valid email',
    email: {
      label: 'Email',
      required: 'Please input an email address',
    },
    message: {
      repeatedEmail: 'Email already exists!',
      emailAdded: 'Email added',
      emailDeleted: 'Email deleted',
      emailUpdated: 'Email updated',
    },
  },
  createTask: {
    title: 'New Task',
    create: 'Create',
    cancel: 'Cancel',
    nameTooLong: 'Name too long',
    name: {
      label: 'Task Name',
      required: 'Please input task name',
    },
    orderType: {
      label: 'Order Type',
      required: 'Please choose order type',
    },
    palletType: {
      label: 'Pallet Type',
      required: 'Please choose pallet',
    },
    domestic: 'Domestic',
    export: 'Export',
  },
  taskDomesticCargo: {
    title: 'Add Domestic Order',
    customerCode: { label: 'Customer Code', required: 'Customer code is required' },
    customerName: { label: 'Customer Name' },
    postcode: { label: 'Postcode', required: 'Postcode is required' },
    doNumber: { label: 'DO Number', required: 'DO Number is required' },
    deliveryDate: { label: 'Delivery Date', required: 'Delivery Date is required' },
    materialCode: { label: 'Material Code', required: 'Material Code is required' },
    materialName: { label: 'Material Name', required: 'Material Name is required' },
    orderQuantity: { label: 'Order Quantity', required: 'Order Quantity is required' },
    packageType: { label: 'Package Type', required: 'Package Type is required' },
    errorMessage: {
      tooLong50: 'Text too long',
      notDivisible: 'Order Quantity must be divisible by material weight',
    },
  },
  taskExportCargo: {
    title: 'Add Domestic Order',
    customerCode: { label: 'Customer Code', required: 'Customer code is required' },
    customerName: { label: 'Customer Name' },
    soNumber: { label: 'SO Number', required: 'So Number is required' },
    retaDate: { label: 'RETA Date', required: 'RETA Date is required' },
    destination: { label: 'Destination', required: 'Destination is required' },
    materialCode: { label: 'Material Code', required: 'Material Code is required' },
    materialName: { label: 'Material Name', required: 'Material Name is required' },
    orderQuantity: { label: 'Order Quantity', required: 'Order Quantity is required' },
    packageType: { label: 'Package Type', required: 'Package Type is required' },
    errorMessage: {
      tooLong50: 'Text too long',
      notDivisible: 'Quantity is not full drum, try: ',
      notFound: ' not found, please try again.',
    },
  },
  palletList: {
    title: 'Pallet Types',
    add: 'Add Pallet',
    edit: 'Edit Pallet',
    table: {
      index: 'Index',
      name: 'Name',
      dimension: 'Dimension',
      weight: 'Weight',
      action: { name: 'Actions' },
    },
  },
  palletAdd: {
    title: 'Add Pallet',
    save: 'Save',
    cancel: 'Cancel',
    name: {
      label: 'Name',
      required: 'Pallet name is required',
    },
    length: {
      label: 'Length',
      required: 'Pallet length is required',
    },
    width: {
      label: 'Width',
      required: 'Pallet width is required',
    },
    height: {
      label: 'Height',
      required: 'Pallet height is required',
    },
    weight: {
      label: 'Weight',
      required: 'Pallet weight is required',
    },
    nameTooLong: 'Pallet name too long',
    repeatedName: 'Pallet name already exists!',
  },
  containerAdd: {
    title: 'Add Container',
    save: 'Save',
    cancel: 'Cancel',
    name: {
      label: 'Name',
      required: 'Container name is required',
    },
    type: {
      label: 'Type',
      required: 'Container name is required',
    },
    length: {
      label: 'Length',
      required: 'Container length is required',
    },
    width: {
      label: 'Width',
      required: 'Container width is required',
    },
    height: {
      label: 'Height',
      required: 'Container height is required',
    },
    weight: {
      label: 'Weight',
      required: 'Container weight is required',
    },
    maxLoad: {
      label: 'Payload Capacity',
      required: 'Container max load is required',
    },
    maxPallet: {
      label: 'Max Pallets',
      required: "Container's max pallets is required",
    },
    refCost: {
      label: 'Reference Cost',
      required: "Container's reference cost is required",
    },
    nameTooLong: 'Container name too long',
    shipping: 'container',
    truck: 'truck',
    repeatedName: 'This name already exists!',
    message: {
      containerAdded: 'Container added',
      containerUpdated: 'Container updated',
    },
  },
  taskLog: {
    add: 'Comment',
    commentLabel: 'Leave a comment',
    commentRequired: 'Comment is empty',
    comment140: 'Comment length is 140 characters top',
    feedback: 'Feedback',
    system: 'System',
    taskLogTitle: 'Task Log and Feedback',
    activity: {
      create: 'Task Created',
      upload: 'Upload File',
      run: 'Task Run',
      rerun: 'Task Re-Run',
      email: 'Email Sent',
      comment: 'Commented',
      'update-order-data': 'Order Updated',
    },
  },
  taskResult: {
    resultHistory: 'Result History',
    resultDetail: 'Result Detail',
    time: 'Time',
    error: 'Error',
    status: 'Status',
    weekNo: 'Week No',
    customerCode: 'Customer Code',
    destination: 'Destination',
    actions: 'Actions',
    result: 'Result',
    notEnoughContainers: 'Not enough containers to load all the order materials',
    waiting: 'Waiting',
    timeout: 'Timeout',
    completed: 'Completed',
    preview: 'Preview',
    email: 'Email',
    sendEmail: 'Send Email',
    suggestions: 'Suggestions',
    code: 'Code',
    name: 'Name',
    quantity: 'Quantity',
    forTheOrders: 'For the Orders:',
    addMoreMaterials:
      'We can add more materials to fill the remaining space by the combinations list below:',
    sendResult: 'Sending Result Email of:',
    task: 'Task',
    send: 'Send',
    recipient: 'Recipient',
    recipientInputPlaceholder: 'Please Input Recipient(s).',
    extraMessage: 'Extra Message',
    invalidEmail: 'Recipient contains invalid email address.',
    somethingWrong: 'Something wrong. Please check the email address and try again',
    emailSent: 'email(s) sent.',
    canNotFindMaterial: 'Material Not Found: ',
    maximizeWeightUtilization: 'Maximize Load Utilization',
    minimizeTypesOfMaterial: 'Minimize Types of Material',
    columnError:
      'Container width too less or pallet width too much, can not load. Container width should be [1,2] times of pallet width.',
    rowError: 'Container length too less or pallet length too much, can not load. ',
    overHeightPallet: 'Some cargo pallets are too tall, exceeded container height, can not load.',
    overWeightPallet:
      'Some cargo pallets are too heavy, exceeded container load capacity, can not load.',
    summary: 'Result Summary',
  },
};
