import React, { Fragment, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Divider, Modal, Checkbox, Select, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { UserCreateRequest, UserRoleType } from '../../types';
import { useLocaleData } from '../../../../locale';
import { UsersLocale } from '../../../../locale/types';
import { useQueryClient, useMutation } from 'react-query';
import { createUser, forgetPassword } from '../../../../utils/api';
import { AxiosResponse, AxiosError } from 'axios';
import { useRecoilValue } from 'recoil';
import { currentLocaleState } from '../../../account/atoms';

const AddUserFormModal = (): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const locale = useLocaleData<UsersLocale>('users');
  const currentLocale = useRecoilValue(currentLocaleState);
  const [form] = Form.useForm();
  const data = queryClient.getQueryData<UserRoleType[]>('userRoles');
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const reset = () => {
    form.resetFields();
    closeModal();
  };

  const redirectUrl = window.location.origin + '/resetPassword';
  const createUserMutation = useMutation(createUser, {
    onSuccess: async (res: AxiosResponse) => {
      message.success(locale.message.userAdded);
      await forgetPassword({
        redirectUrl,
        email: form.getFieldValue('email'),
        lang: currentLocale,
      });
      queryClient.invalidateQueries('userRoles');
      reset();
    },
    onError: (e: AxiosError) => {
      switch (e.response.status) {
        case 400:
          message.error(locale.message.emailAlreadyRegistered);
          break;
        default:
          message.error(locale.message.serverErrorCreatFailed);
      }
    },
  });

  const onFinish = (values) => {
    const { email, firstName, lastName, role } = values;
    const createRequest: UserCreateRequest = {
      email,
      firstName,
      lastName,
      roles: [role],
      password: Math.random().toFixed(16),
    };
    createUserMutation.mutate(createRequest);
  };

  const formOptions = {
    email: {
      rules: [
        { type: 'email', required: true, message: locale.createUserModal.message.emailEmpty },
      ] as Rule[],
    },
    firstName: {
      rules: [{ required: true, message: locale.createUserModal.message.firstNameEmpty }],
    },
    lastName: {
      rules: [{ required: true, message: locale.createUserModal.message.lastNameEmpty }],
    },
    role: {
      rules: [{ required: true, message: locale.createUserModal.message.roleEmpty }],
    },
  };

  return (
    <Fragment>
      <Button onClick={openModal} type="link" style={{ border: 'none' }} data-test-id="addUser">
        {locale.createUserModal.addUser}
      </Button>

      <Modal
        title={locale.createUserModal.title}
        visible={modalVisible}
        footer={null}
        onCancel={reset}
        maskClosable={false}
      >
        <Form form={form} onFinish={onFinish} onReset={reset} layout="vertical">
          <Form.Item name="email" label={locale.createUserModal.emailLabel} {...formOptions.email}>
            <Input data-test-id="email" />
          </Form.Item>
          <Form.Item
            name="firstName"
            label={locale.createUserModal.firstNameLabel}
            {...formOptions.firstName}
          >
            <Input data-test-id="firstName" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={locale.createUserModal.lastNameLabel}
            {...formOptions.lastName}
          >
            <Input data-test-id="lastName" />
          </Form.Item>
          <Form.Item name="role" label={locale.createUserModal.roleLabel} {...formOptions.role}>
            <Select data-test-id="role">
              {data &&
                data.map((userRole: UserRoleType) => (
                  <Select.Option key={userRole.id} value={userRole.id} data-test-id={userRole.id}>
                    {userRole.displayTitle}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Divider />
          <div style={{ textAlign: 'right' }}>
            <Button htmlType="reset" data-test-id="cancel">
              {locale.createUserModal.cancel}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginLeft: '8px' }}
              loading={createUserMutation.isLoading}
              data-test-id="create"
            >
              {locale.createUserModal.create}
            </Button>
          </div>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default AddUserFormModal;
