import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  Mesh,
  Vector3,
  MeshPhysicalMaterial,
  Scene,
  Color,
  WebGLRenderer,
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  BufferGeometry,
} from 'three';

import {
  basfPalletLength,
  basfPalletHeight,
  basfItemMaterials,
  basfGeometryDB,
} from './specsLibrary';
import { PlanData, PlanDataAction, PlanDataItem } from './types';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
type GeometryMap = {
  [name: string]: BufferGeometry;
};

export default class SceneManager {
  scene: Scene;
  camera: PerspectiveCamera;
  renderer: WebGLRenderer;
  orbitControl: OrbitControls;

  palletMeshes: Mesh[];
  containerMesh: Mesh;
  loader: STLLoader;
  geometries: GeometryMap;

  constructor() {
    this.scene = this.initializeScene();
    this.renderer = this.initializeCanvasRenderer();
    this.camera = this.initializeCamera();
    // this.initializeGrid();
    this.initializeLight();
    window.addEventListener('resize', this.onWindowResize.bind(this), false);
    this.orbitControl = new OrbitControls(this.camera, this.renderer.domElement);
    this.palletMeshes = [];
    this.containerMesh = undefined;
    this.geometries = {};
    this.loader = new STLLoader();
    this.initializeTruckMesh();
    this.initializeContainerMesh();
    this.animate();
  }

  getWidth(): number {
    return 970;
  }

  getHeight(): number {
    return 600;
  }

  onWindowResize(): void {
    this.camera.aspect = this.getWidth() / this.getHeight();
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.getWidth(), this.getHeight());
  }

  initializeScene(): Scene {
    const scene = new Scene();
    scene.background = new Color(0xf0f0f0);
    return scene;
  }

  initializeCanvasRenderer(): WebGLRenderer {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('webgl2', { alpha: false }) || undefined;
    const renderer = new WebGLRenderer({ canvas, context });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(this.getWidth(), this.getHeight());
    return renderer;
  }

  appendRenderers(canvasWrapper: HTMLElement): void {
    !!canvasWrapper && canvasWrapper.appendChild(this.renderer.domElement);
  }

  initializeCamera(): PerspectiveCamera {
    const camera = new PerspectiveCamera(45, this.getWidth() / this.getHeight(), 1, 10000);
    camera.up.set(0, 0, 1);
    camera.position.set(-1200, -1000, 600);
    return camera;
  }

  // initializeGrid(): GridHelper {
  //   // grid
  //   const gridHelper = new GridHelper(4000, 100, 0xc0c0c0, 0xc0c0c0);
  //   gridHelper.rotation.set(-Math.PI / 2, 0, 0);
  //   this.scene.add(gridHelper);
  //   return gridHelper;
  // }

  initializeLight(): void {
    // lights
    const ambientLight = new AmbientLight(0x505050);
    this.scene.add(ambientLight);

    const rightDirectionLight = new DirectionalLight(0xffffff);
    rightDirectionLight.position.set(4, 3, 2).normalize();
    this.scene.add(rightDirectionLight);

    const leftDirectionLight = new DirectionalLight(0xffffff);
    leftDirectionLight.position.set(-4, -3, 2).normalize();
    this.scene.add(leftDirectionLight);

    const bottomDirectionLight = new DirectionalLight(0x505050);
    bottomDirectionLight.position.set(0, 0, -3).normalize();
    this.scene.add(bottomDirectionLight);
  }

  animate(): void {
    requestAnimationFrame(this.animate.bind(this));
    this.orbitControl.update();
    this.renderer.render(this.scene, this.camera);
  }

  initializeTruckMesh(): void {
    const scale = new Vector3(0.1, 0.1, 0.1);
    const location = new Vector3(0, 0, 0);
    const color = 0xeeeeee;

    this.loader.load(basfGeometryDB.TRUCK, (geometry) => {
      const mesh = new Mesh(geometry);
      const material = new MeshPhysicalMaterial({ color });
      mesh.material = material;
      mesh.name = 'TRUCK';
      this.scene.add(mesh);
      mesh.scale.copy(scale);
      mesh.position.copy(location);
      mesh.rotateZ(-Math.PI * 0.5);
    });
  }

  initializeContainerMesh(): void {
    const location = new Vector3(0, 0, 0);
    const material = new MeshPhysicalMaterial({
      color: 0xffffff,
      opacity: 0.2,
      transparent: true,
    });
    this.loader.load(basfGeometryDB.CONTAINER, (geometry) => {
      this.containerMesh = new Mesh(geometry, material);
      this.containerMesh.rotateZ(-Math.PI * 0.5);
      this.containerMesh.scale.set(1200, 235, 240);
      this.containerMesh.position.copy(location);
      this.scene.add(this.containerMesh);
    });
  }

  adjustContainerScale(containerSize: Vector3): void {
    // Because we have rotated this particular container model dimension, we need to swap x,y
    const [y, x, z] = containerSize.toArray();
    const rotatedContainerDimension = new Vector3(x, y, z);
    // get the model size
    this.containerMesh.geometry.computeBoundingBox();
    const modelBoundingBox = this.containerMesh.geometry.boundingBox;
    const modelDimension = modelBoundingBox.max
      .clone()
      .addScaledVector(modelBoundingBox.min, -1)
      .divideScalar(10);
    // calculate new scale
    const newScale = rotatedContainerDimension.clone().divide(modelDimension).divideScalar(10);
    this.containerMesh.scale.copy(newScale);
  }

  loadPalletMesh(action: PlanDataAction): Mesh {
    const palletMesh = new Mesh(this.geometries.PALLET, basfItemMaterials.PALLET);
    palletMesh.geometry.computeBoundingBox();
    const modelDimension = palletMesh.geometry.boundingBox.max;

    palletMesh.scale
      .set(
        basfPalletLength / modelDimension.x,
        basfPalletLength / modelDimension.y,
        basfPalletHeight / modelDimension.z
      )
      .divideScalar(10);

    palletMesh.position
      .set(
        action.index[0] * basfPalletLength,
        action.index[1] * basfPalletLength,
        action.location[2]
      )
      .divideScalar(10);

    this.scene.add(palletMesh);
    this.palletMeshes.push(palletMesh);
    return palletMesh;
  }

  loadItemMesh(geometry: BufferGeometry, item: PlanDataItem, palletMesh: Mesh): void {
    const cargoMesh = new Mesh(geometry, basfItemMaterials[item.type]);
    const cargoLocation = new Vector3(...item.location).divideScalar(10);
    cargoLocation.setZ(cargoLocation.z + basfPalletHeight / 10);
    cargoMesh.scale.divideScalar(10);
    cargoMesh.position.copy(cargoLocation);
    palletMesh.add(cargoMesh);
    cargoMesh.scale.divide(palletMesh.scale);
    cargoMesh.position.divide(palletMesh.scale);
  }

  async parsePlanData(planData: PlanData): Promise<any> {
    const loadingList = this.getModelLoadingList(planData);
    await this.loadMissingModels(loadingList);
    // 1. await load pallet model and initialized all pallet meshes, resolve return the pallet mesh.
    if (!this.geometries.PALLET)
      this.geometries.PALLET = await this.loadModel(basfGeometryDB.PALLET);

    // 2. sort pallet into required model meshes.
    const modelRequirementMap = new Map<string, { item: PlanDataItem; palletMesh: Mesh }[]>();
    planData.actions.forEach((action: PlanDataAction) => {
      const palletMesh = this.loadPalletMesh(action);
      action.items.forEach((x) => {
        const mapEntry = modelRequirementMap.get(x.type);
        !!mapEntry
          ? mapEntry.push({ item: x, palletMesh })
          : modelRequirementMap.set(x.type, [{ item: x, palletMesh }]);
      });
    });
    // 3. load model and add them into pallet meshes.
    modelRequirementMap.forEach((mapValue, modelName) => {
      if (!(modelName in basfGeometryDB)) throw new Error('Display Model not find.');
      if (modelName in this.geometries) {
        mapValue.forEach((x) =>
          this.loadItemMesh(this.geometries[modelName], x.item, x.palletMesh)
        );
      } else {
        this.loader.load(basfGeometryDB[modelName], (geometry) => {
          this.geometries[modelName] = geometry;
          mapValue.forEach((x) => this.loadItemMesh(geometry, x.item, x.palletMesh));
        });
      }
    });
    // 4. load container and trailer and truck head meshes.
    const containerSize = new Vector3(...planData.trailer_dims).divideScalar(10);
    this.adjustContainerScale(containerSize);
    const viewCenter = containerSize.clone().divideScalar(2).add(new Vector3(100, 0, 0));
    this.orbitControl.target.copy(viewCenter);
  }

  getModelLoadingList(planData: PlanData): string[] {
    const existingModels = Object.keys(this.geometries);
    const requiredModelSet = new Set<string>(['PALLET']);

    planData.actions.forEach((action) =>
      action.items.forEach((item) => requiredModelSet.add(item.type))
    );
    const requiredModels: string[] = Array.from(requiredModelSet.values());
    const loadingList = requiredModels.filter((x) => !existingModels.includes(x));
    return loadingList;
  }

  async loadMissingModels(loadingList: string[]): Promise<GeometryMap> {
    for (const x of loadingList) {
      this.geometries[x] = await this.loadModel(basfGeometryDB[x]);
    }
    return this.geometries;
  }

  loadModel(uri: string): Promise<BufferGeometry> {
    return new Promise((resolve) => {
      this.loader.load(uri, (geometry: BufferGeometry) => {
        resolve(geometry);
      });
    });
  }

  renderPlan(data: PlanData) {
    this.palletMeshes.forEach((x) => this.scene.remove(x));
    this.parsePlanData(data);
  }
}

const sceneManager = new SceneManager();
export { sceneManager };
