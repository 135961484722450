import React from 'react';
import { useLocaleData } from '../../locale';
import { GlobalLocale } from '../../locale/types';

function NotFound() {
  const locale = useLocaleData<GlobalLocale>('global');
  return <div style={{ textAlign: 'center' }}>{locale.nothingHere}</div>;
}

export default NotFound;
